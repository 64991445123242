import { TypedDocumentNode as DocumentNode } from "@graphql-typed-document-node/core";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  BigInt: any;
  Byte: any;
  CosmosObjectIdString: any;
  Currency: any;
  DID: any;
  Date: any;
  DateString: any;
  DateTime: any;
  Duration: any;
  EmailAddress: any;
  GUID: any;
  HSL: any;
  HSLA: any;
  HexColorCode: any;
  Hexadecimal: any;
  IBAN: any;
  IPv4: any;
  IPv6: any;
  ISBN: any;
  ISO8601Duration: any;
  JSON: any;
  JSONObject: any;
  JWT: any;
  Latitude: any;
  LocalDate: any;
  LocalEndTime: any;
  LocalTime: any;
  Long: any;
  Longitude: any;
  MAC: any;
  NegativeFloat: any;
  NegativeInt: any;
  NonEmptyString: any;
  NonNegativeFloat: any;
  NonNegativeInt: any;
  NonPositiveFloat: any;
  NonPositiveInt: any;
  ObjectID: any;
  PhoneNumber: any;
  Port: any;
  PositiveFloat: any;
  PositiveInt: any;
  PostalCode: any;
  RGB: any;
  RGBA: any;
  SafeInt: any;
  Time: any;
  Timestamp: any;
  URL: any;
  USCurrency: any;
  UUID: any;
  UnsignedFloat: any;
  UnsignedInt: any;
  UtcOffset: any;
  Void: any;
};

export type A_Applicant = {
  __typename?: "A_Applicant";
  email?: Maybe<Scalars["String"]>;
  firstName?: Maybe<Scalars["String"]>;
  lastName?: Maybe<Scalars["String"]>;
  usmleId?: Maybe<Scalars["String"]>;
};

export type A_CaseDetails = {
  __typename?: "A_CaseDetails";
  applicationType?: Maybe<Scalars["String"]>;
  currentPathway?: Maybe<Scalars["String"]>;
  isApplicationCreationAllowed?: Maybe<Scalars["Boolean"]>;
  isExceptionCase?: Maybe<Scalars["Boolean"]>;
  isRequiredDataProvided?: Maybe<Scalars["Boolean"]>;
  output?: Maybe<Scalars["String"]>;
  outputReason?: Maybe<Array<Maybe<Scalars["String"]>>>;
  pathwaySeason?: Maybe<Scalars["Int"]>;
  state?: Maybe<Scalars["String"]>;
  submittedAt?: Maybe<Scalars["DateString"]>;
  submittedPathway?: Maybe<Scalars["String"]>;
};

export type A_Progress = {
  __typename?: "A_Progress";
  englishEligibility?: Maybe<Array<Maybe<A_ProgressStatus>>>;
  generalEligibility?: Maybe<Array<Maybe<A_ProgressStatus>>>;
  pathwayEligibility?: Maybe<Array<Maybe<A_ProgressStatus>>>;
};

export type A_ProgressStatus = {
  __typename?: "A_ProgressStatus";
  checklistRecord?: Maybe<Scalars["String"]>;
  checklistState?: Maybe<Scalars["String"]>;
};

export type ActivityLogType = {
  __typename?: "ActivityLogType";
  logType?: Maybe<Scalars["String"]>;
  message?: Maybe<Scalars["String"]>;
  timestamp?: Maybe<Scalars["DateString"]>;
  user?: Maybe<ActivityLogUserType>;
};

export type ActivityLogUserType = {
  __typename?: "ActivityLogUserType";
  id?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  role?: Maybe<Scalars["String"]>;
};

export type AddMiniCexInput = {
  physicianEmail: Scalars["String"];
  physicianLastNameProvidedByApplicant: Scalars["String"];
  physicianRestOfNameProvidedByApplicant: Scalars["String"];
};

export type AdditionalDocument = {
  __typename?: "AdditionalDocument";
  document?: Maybe<Document>;
  notes?: Maybe<Scalars["String"]>;
};

export type AdditionalDocumentInput = {
  document?: InputMaybe<DocumentInput>;
  notes?: InputMaybe<Scalars["String"]>;
};

export type ApplicantLoginPayloadType = {
  __typename?: "ApplicantLoginPayloadType";
  email?: Maybe<Scalars["String"]>;
  firstName?: Maybe<Scalars["String"]>;
  lastName?: Maybe<Scalars["String"]>;
  usmleId: Scalars["String"];
};

export type ApplicantPortalLoginResponseType = {
  __typename?: "ApplicantPortalLoginResponseType";
  applicant?: Maybe<ApplicantLoginPayloadType>;
  case?: Maybe<CaseLoginPayloadType>;
  token?: Maybe<Scalars["String"]>;
};

export type ApplicantPortalMutationResponseType = {
  __typename?: "ApplicantPortalMutationResponseType";
  data?: Maybe<ApplicantPortalResponseType>;
  status?: Maybe<Scalars["String"]>;
};

export type ApplicantPortalResponseType = {
  __typename?: "ApplicantPortalResponseType";
  _id?: Maybe<Scalars["ID"]>;
  applicant?: Maybe<A_Applicant>;
  caseConfig?: Maybe<CaseConfigType>;
  caseDetails?: Maybe<A_CaseDetails>;
  eligibilityPreCheck?: Maybe<EligibilityPreCheckResponseType>;
  finance?: Maybe<Finance>;
  graduation?: Maybe<GraduationType>;
  languageAssessment?: Maybe<LanguageAssessment>;
  minicexList?: Maybe<Array<Maybe<MiniCexSummaryType>>>;
  pastAttempts?: Maybe<Array<Maybe<PastAttemptsType>>>;
  pathway1?: Maybe<Pathway1>;
  pathway2?: Maybe<Pathway2>;
  pathway6?: Maybe<Pathway6>;
  pathway345?: Maybe<Pathway345>;
  pathwayX?: Maybe<ExceptionRequest>;
  progress?: Maybe<A_Progress>;
  uiConfig?: Maybe<UiPortalConfigType>;
};

export type ApplicantPortalResponseTypeMinicexListArgs = {
  filter: MiniCexListFilterApplicantInput;
};

export type ArchivedCaseApplicantGraduationType = {
  __typename?: "ArchivedCaseApplicantGraduationType";
  country?: Maybe<Scalars["String"]>;
  credComments?: Maybe<Scalars["String"]>;
  credStatusCode?: Maybe<Scalars["String"]>;
  degree?: Maybe<Scalars["String"]>;
  school?: Maybe<Scalars["String"]>;
  year?: Maybe<Scalars["String"]>;
};

export type ArchivedCaseApplicantProfileType = {
  __typename?: "ArchivedCaseApplicantProfileType";
  dateOfBirth?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  firstName?: Maybe<Scalars["String"]>;
  lastName?: Maybe<Scalars["String"]>;
  usmleId?: Maybe<Scalars["String"]>;
};

export type ArchivedCaseApplicantType = {
  __typename?: "ArchivedCaseApplicantType";
  examHistory?: Maybe<Array<Maybe<ExamHistoryType>>>;
  graduation?: Maybe<ArchivedCaseApplicantGraduationType>;
  profile?: Maybe<ArchivedCaseApplicantProfileType>;
  restrictions?: Maybe<Array<Maybe<RestrictionType>>>;
};

export type ArchivedCaseAssessmentEligibilityOutputType = {
  __typename?: "ArchivedCaseAssessmentEligibilityOutputType";
  output?: Maybe<Scalars["String"]>;
  outputReason?: Maybe<Scalars["String"]>;
};

export type ArchivedCaseAssessmentEligibilityType = {
  __typename?: "ArchivedCaseAssessmentEligibilityType";
  eligibilityPreCheck?: Maybe<ArchivedCaseAssessmentEligibilityOutputType>;
  generalEligibility?: Maybe<ArchivedCaseAssessmentEligibilityOutputType>;
};

export type ArchivedCaseAssessmentMinicexType = {
  __typename?: "ArchivedCaseAssessmentMinicexType";
  assessment?: Maybe<MiniCexAssessmentType>;
  minicexList?: Maybe<Array<Maybe<MiniCexResponseType>>>;
};

export type ArchivedCaseAssessmentMinicexTypeMinicexListArgs = {
  filter: MiniCexListFilterCaseManagerInput;
};

export type ArchivedCaseAssessmentOetType = {
  __typename?: "ArchivedCaseAssessmentOETType";
  candidateId?: Maybe<Scalars["String"]>;
  dateOfBirth?: Maybe<Scalars["String"]>;
  examDate?: Maybe<Scalars["String"]>;
  examineeFirstName?: Maybe<Scalars["String"]>;
  examineeLastName?: Maybe<Scalars["String"]>;
  listeningScore?: Maybe<Scalars["Float"]>;
  readingScore?: Maybe<Scalars["Float"]>;
  result?: Maybe<Scalars["String"]>;
  speakingScore?: Maybe<Scalars["Float"]>;
  testingCenter?: Maybe<Scalars["String"]>;
  usmleId?: Maybe<Scalars["String"]>;
  writingScore?: Maybe<Scalars["Float"]>;
};

export type ArchivedCaseAssessmentsType = {
  __typename?: "ArchivedCaseAssessmentsType";
  eligibility?: Maybe<ArchivedCaseAssessmentEligibilityType>;
  emswp?: Maybe<EducationAttestation>;
  minicex?: Maybe<ArchivedCaseAssessmentMinicexType>;
  oet?: Maybe<ArchivedCaseAssessmentOetType>;
};

export type ArchivedCaseAuditType = {
  __typename?: "ArchivedCaseAuditType";
  archivedAt?: Maybe<Scalars["DateString"]>;
  completedAt?: Maybe<Scalars["DateString"]>;
  createdAt?: Maybe<Scalars["DateString"]>;
  submittedAt?: Maybe<Scalars["DateString"]>;
};

export type ArchivedCaseDecisionType = {
  __typename?: "ArchivedCaseDecisionType";
  executedBy: UserType;
  output: Scalars["String"];
  outputReason?: Maybe<Array<Maybe<Scalars["String"]>>>;
};

export type ArchivedCaseDetailsType = {
  __typename?: "ArchivedCaseDetailsType";
  applicationType?: Maybe<Scalars["String"]>;
  currentPathway?: Maybe<Scalars["String"]>;
  decision?: Maybe<ArchivedCaseDecisionType>;
  isRefunded?: Maybe<Scalars["Boolean"]>;
  pathwaySeason?: Maybe<Scalars["Int"]>;
  submittedPathway?: Maybe<Scalars["String"]>;
};

export type ArchivedCaseFilterInput = {
  usmleId: Scalars["String"];
};

export type ArchivedCaseFinanceRevenueRecognitionType = {
  __typename?: "ArchivedCaseFinanceRevenueRecognitionType";
  amount?: Maybe<Scalars["Int"]>;
  creditGlAccount?: Maybe<Scalars["String"]>;
  debitGlAccount?: Maybe<Scalars["String"]>;
  pnref?: Maybe<Scalars["String"]>;
  revenueRecognizedAt?: Maybe<Scalars["DateString"]>;
};

export type ArchivedCaseFinanceType = {
  __typename?: "ArchivedCaseFinanceType";
  payment?: Maybe<Payment>;
  refund?: Maybe<Refund>;
  revenueRecognition?: Maybe<
    Array<Maybe<ArchivedCaseFinanceRevenueRecognitionType>>
  >;
};

export type ArchivedCaseOetReviewType = {
  __typename?: "ArchivedCaseOetReviewType";
  checklist?: Maybe<LanguageAssessmentChecklist>;
  checklistState?: Maybe<Scalars["String"]>;
  data?: Maybe<LanguageAssessmentData>;
  summary?: Maybe<ArchivedCaseReviewSummaryType>;
};

export type ArchivedCasePathway1ReviewType = {
  __typename?: "ArchivedCasePathway1ReviewType";
  checklist?: Maybe<LicenseChecklist>;
  checklistState?: Maybe<Scalars["String"]>;
  data?: Maybe<LicenseData>;
  summary?: Maybe<ArchivedCaseReviewSummaryType>;
};

export type ArchivedCasePathway2ReviewType = {
  __typename?: "ArchivedCasePathway2ReviewType";
  checklist?: Maybe<CsExamChecklist>;
  checklistState?: Maybe<Scalars["String"]>;
  data?: Maybe<CsExamData>;
  summary?: Maybe<ArchivedCaseReviewSummaryType>;
};

export type ArchivedCasePathway6ReviewType = {
  __typename?: "ArchivedCasePathway6ReviewType";
  checklist?: Maybe<MiniCexChecklist>;
  checklistState?: Maybe<Scalars["String"]>;
  summary?: Maybe<ArchivedCaseReviewSummaryType>;
};

export type ArchivedCasePathway345ReviewType = {
  __typename?: "ArchivedCasePathway345ReviewType";
  checklist?: Maybe<EducationChecklist>;
  checklistState?: Maybe<Scalars["String"]>;
  data?: Maybe<EducationData>;
  summary?: Maybe<ArchivedCaseReviewSummaryType>;
};

export type ArchivedCasePathwayXReviewType = {
  __typename?: "ArchivedCasePathwayXReviewType";
  checklist?: Maybe<ExceptionRequestChecklist>;
  data?: Maybe<ExceptionRequestData>;
  exceptionChecklistState?: Maybe<Scalars["String"]>;
  pathwayChecklistState?: Maybe<Scalars["String"]>;
  summary?: Maybe<ArchivedCaseReviewSummaryType>;
};

export type ArchivedCaseReviewSummaryType = {
  __typename?: "ArchivedCaseReviewSummaryType";
  _id: Scalars["String"];
  status?: Maybe<Scalars["String"]>;
  title: Scalars["String"];
  updatedBy?: Maybe<UserType>;
  updatedTimestamp?: Maybe<Scalars["DateString"]>;
};

export type ArchivedCaseReviewsType = {
  __typename?: "ArchivedCaseReviewsType";
  oet?: Maybe<Array<Maybe<ArchivedCaseOetReviewType>>>;
  pathway1?: Maybe<Array<Maybe<ArchivedCasePathway1ReviewType>>>;
  pathway2?: Maybe<Array<Maybe<ArchivedCasePathway2ReviewType>>>;
  pathway6?: Maybe<Array<Maybe<ArchivedCasePathway6ReviewType>>>;
  pathway345?: Maybe<Array<Maybe<ArchivedCasePathway345ReviewType>>>;
  pathwayX?: Maybe<Array<Maybe<ArchivedCasePathwayXReviewType>>>;
};

export type ArchivedCaseSummaryType = {
  __typename?: "ArchivedCaseSummaryType";
  _id: Scalars["String"];
  applicationType?: Maybe<Scalars["String"]>;
  decisionOutput?: Maybe<Scalars["String"]>;
  pathwayNumber?: Maybe<Scalars["String"]>;
  pathwaySeason?: Maybe<Scalars["Int"]>;
  reviewCompletedOn?: Maybe<Scalars["DateString"]>;
  submittedOn?: Maybe<Scalars["DateString"]>;
};

export type ArchivedCaseType = {
  __typename?: "ArchivedCaseType";
  _id: Scalars["String"];
  activityLog?: Maybe<Array<Maybe<ActivityLogType>>>;
  additionalDocuments?: Maybe<Array<Maybe<AdditionalDocument>>>;
  applicant?: Maybe<ArchivedCaseApplicantType>;
  assessments?: Maybe<ArchivedCaseAssessmentsType>;
  audit?: Maybe<ArchivedCaseAuditType>;
  caseDetails?: Maybe<ArchivedCaseDetailsType>;
  externalId?: Maybe<Scalars["String"]>;
  finance?: Maybe<ArchivedCaseFinanceType>;
  reviews?: Maybe<ArchivedCaseReviewsType>;
  uiConfig?: Maybe<UiPortalConfigType>;
};

export type AssessmentCategoryType = {
  __typename?: "AssessmentCategoryType";
  avgScore?: Maybe<Scalars["Float"]>;
  failedEncounters?: Maybe<Array<Maybe<FailedEncounterType>>>;
  isFailedByAvgScore?: Maybe<Scalars["Boolean"]>;
  name?: Maybe<Scalars["String"]>;
};

export type AttestMiniCexInput = {
  hasApplicantEnrolledInClinicalRotation: Scalars["String"];
};

export type AttestationRecordFilterInput = {
  attestationOutput?: InputMaybe<Scalars["String"]>;
  usmleId?: InputMaybe<Scalars["String"]>;
};

export type AttestationRecordType = {
  __typename?: "AttestationRecordType";
  attestationOutput?: Maybe<Scalars["String"]>;
  completeTimestamp?: Maybe<Scalars["DateString"]>;
  completedBy?: Maybe<Scalars["String"]>;
  dataVersion?: Maybe<Scalars["Int"]>;
  dateOfBirth?: Maybe<Scalars["String"]>;
  firstName?: Maybe<Scalars["String"]>;
  gradSchool?: Maybe<Scalars["String"]>;
  gradSchoolCountry?: Maybe<Scalars["String"]>;
  gradYear?: Maybe<Scalars["String"]>;
  lastName?: Maybe<Scalars["String"]>;
  rejectionReason?: Maybe<Scalars["String"]>;
  rejectionReasonText?: Maybe<Scalars["String"]>;
  requestTimestamp?: Maybe<Scalars["DateString"]>;
  uiConfig?: Maybe<UiPortalConfigType>;
  usmleId?: Maybe<Scalars["String"]>;
};

export type AuthorityPortalResponseType = {
  __typename?: "AuthorityPortalResponseType";
  attestationRecords?: Maybe<Array<Maybe<AttestationRecordType>>>;
};

export type AuthorityPortalResponseTypeAttestationRecordsArgs = {
  filter?: InputMaybe<AttestationRecordFilterInput>;
};

export type CsExam = {
  __typename?: "CSExam";
  _id?: Maybe<Scalars["ID"]>;
  data?: Maybe<CsExamData>;
  review?: Maybe<CsExamReview>;
};

export type CsExamChecklist = {
  __typename?: "CSExamChecklist";
  osceDocumentCompleteLegible?: Maybe<Scalars["String"]>;
  osceExaminationAcceptable?: Maybe<Scalars["String"]>;
  osceExaminationAfterJuly1?: Maybe<Scalars["String"]>;
  osceExaminationPassed?: Maybe<Scalars["String"]>;
  osceNameOK?: Maybe<Scalars["String"]>;
  osceTranslation?: Maybe<Scalars["String"]>;
  verificationAcceptableDateConfirmed?: Maybe<Scalars["String"]>;
  verificationCompleted?: Maybe<Scalars["String"]>;
  verificationPassedExamConfirmed?: Maybe<Scalars["String"]>;
  verificationReturned?: Maybe<Scalars["String"]>;
};

export type CsExamChecklistInput = {
  osceDocumentCompleteLegible: Scalars["String"];
  osceExaminationAcceptable: Scalars["String"];
  osceExaminationAfterJuly1: Scalars["String"];
  osceExaminationPassed: Scalars["String"];
  osceNameOK: Scalars["String"];
  osceTranslation: Scalars["String"];
  verificationAcceptableDateConfirmed: Scalars["String"];
  verificationCompleted: Scalars["String"];
  verificationPassedExamConfirmed: Scalars["String"];
  verificationReturned: Scalars["String"];
};

export type CsExamData = {
  __typename?: "CSExamData";
  _id?: Maybe<Scalars["ID"]>;
  documents?: Maybe<Array<Maybe<Document>>>;
  examDate?: Maybe<Scalars["String"]>;
  oscExamRef?: Maybe<OscexamResponseType>;
  schoolRef?: Maybe<SchoolResponseType>;
};

export type CsExamDataInput = {
  documents?: InputMaybe<Array<InputMaybe<DocumentInput>>>;
  examDate?: InputMaybe<Scalars["String"]>;
  oscExamRef?: InputMaybe<OscexamInput>;
  schoolRef?: InputMaybe<SchoolInput>;
};

export type CsExamInput = {
  data?: InputMaybe<CsExamDataInput>;
};

export type CsExamReview = {
  __typename?: "CSExamReview";
  checklist?: Maybe<CsExamChecklist>;
  checklistState?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
  updatedBy?: Maybe<UserType>;
  updatedTimestamp?: Maybe<Scalars["DateString"]>;
};

export type CsExamReviewInput = {
  checklist?: InputMaybe<CsExamChecklistInput>;
  checklistState?: InputMaybe<Scalars["String"]>;
};

export type C_ApplicantType = {
  __typename?: "C_ApplicantType";
  country?: Maybe<Scalars["String"]>;
  credComments?: Maybe<Scalars["String"]>;
  credStatusCode?: Maybe<Scalars["String"]>;
  dateOfBirth?: Maybe<Scalars["String"]>;
  degree?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  examHistory?: Maybe<Array<Maybe<ExamHistoryType>>>;
  gradSchool?: Maybe<Scalars["String"]>;
  gradSchoolId?: Maybe<Scalars["Int"]>;
  gradYear?: Maybe<Scalars["String"]>;
  graduation?: Maybe<GraduationType>;
  isStep2CSFailed?: Maybe<Scalars["Boolean"]>;
  lastDataRefreshTimestamp?: Maybe<Scalars["DateString"]>;
  restrictions?: Maybe<Array<Maybe<RestrictionType>>>;
  schoolEligibility?: Maybe<SchoolEligibilityType>;
};

/**  Required to enable Apollo Cache Control  */
export enum CacheControlScope {
  Private = "PRIVATE",
  Public = "PUBLIC",
}

export type CaseConfigRecordType = {
  __typename?: "CaseConfigRecordType";
  minEligibleGradDate?: Maybe<Scalars["Date"]>;
  minEligibleOETTestDate?: Maybe<Scalars["Date"]>;
  pathwaySeason?: Maybe<Scalars["Int"]>;
};

export type CaseConfigType = {
  __typename?: "CaseConfigType";
  caseConfigRef?: Maybe<Scalars["CosmosObjectIdString"]>;
  config?: Maybe<CaseConfigRecordType>;
};

export type CaseCountType = {
  __typename?: "CaseCountType";
  applicationCompleted?: Maybe<Scalars["Int"]>;
  applicationCreation?: Maybe<Scalars["Int"]>;
  applicationSubmitted?: Maybe<Scalars["Int"]>;
  assignedToUser?: Maybe<Scalars["Int"]>;
  eligibilityReview?: Maybe<Scalars["Int"]>;
  qaReady?: Maybe<Scalars["Int"]>;
  qaReview?: Maybe<Scalars["Int"]>;
};

export type CaseFilterInput = {
  applicationType?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  assignedToUser?: InputMaybe<Scalars["Boolean"]>;
  candidateId?: InputMaybe<Scalars["String"]>;
  caseState?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  currentPathway?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  englishEligibilityStatus?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  firstName?: InputMaybe<Scalars["String"]>;
  generalEligibilityStatus?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  lastName?: InputMaybe<Scalars["String"]>;
  pathwayEligibilityStatus?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  regulatoryAuthority?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  school?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  usmleId?: InputMaybe<Scalars["String"]>;
};

export type CaseListPaginationInput = {
  pageNumber: Scalars["Int"];
  pageSize: Scalars["Int"];
  sortField: Scalars["String"];
  sortOrder: Scalars["String"];
};

export type CaseListPaginationType = {
  __typename?: "CaseListPaginationType";
  pageNumber: Scalars["Int"];
  pageSize: Scalars["Int"];
  sortField: Scalars["String"];
  sortOrder: Scalars["String"];
  totalPages: Scalars["Int"];
  totalRecords: Scalars["Int"];
};

export type CaseListResponseType = {
  __typename?: "CaseListResponseType";
  cases?: Maybe<Array<Maybe<CaseSummaryType>>>;
  pagination: CaseListPaginationType;
};

export type CaseLoginPayloadType = {
  __typename?: "CaseLoginPayloadType";
  applicationType?: Maybe<Scalars["String"]>;
  isApplicationCreationAllowed?: Maybe<Scalars["Boolean"]>;
  isExceptionCase?: Maybe<Scalars["Boolean"]>;
  state?: Maybe<Scalars["String"]>;
};

export type CaseNoteInput = {
  text: Scalars["String"];
};

export type CasePortalResponseType = {
  __typename?: "CasePortalResponseType";
  case?: Maybe<CaseResponseType>;
  caseCount?: Maybe<CaseCountType>;
  caseList?: Maybe<CaseListResponseType>;
  cases?: Maybe<Array<Maybe<CaseSummaryType>>>;
  getOETRecordCount?: Maybe<Scalars["Int"]>;
  getOETUnMatchedCount?: Maybe<Scalars["Int"]>;
  glDistributionConfig?: Maybe<Array<Maybe<GlDistributionConfig>>>;
  glDistributionReport?: Maybe<Array<Maybe<GlDistributionReportResponseType>>>;
  minicexByPhysician?: Maybe<Array<Maybe<MiniCexResponseType>>>;
  oetPortal?: Maybe<OetPortalResponseType>;
  physician?: Maybe<PhysicianResponseType>;
  physicianByMiniCEX?: Maybe<Array<Maybe<PhysicianResponseType>>>;
  physicians?: Maybe<Array<Maybe<PhysicianResponseType>>>;
};

export type CasePortalResponseTypeCaseArgs = {
  usmleId?: InputMaybe<Scalars["String"]>;
};

export type CasePortalResponseTypeCaseListArgs = {
  filter?: InputMaybe<CaseFilterInput>;
  pagination: CaseListPaginationInput;
};

export type CasePortalResponseTypeCasesArgs = {
  filter?: InputMaybe<CaseFilterInput>;
};

export type CasePortalResponseTypeGlDistributionConfigArgs = {
  _id?: InputMaybe<Scalars["ID"]>;
};

export type CasePortalResponseTypeGlDistributionReportArgs = {
  filter?: InputMaybe<GlDistributionReportFilterInput>;
};

export type CasePortalResponseTypeMinicexByPhysicianArgs = {
  filter: MiniCexByPhysicianFilterCaseManagerInput;
};

export type CasePortalResponseTypePhysicianArgs = {
  email: Scalars["String"];
};

export type CasePortalResponseTypePhysicianByMiniCexArgs = {
  filter: PhysicianByMiniCexFilterCaseManagerInput;
};

export type CaseResponseStatusType = {
  __typename?: "CaseResponseStatusType";
  response?: Maybe<ResponseStatusType>;
  usmleId?: Maybe<Scalars["String"]>;
};

export type CaseResponseType = {
  __typename?: "CaseResponseType";
  _id?: Maybe<Scalars["String"]>;
  activityLog?: Maybe<Array<Maybe<ActivityLogType>>>;
  additionalDocuments?: Maybe<Array<Maybe<AdditionalDocument>>>;
  applicant?: Maybe<C_ApplicantType>;
  caseConfig?: Maybe<CaseConfigType>;
  caseSummary?: Maybe<CaseSummaryType>;
  finance?: Maybe<Finance>;
  languageAssessment?: Maybe<LanguageAssessment>;
  oetScore?: Maybe<OetResponseType>;
  pastAttempts?: Maybe<Array<Maybe<PastAttemptsType>>>;
  pathway1?: Maybe<Pathway1>;
  pathway2?: Maybe<Pathway2>;
  pathway6?: Maybe<Pathway6>;
  pathway345?: Maybe<Pathway345>;
  pathwayX?: Maybe<ExceptionRequest>;
  uiConfig?: Maybe<UiPortalConfigType>;
};

export type CaseSummaryType = {
  __typename?: "CaseSummaryType";
  _id?: Maybe<Scalars["String"]>;
  applicationType?: Maybe<Scalars["String"]>;
  caseState?: Maybe<Scalars["String"]>;
  createdAt?: Maybe<Scalars["DateString"]>;
  currentPathway?: Maybe<Scalars["String"]>;
  dataVersion?: Maybe<Scalars["Int"]>;
  dateOfBirth?: Maybe<Scalars["String"]>;
  eligibilityReviewAssigneeId?: Maybe<Scalars["String"]>;
  eligibilityReviewAssigneeName?: Maybe<Scalars["String"]>;
  englishEligibilityOutput?: Maybe<Scalars["String"]>;
  englishEligibilityStatus?: Maybe<Scalars["String"]>;
  firstName?: Maybe<Scalars["String"]>;
  generalEligibilityOutput?: Maybe<Scalars["String"]>;
  generalEligibilityOutputReason?: Maybe<Scalars["String"]>;
  generalEligibilityStatus?: Maybe<Scalars["String"]>;
  isEligible?: Maybe<Scalars["Boolean"]>;
  isExceptionCase?: Maybe<Scalars["Boolean"]>;
  isPathwayChanged?: Maybe<Scalars["Boolean"]>;
  lastDocUploadTimestamp?: Maybe<Scalars["DateString"]>;
  lastName?: Maybe<Scalars["String"]>;
  oetCandidateId?: Maybe<Scalars["String"]>;
  oetExamDate?: Maybe<Scalars["String"]>;
  output?: Maybe<Scalars["String"]>;
  outputReason?: Maybe<Array<Maybe<Scalars["String"]>>>;
  pathwayEligibilityOutput?: Maybe<Scalars["String"]>;
  pathwayEligibilityStatus?: Maybe<Scalars["String"]>;
  pathwaySeason?: Maybe<Scalars["Int"]>;
  qaReviewAssigneeId?: Maybe<Scalars["String"]>;
  qaReviewAssigneeName?: Maybe<Scalars["String"]>;
  qaReworkTimestamp?: Maybe<Scalars["DateString"]>;
  submittedAt?: Maybe<Scalars["DateString"]>;
  submittedPathway?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["DateString"]>;
  usmleId?: Maybe<Scalars["String"]>;
};

export type CategoryAdditionalInfoType = {
  __typename?: "CategoryAdditionalInfoType";
  maxScore?: Maybe<Scalars["Int"]>;
  minScore?: Maybe<Scalars["Int"]>;
};

export type CategoryQualificationType = {
  __typename?: "CategoryQualificationType";
  minAvgScoreAcrossAllRequests?: Maybe<Scalars["Int"]>;
  minScore?: Maybe<Scalars["Int"]>;
};

export type Dashboard = {
  __typename?: "Dashboard";
  _id?: Maybe<Scalars["CosmosObjectIdString"]>;
  dashboardType?: Maybe<Scalars["String"]>;
  lastAccessedCaseView?: Maybe<LastAccessedView>;
  lastAccessedOETView?: Maybe<LastAccessedView>;
  oetViews?: Maybe<Array<Maybe<OetViews>>>;
  schemaVersion?: Maybe<Scalars["String"]>;
  views?: Maybe<Array<Maybe<Views>>>;
};

export type DashboardInput = {
  dashboardType?: InputMaybe<Scalars["String"]>;
  oetViews?: InputMaybe<Array<InputMaybe<OetViewsInput>>>;
  schemaVersion?: InputMaybe<Scalars["String"]>;
  views?: InputMaybe<Array<InputMaybe<ViewsInput>>>;
};

export type DeleteDashboardResponse = {
  __typename?: "DeleteDashboardResponse";
  success?: Maybe<Scalars["String"]>;
};

export type Document = {
  __typename?: "Document";
  docId?: Maybe<Scalars["String"]>;
  docType?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
  uploadedBy?: Maybe<DocumentUploadUser>;
};

export type DocumentInput = {
  docId?: InputMaybe<Scalars["String"]>;
  docType: Scalars["String"];
  title: Scalars["String"];
  uploadedBy?: InputMaybe<DocumentUploadUserInput>;
};

export type DocumentUploadUser = {
  __typename?: "DocumentUploadUser";
  id?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
};

export type DocumentUploadUserInput = {
  id?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
};

export type Education = {
  __typename?: "Education";
  _id?: Maybe<Scalars["ID"]>;
  attestation?: Maybe<EducationAttestation>;
  data?: Maybe<EducationData>;
  review?: Maybe<EducationReview>;
};

export type EducationAttestation = {
  __typename?: "EducationAttestation";
  output?: Maybe<Scalars["String"]>;
  outputReason?: Maybe<Scalars["String"]>;
  outputReasonText?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
  updatedBy?: Maybe<UserType>;
  updatedTimestamp?: Maybe<Scalars["DateString"]>;
};

export type EducationChecklist = {
  __typename?: "EducationChecklist";
  attestationAuthorizedOfficial?: Maybe<Scalars["String"]>;
  attestationComplete?: Maybe<Scalars["String"]>;
  attestationCompletedByMedicalSchool?: Maybe<Scalars["String"]>;
  attestationSealOk?: Maybe<Scalars["String"]>;
  attestationSentPrimarySource?: Maybe<Scalars["String"]>;
  attestationSignatureDate?: Maybe<Scalars["String"]>;
  attestationSignatureMatch?: Maybe<Scalars["String"]>;
  attestationTitleOk?: Maybe<Scalars["String"]>;
  eligibilityGradDateEligible?: Maybe<Scalars["String"]>;
  eligibilityMedicalSchool?: Maybe<Scalars["String"]>;
};

export type EducationChecklistInput = {
  attestationAuthorizedOfficial: Scalars["String"];
  attestationComplete: Scalars["String"];
  attestationCompletedByMedicalSchool: Scalars["String"];
  attestationSealOk: Scalars["String"];
  attestationSentPrimarySource: Scalars["String"];
  attestationSignatureDate: Scalars["String"];
  attestationSignatureMatch: Scalars["String"];
  attestationTitleOk: Scalars["String"];
  eligibilityGradDateEligible: Scalars["String"];
  eligibilityMedicalSchool: Scalars["String"];
};

export type EducationData = {
  __typename?: "EducationData";
  _id?: Maybe<Scalars["ID"]>;
  documents?: Maybe<Array<Maybe<Document>>>;
  gradDate?: Maybe<Scalars["String"]>;
  schoolRef?: Maybe<SchoolResponseType>;
};

export type EducationDataInput = {
  documents?: InputMaybe<Array<InputMaybe<DocumentInput>>>;
  gradDate?: InputMaybe<Scalars["String"]>;
  schoolRef?: InputMaybe<SchoolInput>;
};

export type EducationInput = {
  data?: InputMaybe<EducationDataInput>;
};

export type EducationReview = {
  __typename?: "EducationReview";
  checklist?: Maybe<EducationChecklist>;
  checklistState?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
  updatedBy?: Maybe<UserType>;
  updatedTimestamp?: Maybe<Scalars["DateString"]>;
};

export type EducationReviewInput = {
  checklist?: InputMaybe<EducationChecklistInput>;
  checklistState?: InputMaybe<Scalars["String"]>;
};

export type Eligibility = {
  __typename?: "Eligibility";
  eligibilityCheckTimestamp?: Maybe<Scalars["DateString"]>;
  isEligible?: Maybe<Scalars["Boolean"]>;
  responseCode?: Maybe<Scalars["String"]>;
  responseMsg?: Maybe<Scalars["String"]>;
};

export type EligibilityPreCheckResponseType = {
  __typename?: "EligibilityPreCheckResponseType";
  eligibility?: Maybe<Eligibility>;
  isStep2CSFailed?: Maybe<Scalars["Boolean"]>;
  lastScreenName?: Maybe<Scalars["String"]>;
};

export type EventRepublishResponseType = {
  __typename?: "EventRepublishResponseType";
  id?: Maybe<Scalars["String"]>;
  result?: Maybe<Scalars["String"]>;
};

export type ExamHistoryType = {
  __typename?: "ExamHistoryType";
  examDate?: Maybe<Scalars["String"]>;
  examName?: Maybe<Scalars["String"]>;
  result?: Maybe<Scalars["String"]>;
};

export type ExceptionRequest = {
  __typename?: "ExceptionRequest";
  _id?: Maybe<Scalars["ID"]>;
  data?: Maybe<ExceptionRequestData>;
  review?: Maybe<ExceptionRequestReview>;
};

export type ExceptionRequestChecklist = {
  __typename?: "ExceptionRequestChecklist";
  applicantEligiblePathway1?: Maybe<Scalars["String"]>;
  applicantEligiblePathway2?: Maybe<Scalars["String"]>;
  applicantEligiblePathway345?: Maybe<Scalars["String"]>;
  applicantHasNotSubmittedAppForCert?: Maybe<Scalars["String"]>;
  applicantHasPurchasedToken2021Match?: Maybe<Scalars["String"]>;
  applicantIsNotCertified?: Maybe<Scalars["String"]>;
  applicantNotBarredByUSMLE?: Maybe<Scalars["String"]>;
  applicantNotValidStep2CSScore?: Maybe<Scalars["String"]>;
  ecfmgRestrictionsDoNotPreventAppExamination?: Maybe<Scalars["String"]>;
  exceptionApproved?: Maybe<Scalars["String"]>;
  pathway1DocsVerified?: Maybe<Scalars["String"]>;
  pathway2DocsVerified?: Maybe<Scalars["String"]>;
  pathway345DocsVerified?: Maybe<Scalars["String"]>;
  step1PassedWithOneOrNoFails?: Maybe<Scalars["String"]>;
  step2CKPassedWithOneOrNoFails?: Maybe<Scalars["String"]>;
};

export type ExceptionRequestChecklistInput = {
  applicantEligiblePathway1: Scalars["String"];
  applicantEligiblePathway2: Scalars["String"];
  applicantEligiblePathway345: Scalars["String"];
  applicantHasNotSubmittedAppForCert: Scalars["String"];
  applicantHasPurchasedToken2021Match: Scalars["String"];
  applicantIsNotCertified: Scalars["String"];
  applicantNotBarredByUSMLE: Scalars["String"];
  applicantNotValidStep2CSScore: Scalars["String"];
  ecfmgRestrictionsDoNotPreventAppExamination: Scalars["String"];
  exceptionApproved: Scalars["String"];
  pathway1DocsVerified: Scalars["String"];
  pathway2DocsVerified: Scalars["String"];
  pathway345DocsVerified: Scalars["String"];
  step1PassedWithOneOrNoFails: Scalars["String"];
  step2CKPassedWithOneOrNoFails: Scalars["String"];
};

export type ExceptionRequestData = {
  __typename?: "ExceptionRequestData";
  documents?: Maybe<Array<Maybe<Document>>>;
  requestText?: Maybe<Scalars["String"]>;
};

export type ExceptionRequestDataInput = {
  documents?: InputMaybe<Array<InputMaybe<DocumentInput>>>;
  requestText?: InputMaybe<Scalars["String"]>;
};

export type ExceptionRequestInput = {
  data?: InputMaybe<ExceptionRequestDataInput>;
};

export type ExceptionRequestReview = {
  __typename?: "ExceptionRequestReview";
  checklist?: Maybe<ExceptionRequestChecklist>;
  exceptionChecklistState?: Maybe<Scalars["String"]>;
  pathwayChecklistState?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
  updatedBy?: Maybe<UserType>;
  updatedTimestamp?: Maybe<Scalars["DateString"]>;
};

export type ExceptionRequestReviewInput = {
  checklist?: InputMaybe<ExceptionRequestChecklistInput>;
  exceptionChecklistState?: InputMaybe<Scalars["String"]>;
  pathwayChecklistState?: InputMaybe<Scalars["String"]>;
};

export type FailedEncounterType = {
  __typename?: "FailedEncounterType";
  encounterId?: Maybe<Scalars["String"]>;
  score?: Maybe<Scalars["Int"]>;
};

export type Finance = {
  __typename?: "Finance";
  caseFee?: Maybe<Scalars["Int"]>;
  payment?: Maybe<Payment>;
  refund?: Maybe<Refund>;
};

export type GlDistributionConfig = {
  __typename?: "GLDistributionConfig";
  _id?: Maybe<Scalars["ID"]>;
  applicationType?: Maybe<Scalars["String"]>;
  configName?: Maybe<Scalars["String"]>;
  createdAt?: Maybe<Scalars["DateString"]>;
  createdBy?: Maybe<Scalars["String"]>;
  dateFrom?: Maybe<Scalars["String"]>;
  exceptionCaseFee?: Maybe<Scalars["Int"]>;
  exceptionCaseInitialIntake?: Maybe<Scalars["Int"]>;
  glAccountCashControl?: Maybe<Scalars["String"]>;
  glAccountEarned?: Maybe<Scalars["String"]>;
  glAccountRefundControl?: Maybe<Scalars["String"]>;
  glAccountUnearned?: Maybe<Scalars["String"]>;
  standardCaseFee?: Maybe<Scalars["Int"]>;
  standardCaseInitialIntake?: Maybe<Scalars["Int"]>;
  updatedAt?: Maybe<Scalars["DateString"]>;
  updatedBy?: Maybe<Scalars["String"]>;
  version?: Maybe<Scalars["Int"]>;
};

export type GlDistributionConfigEditInput = {
  configName: Scalars["String"];
};

export type GlDistributionConfigInput = {
  applicationType: Scalars["String"];
  configName: Scalars["String"];
  dateFrom: Scalars["DateString"];
  exceptionCaseFee: Scalars["Int"];
  exceptionCaseInitialIntake: Scalars["Int"];
  glAccountCashControl: Scalars["String"];
  glAccountEarned: Scalars["String"];
  glAccountRefundControl: Scalars["String"];
  glAccountUnearned: Scalars["String"];
  standardCaseFee: Scalars["Int"];
  standardCaseInitialIntake: Scalars["Int"];
};

export type GenericResponseType = {
  __typename?: "GenericResponseType";
  code?: Maybe<Scalars["String"]>;
  dataVersion?: Maybe<Scalars["Int"]>;
  msg?: Maybe<Scalars["String"]>;
};

export type GraduationType = {
  __typename?: "GraduationType";
  country?: Maybe<Scalars["String"]>;
  gradSchoolId?: Maybe<Scalars["Int"]>;
  isYearEligible?: Maybe<Scalars["Boolean"]>;
  school?: Maybe<Scalars["String"]>;
  schoolEligibility?: Maybe<SchoolEligibilityType>;
  year?: Maybe<Scalars["String"]>;
};

export type JobEligibilityChecksResponseType = {
  __typename?: "JobEligibilityChecksResponseType";
  eligibilityPreCheck?: Maybe<JobResponseType>;
  generalEligibility?: Maybe<JobResponseType>;
  jobId?: Maybe<Scalars["String"]>;
  totalTimeTaken?: Maybe<Scalars["Int"]>;
};

export type JobResponseType = {
  __typename?: "JobResponseType";
  failureCount?: Maybe<Scalars["Int"]>;
  jobId?: Maybe<Scalars["String"]>;
  jobStatus?: Maybe<Scalars["String"]>;
  successCount?: Maybe<Scalars["Int"]>;
  timeTaken?: Maybe<Scalars["Int"]>;
  totalCount?: Maybe<Scalars["Int"]>;
};

export type LanguageAssessment = {
  __typename?: "LanguageAssessment";
  applicantProvidedData?: Maybe<Array<Maybe<LanguageAssessmentType>>>;
  staffProvidedData?: Maybe<Array<Maybe<LanguageAssessmentType>>>;
  userResponse?: Maybe<LanguageAssessmentUserResponse>;
};

export type LanguageAssessmentChecklist = {
  __typename?: "LanguageAssessmentChecklist";
  documentCompleteLegible?: Maybe<Scalars["String"]>;
  examDateValidation?: Maybe<Scalars["String"]>;
  nameValid?: Maybe<Scalars["String"]>;
  scoreAcceptable?: Maybe<Scalars["String"]>;
  scoreConfirmed?: Maybe<Scalars["String"]>;
};

export type LanguageAssessmentChecklistInput = {
  documentCompleteLegible: Scalars["String"];
  examDateValidation: Scalars["String"];
  nameValid: Scalars["String"];
  scoreAcceptable: Scalars["String"];
  scoreConfirmed: Scalars["String"];
};

export type LanguageAssessmentData = {
  __typename?: "LanguageAssessmentData";
  _id?: Maybe<Scalars["ID"]>;
  candidateId?: Maybe<Scalars["String"]>;
  documents?: Maybe<Array<Maybe<Document>>>;
  examDate?: Maybe<Scalars["String"]>;
};

export type LanguageAssessmentDataInput = {
  candidateId?: InputMaybe<Scalars["String"]>;
  documents?: InputMaybe<Array<InputMaybe<DocumentInput>>>;
  examDate?: InputMaybe<Scalars["String"]>;
};

export type LanguageAssessmentInput = {
  applicantProvidedData?: InputMaybe<
    Array<InputMaybe<LanguageAssessmentTypeInput>>
  >;
  userResponse?: InputMaybe<LanguageAssessmentUserResponseInput>;
};

export type LanguageAssessmentReview = {
  __typename?: "LanguageAssessmentReview";
  checklist?: Maybe<LanguageAssessmentChecklist>;
  checklistState?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
  updatedBy?: Maybe<UserType>;
  updatedTimestamp?: Maybe<Scalars["DateString"]>;
};

export type LanguageAssessmentReviewInput = {
  checklist?: InputMaybe<LanguageAssessmentChecklistInput>;
  checklistState?: InputMaybe<Scalars["String"]>;
};

export type LanguageAssessmentType = {
  __typename?: "LanguageAssessmentType";
  _id?: Maybe<Scalars["ID"]>;
  data?: Maybe<LanguageAssessmentData>;
  review?: Maybe<LanguageAssessmentReview>;
};

export type LanguageAssessmentTypeInput = {
  data?: InputMaybe<LanguageAssessmentDataInput>;
};

export type LanguageAssessmentUserResponse = {
  __typename?: "LanguageAssessmentUserResponse";
  hasPassedExam?: Maybe<Scalars["Boolean"]>;
};

export type LanguageAssessmentUserResponseInput = {
  hasPassedExam?: InputMaybe<Scalars["Boolean"]>;
};

export type LastAccessedView = {
  __typename?: "LastAccessedView";
  assignedToMe?: Maybe<Scalars["Boolean"]>;
  recordsPerPage?: Maybe<Scalars["Int"]>;
  viewId?: Maybe<Scalars["CosmosObjectIdString"]>;
};

export type LastAccessedViewInput = {
  assignedToMe?: InputMaybe<Scalars["Boolean"]>;
  recordsPerPage?: InputMaybe<Scalars["Int"]>;
  viewId?: InputMaybe<Scalars["CosmosObjectIdString"]>;
};

export type License = {
  __typename?: "License";
  _id?: Maybe<Scalars["ID"]>;
  data?: Maybe<LicenseData>;
  review?: Maybe<LicenseReview>;
};

export type LicenseChecklist = {
  __typename?: "LicenseChecklist";
  licensureApplicantLicenseOnPoint?: Maybe<Scalars["String"]>;
  licensureCompleteLegible?: Maybe<Scalars["String"]>;
  licensureDisciplinaryAction?: Maybe<Scalars["String"]>;
  licensureDocumentIssueDateAcceptable?: Maybe<Scalars["String"]>;
  licensureIssuedAppropriateAuthority?: Maybe<Scalars["String"]>;
  licensureNameValidation?: Maybe<Scalars["String"]>;
  licensureTranslationStatus?: Maybe<Scalars["String"]>;
  licensureUnrestrictedLicense?: Maybe<Scalars["String"]>;
  verificationActiveLicense?: Maybe<Scalars["String"]>;
  verificationAuthorized?: Maybe<Scalars["String"]>;
  verificationGoodStanding?: Maybe<Scalars["String"]>;
  verificationPrimarySource?: Maybe<Scalars["String"]>;
};

export type LicenseChecklistInput = {
  licensureApplicantLicenseOnPoint: Scalars["String"];
  licensureCompleteLegible: Scalars["String"];
  licensureDisciplinaryAction: Scalars["String"];
  licensureDocumentIssueDateAcceptable: Scalars["String"];
  licensureIssuedAppropriateAuthority: Scalars["String"];
  licensureNameValidation: Scalars["String"];
  licensureTranslationStatus: Scalars["String"];
  licensureUnrestrictedLicense: Scalars["String"];
  verificationActiveLicense: Scalars["String"];
  verificationAuthorized: Scalars["String"];
  verificationGoodStanding: Scalars["String"];
  verificationPrimarySource: Scalars["String"];
};

export type LicenseData = {
  __typename?: "LicenseData";
  _id?: Maybe<Scalars["ID"]>;
  documents?: Maybe<Array<Maybe<Document>>>;
  hasDisciplinaryAction?: Maybe<Scalars["Boolean"]>;
  isCurrentlyLicensed?: Maybe<Scalars["Boolean"]>;
  licenseExpiryDate?: Maybe<Scalars["String"]>;
  licenseIssueDate?: Maybe<Scalars["String"]>;
  licensureDocumentStatus?: Maybe<Scalars["String"]>;
  regulatoryAuthorityRef?: Maybe<RegulatoryAuthorityResponseType>;
};

export type LicenseDataInput = {
  documents?: InputMaybe<Array<InputMaybe<DocumentInput>>>;
  hasDisciplinaryAction?: InputMaybe<Scalars["Boolean"]>;
  isCurrentlyLicensed?: InputMaybe<Scalars["Boolean"]>;
  licenseExpiryDate?: InputMaybe<Scalars["String"]>;
  licenseIssueDate?: InputMaybe<Scalars["String"]>;
  licensureDocumentStatus?: InputMaybe<Scalars["String"]>;
  regulatoryAuthorityRef?: InputMaybe<RegulatoryAuthorityInput>;
};

export type LicenseInput = {
  data?: InputMaybe<LicenseDataInput>;
};

export type LicenseReview = {
  __typename?: "LicenseReview";
  checklist?: Maybe<LicenseChecklist>;
  checklistState?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
  updatedBy?: Maybe<UserType>;
  updatedTimestamp?: Maybe<Scalars["DateString"]>;
};

export type LicenseReviewInput = {
  checklist?: InputMaybe<LicenseChecklistInput>;
  checklistState?: InputMaybe<Scalars["String"]>;
};

export type MiniCexAdditionalInfoInput = {
  additionalInfoText?: InputMaybe<Scalars["String"]>;
  categories?: InputMaybe<
    Array<InputMaybe<MiniCexCategoryAdditionalInfoInput>>
  >;
};

export type MiniCexAssessmentType = {
  __typename?: "MiniCEXAssessmentType";
  categories?: Maybe<Array<Maybe<AssessmentCategoryType>>>;
  output?: Maybe<Scalars["String"]>;
  timestamp?: Maybe<Scalars["DateString"]>;
};

export type MiniCexAttestationType = {
  __typename?: "MiniCEXAttestationType";
  attestedAt?: Maybe<Array<Maybe<Scalars["DateString"]>>>;
  hasApplicantEnrolledInClinicalRotation?: Maybe<Scalars["String"]>;
};

export type MiniCexByPhysicianFilterCaseManagerInput = {
  email: Scalars["String"];
  responseType?: InputMaybe<Scalars["String"]>;
};

export type MiniCexCategoryAdditionalInfoInput = {
  name?: InputMaybe<Scalars["String"]>;
  reasonText?: InputMaybe<Scalars["String"]>;
};

export type MiniCexCategoryScoreInput = {
  name?: InputMaybe<Scalars["String"]>;
  score?: InputMaybe<Scalars["Int"]>;
};

export type MiniCexCategoryType = {
  __typename?: "MiniCEXCategoryType";
  isAdditionalInfoRequired?: Maybe<Scalars["Boolean"]>;
  name?: Maybe<Scalars["String"]>;
  reasonText?: Maybe<Scalars["String"]>;
  score?: Maybe<Scalars["Int"]>;
};

export type MiniCexChecklist = {
  __typename?: "MiniCEXChecklist";
  minicexComplete?: Maybe<Scalars["String"]>;
  minicexScoreAcceptable?: Maybe<Scalars["String"]>;
};

export type MiniCexChecklistInput = {
  minicexComplete: Scalars["String"];
  minicexScoreAcceptable: Scalars["String"];
};

export type MiniCexConfigMgmtType = {
  __typename?: "MiniCEXConfigMgmtType";
  config?: Maybe<MiniCexConfigRecordType>;
  configName?: Maybe<Scalars["String"]>;
  createdAt?: Maybe<Scalars["DateString"]>;
  createdUser?: Maybe<Scalars["String"]>;
  dataVersion?: Maybe<Scalars["Int"]>;
  dateFrom?: Maybe<Scalars["DateString"]>;
  isActive?: Maybe<Scalars["Boolean"]>;
  minicexConfigRef?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["DateString"]>;
  updatedUser?: Maybe<Scalars["String"]>;
};

export type MiniCexConfigRecordType = {
  __typename?: "MiniCEXConfigRecordType";
  deadline?: Maybe<Array<Maybe<MiniCexDeadlineConfigType>>>;
  evaluationCategory?: Maybe<Array<Maybe<MiniCexEvaluationCategoryConfigType>>>;
  pathwaySeason?: Maybe<Scalars["String"]>;
  submission?: Maybe<MiniCexSubmissionConfigType>;
};

export type MiniCexConfigType = {
  __typename?: "MiniCEXConfigType";
  config?: Maybe<MiniCexConfigRecordType>;
  minicexConfigRef?: Maybe<Scalars["CosmosObjectIdString"]>;
};

export type MiniCexCountryInput = {
  name?: InputMaybe<Scalars["String"]>;
  ref?: InputMaybe<Scalars["String"]>;
};

export type MiniCexCountryType = {
  __typename?: "MiniCEXCountryType";
  name?: Maybe<Scalars["String"]>;
  ref?: Maybe<Scalars["String"]>;
};

export type MiniCexData = {
  __typename?: "MiniCEXData";
  _id?: Maybe<Scalars["ID"]>;
  acknowledgedTimestamp?: Maybe<Scalars["DateString"]>;
  documents?: Maybe<Array<Maybe<Document>>>;
};

export type MiniCexDataInput = {
  documents?: InputMaybe<Array<InputMaybe<DocumentInput>>>;
};

export type MiniCexDeadlineConfigType = {
  __typename?: "MiniCEXDeadlineConfigType";
  deadlineInDays?: Maybe<Scalars["Int"]>;
  deadlineName?: Maybe<Scalars["String"]>;
};

export type MiniCexEncounterInput = {
  encounterDate?: InputMaybe<Scalars["String"]>;
  encounterSetting?: InputMaybe<Scalars["String"]>;
  facility?: InputMaybe<MiniCexFacilityInput>;
  patient?: InputMaybe<MiniCexPatientInput>;
};

export type MiniCexEncounterType = {
  __typename?: "MiniCEXEncounterType";
  encounterDate?: Maybe<Scalars["String"]>;
  encounterSetting?: Maybe<Scalars["String"]>;
  facility?: Maybe<MiniCexFacilityType>;
  patient?: Maybe<MiniCexPatientType>;
};

export type MiniCexEvaluationCategoryConfigType = {
  __typename?: "MiniCEXEvaluationCategoryConfigType";
  additionalInfo?: Maybe<CategoryAdditionalInfoType>;
  categoryName?: Maybe<Scalars["String"]>;
  qualification?: Maybe<CategoryQualificationType>;
};

export type MiniCexEvaluationInput = {
  categories?: InputMaybe<Array<InputMaybe<MiniCexCategoryScoreInput>>>;
};

export type MiniCexEvaluationType = {
  __typename?: "MiniCEXEvaluationType";
  additionalInfoText?: Maybe<Scalars["String"]>;
  categories?: Maybe<Array<Maybe<MiniCexCategoryType>>>;
};

export type MiniCexFacilityInput = {
  city?: InputMaybe<Scalars["String"]>;
  country?: InputMaybe<MiniCexCountryInput>;
  name?: InputMaybe<Scalars["String"]>;
  state?: InputMaybe<Scalars["String"]>;
};

export type MiniCexFacilityType = {
  __typename?: "MiniCEXFacilityType";
  city?: Maybe<Scalars["String"]>;
  country?: Maybe<MiniCexCountryType>;
  name?: Maybe<Scalars["String"]>;
  state?: Maybe<Scalars["String"]>;
};

export type MiniCexFormProgressType = {
  __typename?: "MiniCEXFormProgressType";
  state?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
};

export type MiniCexFormType = {
  __typename?: "MiniCEXFormType";
  attestation?: Maybe<MiniCexAttestationType>;
  encounter?: Maybe<MiniCexEncounterType>;
  evaluation?: Maybe<MiniCexEvaluationType>;
  progress?: Maybe<Array<Maybe<MiniCexFormProgressType>>>;
};

export type MiniCexListFilterApplicantInput = {
  responseType: Scalars["String"];
};

export type MiniCexListFilterCaseManagerInput = {
  responseType: Scalars["String"];
  usmleId: Scalars["String"];
};

export type MiniCexListFilterPhysicianInput = {
  responseType: Scalars["String"];
};

export type MiniCexNotificationType = {
  __typename?: "MiniCEXNotificationType";
  name?: Maybe<Scalars["String"]>;
  recipient?: Maybe<UserType>;
  status?: Maybe<Scalars["String"]>;
  timestamp?: Maybe<Scalars["DateString"]>;
};

export type MiniCexPatientInput = {
  age?: InputMaybe<Scalars["Int"]>;
  ageMonth?: InputMaybe<Scalars["String"]>;
  ageYear?: InputMaybe<Scalars["Int"]>;
  gender?: InputMaybe<Scalars["String"]>;
};

export type MiniCexPatientType = {
  __typename?: "MiniCEXPatientType";
  age?: Maybe<Scalars["Int"]>;
  ageMonth?: Maybe<Scalars["String"]>;
  ageYear?: Maybe<Scalars["Int"]>;
  gender?: Maybe<Scalars["String"]>;
};

export type MiniCexResponseType = {
  __typename?: "MiniCEXResponseType";
  dataVersion?: Maybe<Scalars["Int"]>;
  encounterId?: Maybe<Scalars["String"]>;
  form?: Maybe<MiniCexFormType>;
  minicexConfig?: Maybe<MiniCexConfigType>;
  minicexId?: Maybe<Scalars["String"]>;
  summary?: Maybe<MiniCexSummaryType>;
  uiConfig?: Maybe<UiPortalConfigType>;
};

export type MiniCexReview = {
  __typename?: "MiniCEXReview";
  checklist?: Maybe<MiniCexChecklist>;
  checklistState?: Maybe<Scalars["String"]>;
  isLocked?: Maybe<Scalars["Boolean"]>;
  status?: Maybe<Scalars["String"]>;
  updatedBy?: Maybe<UserType>;
  updatedTimestamp?: Maybe<Scalars["DateString"]>;
};

export type MiniCexReviewInput = {
  checklist?: InputMaybe<MiniCexChecklistInput>;
  checklistState?: InputMaybe<Scalars["String"]>;
};

export type MiniCexStateModelType = {
  __typename?: "MiniCEXStateModelType";
  state?: Maybe<Scalars["String"]>;
  timestamp?: Maybe<Scalars["DateString"]>;
};

export type MiniCexSubmissionConfigType = {
  __typename?: "MiniCEXSubmissionConfigType";
  maxRequestPerPhysician?: Maybe<Scalars["Int"]>;
  totalRequestCount?: Maybe<Scalars["Int"]>;
};

export type MiniCexSummaryType = {
  __typename?: "MiniCEXSummaryType";
  acceptedAt?: Maybe<Scalars["DateString"]>;
  allowedActions?: Maybe<Array<Maybe<Scalars["String"]>>>;
  applicantLastName?: Maybe<Scalars["String"]>;
  applicantRestOfName?: Maybe<Scalars["String"]>;
  completedAt?: Maybe<Scalars["DateString"]>;
  createdAt?: Maybe<Scalars["DateString"]>;
  dataVersion?: Maybe<Scalars["Int"]>;
  encounterId?: Maybe<Scalars["String"]>;
  expiresAt?: Maybe<Scalars["DateString"]>;
  isActive?: Maybe<Scalars["Boolean"]>;
  minicexId?: Maybe<Scalars["String"]>;
  notifications?: Maybe<Array<Maybe<MiniCexNotificationType>>>;
  pathwaySeason?: Maybe<Scalars["Int"]>;
  physicianEmail?: Maybe<Scalars["String"]>;
  physicianLastNameProvidedByApplicant?: Maybe<Scalars["String"]>;
  physicianLastNameProvidedByPhysician?: Maybe<Scalars["String"]>;
  physicianRestOfNameProvidedByApplicant?: Maybe<Scalars["String"]>;
  physicianRestOfNameProvidedByPhysician?: Maybe<Scalars["String"]>;
  rejectionReason?: Maybe<Scalars["String"]>;
  rejectionReasonComments?: Maybe<Scalars["String"]>;
  state?: Maybe<Scalars["String"]>;
  stateModel?: Maybe<Array<Maybe<MiniCexStateModelType>>>;
  usmleId?: Maybe<Scalars["String"]>;
};

export type MiniCexSurveyInput = {
  questions?: InputMaybe<Array<InputMaybe<MiniCexSurveyQuestionInput>>>;
};

export type MiniCexSurveyQuestionInput = {
  questionId?: InputMaybe<Scalars["String"]>;
  response?: InputMaybe<Scalars["String"]>;
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type Mutation = {
  __typename?: "Mutation";
  AdminPortal_events_republish?: Maybe<
    Array<Maybe<EventRepublishResponseType>>
  >;
  ApplicantPortal_MiniCEX_add?: Maybe<Array<Maybe<MiniCexSummaryType>>>;
  ApplicantPortal_MiniCEX_remove?: Maybe<Array<Maybe<MiniCexSummaryType>>>;
  ApplicantPortal_checkPaymentExists?: Maybe<Scalars["Boolean"]>;
  ApplicantPortal_login?: Maybe<ApplicantPortalLoginResponseType>;
  ApplicantPortal_pathway1a?: Maybe<ApplicantPortalMutationResponseType>;
  ApplicantPortal_pathway1b?: Maybe<ApplicantPortalMutationResponseType>;
  ApplicantPortal_pathway2a?: Maybe<ApplicantPortalMutationResponseType>;
  ApplicantPortal_pathway2b?: Maybe<ApplicantPortalMutationResponseType>;
  ApplicantPortal_pathway6?: Maybe<ApplicantPortalMutationResponseType>;
  ApplicantPortal_pathway345?: Maybe<ApplicantPortalMutationResponseType>;
  ApplicantPortal_pathwayX?: Maybe<ApplicantPortalMutationResponseType>;
  ApplicantPortal_runEligibilityPreCheck?: Maybe<EligibilityPreCheckResponseType>;
  AuthorityPortal_attest?: Maybe<Scalars["String"]>;
  AuthorityPortal_reject?: Maybe<Scalars["String"]>;
  CasePortal_AuthorityMgmt_syncAccountFromB2C?: Maybe<ResponseStatusType>;
  CasePortal_MiniCEX_cancel?: Maybe<MiniCexResponseType>;
  CasePortal_MiniCEX_reject?: Maybe<MiniCexResponseType>;
  CasePortal_PhyMgmt_block?: Maybe<PhysicianResponseType>;
  CasePortal_PhyMgmt_editDoB?: Maybe<PhysicianResponseType>;
  CasePortal_PhyMgmt_editName?: Maybe<PhysicianResponseType>;
  CasePortal_PhyMgmt_optIn?: Maybe<PhysicianResponseType>;
  CasePortal_PhyMgmt_optOut?: Maybe<PhysicianResponseType>;
  CasePortal_PhyMgmt_syncRegistration?: Maybe<PhysicianResponseType>;
  CasePortal_PhyMgmt_unblock?: Maybe<PhysicianResponseType>;
  CasePortal_SubmissionSummary?: Maybe<Scalars["String"]>;
  CasePortal_addCaseNote?: Maybe<CaseResponseType>;
  CasePortal_additionalDocuments?: Maybe<CaseResponseType>;
  CasePortal_archivedCase_SubmissionSummary?: Maybe<Scalars["String"]>;
  CasePortal_assignEligibilityReviewTask?: Maybe<CaseResponseType>;
  CasePortal_assignQAReviewTask?: Maybe<CaseResponseType>;
  CasePortal_case_archive?: Maybe<CaseResponseStatusType>;
  CasePortal_case_purge?: Maybe<CaseResponseStatusType>;
  CasePortal_checkCaseExists?: Maybe<Scalars["Boolean"]>;
  CasePortal_convertToExceptionCase?: Maybe<CaseResponseType>;
  CasePortal_createExceptionCase?: Maybe<CaseResponseType>;
  CasePortal_glDistributionReport?: Maybe<
    Array<Maybe<GlDistributionReportResponseType>>
  >;
  CasePortal_issuePaperCheck?: Maybe<GenericResponseType>;
  CasePortal_languageAssessment_setReview?: Maybe<CaseResponseType>;
  CasePortal_overridePathway?: Maybe<CaseResponseType>;
  CasePortal_pathway1_deleteRecord?: Maybe<CaseResponseType>;
  CasePortal_pathway1_setData?: Maybe<CaseResponseType>;
  CasePortal_pathway1_setReview?: Maybe<CaseResponseType>;
  CasePortal_pathway2_setReview?: Maybe<CaseResponseType>;
  CasePortal_pathway6_setReview?: Maybe<CaseResponseType>;
  CasePortal_pathway6_unlockReview?: Maybe<CaseResponseType>;
  CasePortal_pathway345_setReview?: Maybe<CaseResponseType>;
  CasePortal_pathwayX_setReview?: Maybe<CaseResponseType>;
  CasePortal_process_generalEligibility?: Maybe<CaseResponseType>;
  CasePortal_qa_acceptDecision?: Maybe<CaseResponseType>;
  CasePortal_qa_approve?: Maybe<CaseResponseType>;
  CasePortal_qa_overrideDecision?: Maybe<CaseResponseType>;
  CasePortal_qa_reject?: Maybe<CaseResponseType>;
  CasePortal_qa_reworkRequired?: Maybe<CaseResponseType>;
  CasePortal_reopenCase?: Maybe<CaseResponseType>;
  CasePortal_review_reworkRequired?: Maybe<CaseResponseType>;
  FeatureFlag_SASToken?: Maybe<SasToken>;
  GLDistributionConfig_create?: Maybe<GlDistributionConfig>;
  GLDistributionConfig_deactivate?: Maybe<ResponseStatusType>;
  GLDistributionConfig_edit?: Maybe<GlDistributionConfig>;
  Job_processEligibilityChecks?: Maybe<JobEligibilityChecksResponseType>;
  PhysicianPortal_Mgmt_optOut?: Maybe<PhysicianResponseType>;
  PhysicianPortal_Mgmt_optOut_noauth?: Maybe<ResponseStatusType>;
  PhysicianPortal_Mgmt_setProfile?: Maybe<PhysicianResponseType>;
  PhysicianPortal_MiniCEX_accept?: Maybe<MiniCexResponseType>;
  PhysicianPortal_MiniCEX_attest?: Maybe<MiniCexResponseType>;
  PhysicianPortal_MiniCEX_complete?: Maybe<MiniCexResponseType>;
  PhysicianPortal_MiniCEX_get?: Maybe<MiniCexResponseType>;
  PhysicianPortal_MiniCEX_reject?: Maybe<MiniCexResponseType>;
  PhysicianPortal_MiniCEX_setAdditionalInfo?: Maybe<MiniCexResponseType>;
  PhysicianPortal_MiniCEX_setEncounter?: Maybe<MiniCexResponseType>;
  PhysicianPortal_MiniCEX_setEvaluation?: Maybe<MiniCexResponseType>;
  PhysicianPortal_MiniCEX_setSurvey?: Maybe<ResponseStatusType>;
  PhysicianRegAuthority?: Maybe<
    Array<Maybe<PhysicianRegAuthorityResponseType>>
  >;
  /** IGNORE: Dummy field necessary for the Mutation type to be valid */
  _empty?: Maybe<Scalars["String"]>;
  addDashboard?: Maybe<Dashboard>;
  addOETViewFilters?: Maybe<Dashboard>;
  addViewFilters?: Maybe<Dashboard>;
  deleteDashboard?: Maybe<DeleteDashboardResponse>;
  deleteOETViewFilter?: Maybe<DeleteDashboardResponse>;
  deleteViewFilter?: Maybe<DeleteDashboardResponse>;
  deleteViewFilters?: Maybe<DeleteDashboardResponse>;
  requestRefund?: Maybe<RequestPayload>;
  requestSecureToken?: Maybe<TokenPayload>;
  saveLastAccessedView?: Maybe<Dashboard>;
  saveOETViewFilters?: Maybe<Dashboard>;
  saveViewFilters?: Maybe<Dashboard>;
  uploadDocument?: Maybe<SasToken>;
  validateEmail?: Maybe<ValidationResult>;
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationAdminPortal_Events_RepublishArgs = {
  parentIds: Array<Scalars["String"]>;
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationApplicantPortal_MiniCex_AddArgs = {
  input: AddMiniCexInput;
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationApplicantPortal_MiniCex_RemoveArgs = {
  dataVersion: Scalars["Int"];
  encounterId: Scalars["String"];
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationApplicantPortal_LoginArgs = {
  password: Scalars["String"];
  userid: Scalars["String"];
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationApplicantPortal_Pathway1aArgs = {
  input: Pathway1Input;
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationApplicantPortal_Pathway1bArgs = {
  input: Pathway1Input;
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationApplicantPortal_Pathway2aArgs = {
  input: Pathway2Input;
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationApplicantPortal_Pathway2bArgs = {
  input?: InputMaybe<Pathway2Input>;
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationApplicantPortal_PathwayXArgs = {
  input: ExceptionRequestInput;
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationAuthorityPortal_AttestArgs = {
  dataVersion: Scalars["Int"];
  usmleId: Scalars["String"];
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationAuthorityPortal_RejectArgs = {
  dataVersion: Scalars["Int"];
  rejectionReason: Scalars["String"];
  rejectionReasonText?: InputMaybe<Scalars["String"]>;
  usmleId: Scalars["String"];
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationCasePortal_AuthorityMgmt_SyncAccountFromB2CArgs = {
  userName?: InputMaybe<Scalars["String"]>;
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationCasePortal_MiniCex_CancelArgs = {
  encounterId: Scalars["String"];
  isMiniCEXAssessmentResetConfirmed: Scalars["Boolean"];
  minicexDataVersion: Scalars["Int"];
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationCasePortal_MiniCex_RejectArgs = {
  encounterId: Scalars["String"];
  minicexDataVersion: Scalars["Int"];
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationCasePortal_PhyMgmt_BlockArgs = {
  dataVersion: Scalars["Int"];
  email: Scalars["String"];
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationCasePortal_PhyMgmt_EditDoBArgs = {
  dataVersion: Scalars["Int"];
  email: Scalars["String"];
  input: PhysicianDoBInput;
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationCasePortal_PhyMgmt_EditNameArgs = {
  dataVersion: Scalars["Int"];
  email: Scalars["String"];
  input?: InputMaybe<PhysicianNameInput>;
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationCasePortal_PhyMgmt_OptInArgs = {
  dataVersion: Scalars["Int"];
  email: Scalars["String"];
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationCasePortal_PhyMgmt_OptOutArgs = {
  dataVersion: Scalars["Int"];
  email: Scalars["String"];
  input?: InputMaybe<PhysicianOptOutInput>;
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationCasePortal_PhyMgmt_SyncRegistrationArgs = {
  dataVersion: Scalars["Int"];
  email: Scalars["String"];
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationCasePortal_PhyMgmt_UnblockArgs = {
  dataVersion: Scalars["Int"];
  email: Scalars["String"];
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationCasePortal_SubmissionSummaryArgs = {
  usmleId: Scalars["String"];
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationCasePortal_AddCaseNoteArgs = {
  dataVersion: Scalars["Int"];
  input: CaseNoteInput;
  usmleId: Scalars["String"];
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationCasePortal_AdditionalDocumentsArgs = {
  dataVersion: Scalars["Int"];
  input: Array<AdditionalDocumentInput>;
  usmleId: Scalars["String"];
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationCasePortal_ArchivedCase_SubmissionSummaryArgs = {
  caseId: Scalars["String"];
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationCasePortal_AssignEligibilityReviewTaskArgs = {
  dataVersion: Scalars["Int"];
  usmleId: Scalars["String"];
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationCasePortal_AssignQaReviewTaskArgs = {
  dataVersion: Scalars["Int"];
  usmleId: Scalars["String"];
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationCasePortal_Case_ArchiveArgs = {
  dataVersion: Scalars["Int"];
  usmleId: Scalars["String"];
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationCasePortal_Case_PurgeArgs = {
  dataVersion: Scalars["Int"];
  usmleId: Scalars["String"];
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationCasePortal_CheckCaseExistsArgs = {
  usmleId: Scalars["String"];
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationCasePortal_ConvertToExceptionCaseArgs = {
  dataVersion: Scalars["Int"];
  usmleId: Scalars["String"];
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationCasePortal_CreateExceptionCaseArgs = {
  usmleId: Scalars["String"];
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationCasePortal_GlDistributionReportArgs = {
  filter?: InputMaybe<GlDistributionReportFilterInput>;
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationCasePortal_IssuePaperCheckArgs = {
  amount: Scalars["Int"];
  dataVersion?: InputMaybe<Scalars["Int"]>;
  usmleId: Scalars["String"];
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationCasePortal_LanguageAssessment_SetReviewArgs = {
  dataVersion: Scalars["Int"];
  input: LanguageAssessmentReviewInput;
  isComplete: Scalars["Boolean"];
  recordId?: InputMaybe<Scalars["ID"]>;
  usmleId: Scalars["String"];
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationCasePortal_OverridePathwayArgs = {
  dataVersion: Scalars["Int"];
  input: OverridePathwayInput;
  usmleId: Scalars["String"];
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationCasePortal_Pathway1_DeleteRecordArgs = {
  dataVersion: Scalars["Int"];
  recordId: Scalars["ID"];
  usmleId: Scalars["String"];
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationCasePortal_Pathway1_SetDataArgs = {
  dataVersion: Scalars["Int"];
  input: LicenseDataInput;
  recordId?: InputMaybe<Scalars["ID"]>;
  usmleId: Scalars["String"];
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationCasePortal_Pathway1_SetReviewArgs = {
  dataVersion: Scalars["Int"];
  input: LicenseReviewInput;
  isComplete: Scalars["Boolean"];
  recordId: Scalars["ID"];
  usmleId: Scalars["String"];
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationCasePortal_Pathway2_SetReviewArgs = {
  dataVersion: Scalars["Int"];
  input: CsExamReviewInput;
  isComplete: Scalars["Boolean"];
  recordId?: InputMaybe<Scalars["ID"]>;
  usmleId: Scalars["String"];
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationCasePortal_Pathway6_SetReviewArgs = {
  dataVersion: Scalars["Int"];
  input: MiniCexReviewInput;
  isComplete: Scalars["Boolean"];
  recordId?: InputMaybe<Scalars["ID"]>;
  usmleId: Scalars["String"];
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationCasePortal_Pathway6_UnlockReviewArgs = {
  dataVersion: Scalars["Int"];
  reason?: InputMaybe<Scalars["String"]>;
  recordId: Scalars["ID"];
  usmleId: Scalars["String"];
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationCasePortal_Pathway345_SetReviewArgs = {
  dataVersion: Scalars["Int"];
  input: EducationReviewInput;
  isComplete: Scalars["Boolean"];
  recordId?: InputMaybe<Scalars["ID"]>;
  usmleId: Scalars["String"];
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationCasePortal_PathwayX_SetReviewArgs = {
  dataVersion: Scalars["Int"];
  input: ExceptionRequestReviewInput;
  isComplete: Scalars["Boolean"];
  recordId: Scalars["ID"];
  usmleId: Scalars["String"];
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationCasePortal_Process_GeneralEligibilityArgs = {
  dataVersion: Scalars["Int"];
  usmleId: Scalars["String"];
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationCasePortal_Qa_AcceptDecisionArgs = {
  dataVersion: Scalars["Int"];
  usmleId: Scalars["String"];
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationCasePortal_Qa_ApproveArgs = {
  dataVersion: Scalars["Int"];
  usmleId: Scalars["String"];
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationCasePortal_Qa_OverrideDecisionArgs = {
  comments: Scalars["String"];
  dataVersion: Scalars["Int"];
  decision: Scalars["String"];
  usmleId: Scalars["String"];
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationCasePortal_Qa_RejectArgs = {
  dataVersion: Scalars["Int"];
  usmleId: Scalars["String"];
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationCasePortal_Qa_ReworkRequiredArgs = {
  dataVersion: Scalars["Int"];
  processGEChecksOnQARework: Scalars["Boolean"];
  usmleId: Scalars["String"];
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationCasePortal_ReopenCaseArgs = {
  dataVersion: Scalars["Int"];
  usmleId: Scalars["String"];
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationCasePortal_Review_ReworkRequiredArgs = {
  dataVersion: Scalars["Int"];
  usmleId: Scalars["String"];
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationGlDistributionConfig_CreateArgs = {
  input: GlDistributionConfigInput;
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationGlDistributionConfig_DeactivateArgs = {
  _id: Scalars["ID"];
  version: Scalars["Int"];
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationGlDistributionConfig_EditArgs = {
  _id: Scalars["ID"];
  input: GlDistributionConfigEditInput;
  version: Scalars["Int"];
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationPhysicianPortal_Mgmt_OptOutArgs = {
  dataVersion: Scalars["Int"];
  email: Scalars["String"];
  input?: InputMaybe<PhysicianOptOutInput>;
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationPhysicianPortal_Mgmt_OptOut_NoauthArgs = {
  email: Scalars["String"];
  input?: InputMaybe<PhysicianOptOutInput>;
  token: Scalars["String"];
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationPhysicianPortal_Mgmt_SetProfileArgs = {
  dataVersion: Scalars["Int"];
  input: PhysicianProfileInput;
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationPhysicianPortal_MiniCex_AcceptArgs = {
  dataVersion: Scalars["Int"];
  encounterId: Scalars["String"];
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationPhysicianPortal_MiniCex_AttestArgs = {
  dataVersion: Scalars["Int"];
  encounterId: Scalars["String"];
  input?: InputMaybe<AttestMiniCexInput>;
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationPhysicianPortal_MiniCex_CompleteArgs = {
  dataVersion: Scalars["Int"];
  encounterId: Scalars["String"];
  preSubmissionSurvey?: InputMaybe<PhysicianMiniCexPreSubmissionSurveyInput>;
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationPhysicianPortal_MiniCex_GetArgs = {
  encounterId: Scalars["String"];
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationPhysicianPortal_MiniCex_RejectArgs = {
  dataVersion: Scalars["Int"];
  encounterId: Scalars["String"];
  input: RejectMiniCexInput;
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationPhysicianPortal_MiniCex_SetAdditionalInfoArgs = {
  dataVersion: Scalars["Int"];
  encounterId: Scalars["String"];
  input: MiniCexAdditionalInfoInput;
  isComplete: Scalars["Boolean"];
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationPhysicianPortal_MiniCex_SetEncounterArgs = {
  dataVersion: Scalars["Int"];
  encounterId: Scalars["String"];
  input: MiniCexEncounterInput;
  isComplete: Scalars["Boolean"];
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationPhysicianPortal_MiniCex_SetEvaluationArgs = {
  dataVersion: Scalars["Int"];
  encounterId: Scalars["String"];
  input: MiniCexEvaluationInput;
  isComplete: Scalars["Boolean"];
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationPhysicianPortal_MiniCex_SetSurveyArgs = {
  dataVersion: Scalars["Int"];
  encounterId: Scalars["String"];
  input?: InputMaybe<MiniCexSurveyInput>;
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationPhysicianRegAuthorityArgs = {
  input?: InputMaybe<PhysicianRegAuthorityInput>;
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationAddDashboardArgs = {
  input: DashboardInput;
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationAddOetViewFiltersArgs = {
  input: OetViewsInput;
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationAddViewFiltersArgs = {
  input: ViewsInput;
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationDeleteDashboardArgs = {
  _id: Scalars["ID"];
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationDeleteOetViewFilterArgs = {
  oetViewId: Scalars["ID"];
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationDeleteViewFilterArgs = {
  viewId: Scalars["ID"];
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationDeleteViewFiltersArgs = {
  dashboardId: Scalars["ID"];
  dashboardType: Scalars["String"];
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationRequestRefundArgs = {
  requestArgument?: InputMaybe<RefundRequestArgument>;
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationRequestSecureTokenArgs = {
  requestArgument?: InputMaybe<TokenRequestArgument>;
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationSaveLastAccessedViewArgs = {
  input: LastAccessedViewInput;
  viewType: Scalars["String"];
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationSaveOetViewFiltersArgs = {
  input: OetViewsInput;
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationSaveViewFiltersArgs = {
  input: ViewsInput;
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationUploadDocumentArgs = {
  fileName: Scalars["String"];
};

/**  Base Mutation Type definition - all mutations will be defined in separate files extending this type  */
export type MutationValidateEmailArgs = {
  email: Scalars["String"];
};

export type OetInput = {
  candidateId: Scalars["String"];
  dateOfBirth?: InputMaybe<Scalars["String"]>;
  examDate?: InputMaybe<Scalars["String"]>;
  examineeFirstName?: InputMaybe<Scalars["String"]>;
  examineeLastName?: InputMaybe<Scalars["String"]>;
  gender?: InputMaybe<Scalars["String"]>;
  isMatched?: InputMaybe<Scalars["Boolean"]>;
  listeningScore?: InputMaybe<Scalars["Float"]>;
  readingScore?: InputMaybe<Scalars["Float"]>;
  result?: InputMaybe<Scalars["String"]>;
  schemaVersion: Scalars["String"];
  speakingScore?: InputMaybe<Scalars["Float"]>;
  testingCenter?: InputMaybe<Scalars["String"]>;
  usmleId?: InputMaybe<Scalars["String"]>;
  writingScore?: InputMaybe<Scalars["Float"]>;
};

export type OetViewFilters = {
  __typename?: "OETViewFilters";
  matchState?: Maybe<Scalars["Boolean"]>;
  resultState?: Maybe<Array<Maybe<Scalars["String"]>>>;
};

export type OetViewFiltersInput = {
  matchState?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  resultState?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type OetViews = {
  __typename?: "OETViews";
  _id?: Maybe<Scalars["CosmosObjectIdString"]>;
  displayColumns?: Maybe<Array<Maybe<Scalars["String"]>>>;
  viewFilters?: Maybe<OetViewFilters>;
  viewName?: Maybe<Scalars["String"]>;
};

export type OetViewsInput = {
  _id?: InputMaybe<Scalars["CosmosObjectIdString"]>;
  displayColumns?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  viewFilters?: InputMaybe<OetViewFiltersInput>;
  viewName?: InputMaybe<Scalars["String"]>;
};

export type OetPortalResponseType = {
  __typename?: "OetPortalResponseType";
  oetscores?: Maybe<Array<Maybe<OetResponseType>>>;
};

export type OetResponseType = {
  __typename?: "OetResponseType";
  _id?: Maybe<Scalars["String"]>;
  candidateId?: Maybe<Scalars["String"]>;
  createdAt?: Maybe<Scalars["DateString"]>;
  createdUser?: Maybe<Scalars["String"]>;
  dateOfBirth?: Maybe<Scalars["String"]>;
  examDate?: Maybe<Scalars["String"]>;
  examineeFirstName?: Maybe<Scalars["String"]>;
  examineeLastName?: Maybe<Scalars["String"]>;
  gender?: Maybe<Scalars["String"]>;
  listeningScore?: Maybe<Scalars["String"]>;
  matched?: Maybe<Scalars["String"]>;
  matchedCandidateId?: Maybe<Scalars["String"]>;
  matchedCaseState?: Maybe<Scalars["String"]>;
  matchedDateOfBirth?: Maybe<Scalars["String"]>;
  matchedEnglishEligibilityStatus?: Maybe<Scalars["String"]>;
  matchedFirstName?: Maybe<Scalars["String"]>;
  matchedLastName?: Maybe<Scalars["String"]>;
  matchedUsmleId?: Maybe<Scalars["String"]>;
  matchedexamDate?: Maybe<Scalars["String"]>;
  readingScore?: Maybe<Scalars["String"]>;
  result?: Maybe<Scalars["String"]>;
  speakingScore?: Maybe<Scalars["String"]>;
  testingCenter?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["DateString"]>;
  updatedUser?: Maybe<Scalars["String"]>;
  usmleId?: Maybe<Scalars["String"]>;
  writingScore?: Maybe<Scalars["String"]>;
};

export type OverridePathwayInput = {
  currentPathway: Scalars["String"];
  usmleId: Scalars["String"];
};

export type PastAttemptsType = {
  __typename?: "PastAttemptsType";
  applicationType?: Maybe<Scalars["String"]>;
  completedAt?: Maybe<Scalars["DateString"]>;
  output?: Maybe<Scalars["String"]>;
  pathway?: Maybe<Scalars["String"]>;
  pathwaySeason?: Maybe<Scalars["String"]>;
  refundIssued?: Maybe<Scalars["String"]>;
  rejectionReason?: Maybe<Array<Maybe<Scalars["String"]>>>;
  submittedAt?: Maybe<Scalars["DateString"]>;
};

export type Pathway1 = {
  __typename?: "Pathway1";
  applicantProvidedData?: Maybe<Array<Maybe<License>>>;
  staffProvidedData?: Maybe<Array<Maybe<License>>>;
  userResponse?: Maybe<Pathway1UserResponse>;
};

export type Pathway1Input = {
  applicantProvidedData?: InputMaybe<Array<InputMaybe<LicenseInput>>>;
  userResponse?: InputMaybe<Pathway1UserResponseInput>;
};

export type Pathway1UserResponse = {
  __typename?: "Pathway1UserResponse";
  isCurrentlyLicensed?: Maybe<Scalars["Boolean"]>;
  wasLicensedInRecentPast?: Maybe<Scalars["Boolean"]>;
};

export type Pathway1UserResponseInput = {
  isCurrentlyLicensed?: InputMaybe<Scalars["Boolean"]>;
  wasLicensedInRecentPast?: InputMaybe<Scalars["Boolean"]>;
};

export type Pathway2 = {
  __typename?: "Pathway2";
  applicantProvidedData?: Maybe<Array<Maybe<CsExam>>>;
  staffProvidedData?: Maybe<Array<Maybe<CsExam>>>;
  userResponse?: Maybe<Pathway2UserResponse>;
};

export type Pathway2Input = {
  applicantProvidedData?: InputMaybe<Array<InputMaybe<CsExamInput>>>;
  userResponse?: InputMaybe<Pathway2UserResponseInput>;
};

export type Pathway2UserResponse = {
  __typename?: "Pathway2UserResponse";
  hasPassedCSExamInRecentPast?: Maybe<Scalars["Boolean"]>;
  hasPassedFromQualifiedSchoolInRecentPast?: Maybe<Scalars["Boolean"]>;
};

export type Pathway2UserResponseInput = {
  hasPassedCSExamInRecentPast?: InputMaybe<Scalars["Boolean"]>;
  hasPassedFromQualifiedSchoolInRecentPast?: InputMaybe<Scalars["Boolean"]>;
};

export type Pathway6 = {
  __typename?: "Pathway6";
  applicantProvidedData?: Maybe<Array<Maybe<Pathway6MiniCexType>>>;
  minicexList?: Maybe<Array<Maybe<MiniCexResponseType>>>;
  staffProvidedData?: Maybe<Array<Maybe<Pathway6MiniCexType>>>;
  userResponse?: Maybe<Pathway6UserResponse>;
};

export type Pathway6MinicexListArgs = {
  filter: MiniCexListFilterCaseManagerInput;
};

export type Pathway6Input = {
  applicantProvidedData?: InputMaybe<Array<InputMaybe<Pathway6MiniCexInput>>>;
  userResponse?: InputMaybe<Pathway6UserResponseInput>;
};

export type Pathway6MiniCexInput = {
  data?: InputMaybe<MiniCexDataInput>;
};

export type Pathway6MiniCexType = {
  __typename?: "Pathway6MiniCEXType";
  _id?: Maybe<Scalars["ID"]>;
  areAllMiniCEXComplete?: Maybe<Scalars["Boolean"]>;
  assessment?: Maybe<MiniCexAssessmentType>;
  data?: Maybe<MiniCexData>;
  review?: Maybe<MiniCexReview>;
};

export type Pathway6UserResponse = {
  __typename?: "Pathway6UserResponse";
  hasAgreedToProvidePhysicianInfo?: Maybe<Scalars["Boolean"]>;
};

export type Pathway6UserResponseInput = {
  hasAgreedToProvidePhysicianInfo?: InputMaybe<Scalars["Boolean"]>;
};

export type Pathway345 = {
  __typename?: "Pathway345";
  applicantProvidedData?: Maybe<Array<Maybe<Education>>>;
  staffProvidedData?: Maybe<Array<Maybe<Education>>>;
  userResponse?: Maybe<Pathway345UserResponse>;
};

export type Pathway345Input = {
  applicantProvidedData?: InputMaybe<Array<InputMaybe<EducationInput>>>;
  userResponse?: InputMaybe<Pathway345UserResponseInput>;
};

export type Pathway345UserResponse = {
  __typename?: "Pathway345UserResponse";
  isGradDateInAcceptableRange?: Maybe<Scalars["Boolean"]>;
  isGradSchoolEligible?: Maybe<Scalars["Boolean"]>;
};

export type Pathway345UserResponseInput = {
  isGradDateInAcceptableRange?: InputMaybe<Scalars["Boolean"]>;
  isGradSchoolEligible?: InputMaybe<Scalars["Boolean"]>;
};

export type Payment = {
  __typename?: "Payment";
  amt?: Maybe<Scalars["Int"]>;
  pnRef?: Maybe<Scalars["String"]>;
  transTime?: Maybe<Scalars["DateString"]>;
};

export type PhysicianByMiniCexFilterCaseManagerInput = {
  responseType?: InputMaybe<Scalars["String"]>;
  usmleId: Scalars["String"];
};

export type PhysicianConfigRecordType = {
  __typename?: "PhysicianConfigRecordType";
  minicex?: Maybe<PhysicianMiniCexConfigType>;
};

export type PhysicianConfigType = {
  __typename?: "PhysicianConfigType";
  config?: Maybe<PhysicianConfigRecordType>;
  physicianConfigRef?: Maybe<Scalars["CosmosObjectIdString"]>;
};

export type PhysicianDoBInput = {
  dateOfBirth: Scalars["Date"];
};

export type PhysicianFaimerSurveyQuestionType = {
  __typename?: "PhysicianFaimerSurveyQuestionType";
  comments?: Maybe<Scalars["String"]>;
  questionDescription?: Maybe<Scalars["String"]>;
  questionId?: Maybe<Scalars["String"]>;
  response?: Maybe<Scalars["String"]>;
};

export type PhysicianFaimerSurveyType = {
  __typename?: "PhysicianFaimerSurveyType";
  completedAt?: Maybe<Scalars["Date"]>;
  pathwaySeason?: Maybe<Scalars["Int"]>;
  questions?: Maybe<Array<Maybe<PhysicianFaimerSurveyQuestionType>>>;
};

export type PhysicianLicenseInput = {
  _id?: InputMaybe<Scalars["String"]>;
  countryName: Scalars["String"];
  licenseNumber: Scalars["String"];
  orgName: Scalars["String"];
  physicianRegAuthorityRef: Scalars["Int"];
};

export type PhysicianLicenseRecordType = {
  __typename?: "PhysicianLicenseRecordType";
  _id?: Maybe<Scalars["String"]>;
  addedAt?: Maybe<Scalars["DateString"]>;
  countryName?: Maybe<Scalars["String"]>;
  licenseNumber?: Maybe<Scalars["String"]>;
  orgName?: Maybe<Scalars["String"]>;
  physicianRegAuthorityRef?: Maybe<Scalars["Int"]>;
  removedAt?: Maybe<Scalars["DateString"]>;
};

export type PhysicianMiniCexConfigType = {
  __typename?: "PhysicianMiniCEXConfigType";
  maxApplicantsAllowedToBeEvaluatedByPhysician?: Maybe<Scalars["Int"]>;
};

export type PhysicianMiniCexPreSubmissionSurveyInput = {
  questions?: InputMaybe<
    Array<InputMaybe<PhysicianMiniCexPreSubmissionSurveyQuestionInput>>
  >;
};

export type PhysicianMiniCexPreSubmissionSurveyQuestionInput = {
  comments?: InputMaybe<Scalars["String"]>;
  questionDescription?: InputMaybe<Scalars["String"]>;
  questionId?: InputMaybe<Scalars["String"]>;
  response?: InputMaybe<Scalars["String"]>;
};

export type PhysicianMinicexAccountType = {
  __typename?: "PhysicianMinicexAccountType";
  accountStatus?: Maybe<Scalars["String"]>;
  accountStatusComments?: Maybe<Scalars["String"]>;
  faimerSurveyCurrentPathwaySeason?: Maybe<PhysicianFaimerSurveyType>;
  optOut?: Maybe<PhysicianOptOutType>;
  profileStatus?: Maybe<Scalars["String"]>;
  profileStatusComments?: Maybe<Array<Maybe<Scalars["String"]>>>;
  tags?: Maybe<PhysicianTagsType>;
};

export type PhysicianNameInput = {
  email: Scalars["String"];
  lastName: Scalars["String"];
  restOfName?: InputMaybe<Scalars["String"]>;
};

export type PhysicianOptOutInput = {
  reason?: InputMaybe<Scalars["String"]>;
  reasonComments?: InputMaybe<Scalars["String"]>;
};

export type PhysicianOptOutType = {
  __typename?: "PhysicianOptOutType";
  lastAccountStatus?: Maybe<Scalars["String"]>;
  reason?: Maybe<Scalars["String"]>;
  reasonComments?: Maybe<Scalars["String"]>;
  timestamp?: Maybe<Scalars["DateString"]>;
};

export type PhysicianPortalResponseType = {
  __typename?: "PhysicianPortalResponseType";
  minicex?: Maybe<MiniCexResponseType>;
  minicexList?: Maybe<Array<Maybe<MiniCexSummaryType>>>;
  physician?: Maybe<PhysicianResponseType>;
};

export type PhysicianPortalResponseTypeMinicexArgs = {
  encounterId: Scalars["String"];
};

export type PhysicianPortalResponseTypeMinicexListArgs = {
  filter: MiniCexListFilterPhysicianInput;
};

export type PhysicianProfileInput = {
  dateOfBirth: Scalars["Date"];
  isHoldingFacultyAppointment: Scalars["String"];
  lastName: Scalars["String"];
  licenses?: InputMaybe<Array<InputMaybe<PhysicianLicenseInput>>>;
  medicalSpecialty?: InputMaybe<Scalars["String"]>;
  restOfName?: InputMaybe<Scalars["String"]>;
  secondaryEmails?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  usmleId?: InputMaybe<Scalars["String"]>;
};

export type PhysicianProfileType = {
  __typename?: "PhysicianProfileType";
  dateOfBirth?: Maybe<Scalars["Date"]>;
  isHoldingFacultyAppointment?: Maybe<Scalars["String"]>;
  lastName?: Maybe<Scalars["String"]>;
  licenses?: Maybe<Array<Maybe<PhysicianLicenseRecordType>>>;
  medicalSpecialty?: Maybe<Scalars["String"]>;
  removedLicenses?: Maybe<Array<Maybe<PhysicianLicenseRecordType>>>;
  restOfName?: Maybe<Scalars["String"]>;
  secondaryEmails?: Maybe<Array<Maybe<Scalars["String"]>>>;
  usmleId?: Maybe<Scalars["String"]>;
};

export type PhysicianResponseType = {
  __typename?: "PhysicianResponseType";
  activityLog?: Maybe<Array<Maybe<ActivityLogType>>>;
  allowedActions?: Maybe<Array<Maybe<Scalars["String"]>>>;
  applicantsEvaluatedByPhysician?: Maybe<Array<Maybe<A_Applicant>>>;
  dataVersion?: Maybe<Scalars["Int"]>;
  email?: Maybe<Scalars["String"]>;
  minicex?: Maybe<PhysicianMinicexAccountType>;
  oid?: Maybe<Scalars["String"]>;
  physicianConfig?: Maybe<PhysicianConfigType>;
  physicianId?: Maybe<Scalars["String"]>;
  profile?: Maybe<PhysicianProfileType>;
};

export type PhysicianResponseTypeApplicantsEvaluatedByPhysicianArgs = {
  email?: InputMaybe<Scalars["String"]>;
};

export type PhysicianTagsType = {
  __typename?: "PhysicianTagsType";
  actionTags?: Maybe<Array<Maybe<Scalars["String"]>>>;
};

export type PhysiciansByArchivedMinicexFilterCaseManagerInput = {
  caseId: Scalars["String"];
  responseType?: InputMaybe<Scalars["String"]>;
};

/**  Base Query Type definition - , all mutations will be defined in separate files extending this type  */
export type Query = {
  __typename?: "Query";
  /** IGNORE: Dummy field necessary for the Query type to be valid */
  _empty?: Maybe<Scalars["String"]>;
  applicantPortal?: Maybe<ApplicantPortalResponseType>;
  archivedCase?: Maybe<ArchivedCaseType>;
  archivedCases?: Maybe<Array<Maybe<ArchivedCaseSummaryType>>>;
  authorityPortal?: Maybe<AuthorityPortalResponseType>;
  casePortal?: Maybe<CasePortalResponseType>;
  country?: Maybe<Array<Maybe<CountryResponseType>>>;
  downloadDocument?: Maybe<SasToken>;
  getDashboards?: Maybe<Array<Maybe<Dashboard>>>;
  oscexam?: Maybe<Array<Maybe<OscexamResponseType>>>;
  physicianPortal?: Maybe<PhysicianPortalResponseType>;
  physicianRegAuthority?: Maybe<
    Array<Maybe<PhysicianRegAuthorityResponseType>>
  >;
  physiciansByArchivedMinicex?: Maybe<Array<Maybe<PhysicianResponseType>>>;
  regulatoryAuthority?: Maybe<Array<Maybe<RegulatoryAuthorityResponseType>>>;
  school?: Maybe<Array<Maybe<SchoolResponseType>>>;
  serverDate: Scalars["DateString"];
};

/**  Base Query Type definition - , all mutations will be defined in separate files extending this type  */
export type QueryArchivedCaseArgs = {
  caseId: Scalars["String"];
};

/**  Base Query Type definition - , all mutations will be defined in separate files extending this type  */
export type QueryArchivedCasesArgs = {
  filter: ArchivedCaseFilterInput;
};

/**  Base Query Type definition - , all mutations will be defined in separate files extending this type  */
export type QueryDownloadDocumentArgs = {
  fileName: Scalars["String"];
};

/**  Base Query Type definition - , all mutations will be defined in separate files extending this type  */
export type QueryPhysicianRegAuthorityArgs = {
  input?: InputMaybe<PhysicianRegAuthorityInput>;
};

/**  Base Query Type definition - , all mutations will be defined in separate files extending this type  */
export type QueryPhysiciansByArchivedMinicexArgs = {
  filter: PhysiciansByArchivedMinicexFilterCaseManagerInput;
};

/**  Base Query Type definition - , all mutations will be defined in separate files extending this type  */
export type QuerySchoolArgs = {
  pathway?: InputMaybe<Scalars["String"]>;
};

export type Refund = {
  __typename?: "Refund";
  amt?: Maybe<Scalars["Int"]>;
  pnRef?: Maybe<Scalars["String"]>;
  transTime?: Maybe<Scalars["DateString"]>;
};

export type RefundRequestArgument = {
  amount?: InputMaybe<Scalars["String"]>;
  caseWorker?: InputMaybe<Scalars["String"]>;
  usmleID?: InputMaybe<Scalars["String"]>;
};

export type RejectMiniCexInput = {
  rejectionReason: Scalars["String"];
  rejectionReasonComments?: InputMaybe<Scalars["String"]>;
};

export type RequestPayload = {
  __typename?: "RequestPayload";
  pnRef?: Maybe<Scalars["String"]>;
  responseMessage?: Maybe<Scalars["String"]>;
  result?: Maybe<Scalars["String"]>;
};

export type ResponseStatusType = {
  __typename?: "ResponseStatusType";
  code?: Maybe<Scalars["String"]>;
  msg?: Maybe<Scalars["String"]>;
};

export type RestrictionType = {
  __typename?: "RestrictionType";
  comments?: Maybe<Scalars["String"]>;
  level?: Maybe<Scalars["String"]>;
  reason?: Maybe<Scalars["String"]>;
  releaseDate?: Maybe<Scalars["String"]>;
  startDate?: Maybe<Scalars["String"]>;
  user?: Maybe<Scalars["String"]>;
};

export type SasToken = {
  __typename?: "SASToken";
  token?: Maybe<Token>;
};

export type SchoolEligibilityType = {
  __typename?: "SchoolEligibilityType";
  isSchoolEMSWPMember?: Maybe<Scalars["Boolean"]>;
  schoolPathway?: Maybe<Scalars["String"]>;
};

export type SchoolInput = {
  _id?: InputMaybe<Scalars["Int"]>;
  countryName?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
};

export type SchoolResponseType = {
  __typename?: "SchoolResponseType";
  _id?: Maybe<Scalars["Int"]>;
  countryName?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
};

export type SearchOetInput = {
  candidateId?: InputMaybe<Scalars["String"]>;
  examineeFirstName?: InputMaybe<Scalars["String"]>;
  examineeLastName?: InputMaybe<Scalars["String"]>;
  matched?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  output?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  usmleId?: InputMaybe<Scalars["String"]>;
};

export type Token = {
  __typename?: "Token";
  token: Scalars["String"];
  uri: Scalars["String"];
};

export type TokenPayload = {
  __typename?: "TokenPayload";
  alreadyPaid?: Maybe<Scalars["String"]>;
  errorDetails?: Maybe<Scalars["String"]>;
  secureToken?: Maybe<Scalars["String"]>;
  secureTokenId?: Maybe<Scalars["String"]>;
};

export type TokenRequestArgument = {
  acknowledgeTimestamp?: InputMaybe<Scalars["String"]>;
  amount?: InputMaybe<Scalars["Float"]>;
  billToCity?: InputMaybe<Scalars["String"]>;
  billToFirstName?: InputMaybe<Scalars["String"]>;
  billToLastName?: InputMaybe<Scalars["String"]>;
  billToMiddleName?: InputMaybe<Scalars["String"]>;
  billToState?: InputMaybe<Scalars["String"]>;
  billToStreet?: InputMaybe<Scalars["String"]>;
  billToStreet2?: InputMaybe<Scalars["String"]>;
  billToZip?: InputMaybe<Scalars["String"]>;
  usmleID?: InputMaybe<Scalars["String"]>;
};

export type UiComponentConfigType = {
  __typename?: "UIComponentConfigType";
  componentName?: Maybe<Scalars["String"]>;
  version?: Maybe<Scalars["Int"]>;
};

export type UiConfigRecordType = {
  __typename?: "UIConfigRecordType";
  pages?: Maybe<Array<Maybe<UiPageConfigType>>>;
  portal?: Maybe<Scalars["String"]>;
};

export type UiPageConfigType = {
  __typename?: "UIPageConfigType";
  components?: Maybe<Array<Maybe<UiComponentConfigType>>>;
  pageName?: Maybe<Scalars["String"]>;
};

export type UiPortalConfigType = {
  __typename?: "UIPortalConfigType";
  config?: Maybe<UiConfigRecordType>;
  uiConfigRef?: Maybe<Scalars["CosmosObjectIdString"]>;
};

export type UserType = {
  __typename?: "UserType";
  id?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  role?: Maybe<Scalars["String"]>;
};

export type ValidationResult = {
  __typename?: "ValidationResult";
  errorMessage?: Maybe<Scalars["String"]>;
  isValidated?: Maybe<Scalars["Boolean"]>;
};

export type ViewFilters = {
  __typename?: "ViewFilters";
  applicationType?: Maybe<Array<Maybe<Scalars["String"]>>>;
  caseState?: Maybe<Array<Maybe<Scalars["String"]>>>;
  englishEligibilityStatus?: Maybe<Array<Maybe<Scalars["String"]>>>;
  generalEligibilityStatus?: Maybe<Array<Maybe<Scalars["String"]>>>;
  pathway?: Maybe<Array<Maybe<Scalars["String"]>>>;
  pathwayEligibilityStatus?: Maybe<Array<Maybe<Scalars["String"]>>>;
  regulatoryAuthority?: Maybe<Array<Maybe<Scalars["Int"]>>>;
  school?: Maybe<Array<Maybe<Scalars["Int"]>>>;
};

export type ViewFiltersInput = {
  applicationType?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  caseState?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  englishEligibilityStatus?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  generalEligibilityStatus?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  pathway?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  pathwayEligibilityStatus?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  regulatoryAuthority?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  school?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
};

export type Views = {
  __typename?: "Views";
  _id?: Maybe<Scalars["CosmosObjectIdString"]>;
  displayColumns?: Maybe<Array<Maybe<Scalars["String"]>>>;
  viewFilters?: Maybe<ViewFilters>;
  viewName?: Maybe<Scalars["String"]>;
};

export type ViewsInput = {
  _id?: InputMaybe<Scalars["CosmosObjectIdString"]>;
  displayColumns?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  viewFilters?: InputMaybe<ViewFiltersInput>;
  viewName?: InputMaybe<Scalars["String"]>;
};

export type CountryResponseType = {
  __typename?: "countryResponseType";
  _id?: Maybe<Scalars["String"]>;
  countryName?: Maybe<Scalars["String"]>;
  isoCountryCode?: Maybe<Scalars["String"]>;
};

export type GlDistributionReportFilterInput = {
  transactionDateFrom?: InputMaybe<Scalars["String"]>;
  transactionDateTo?: InputMaybe<Scalars["String"]>;
};

export type GlDistributionReportResponseType = {
  __typename?: "glDistributionReportResponseType";
  amount?: Maybe<Scalars["Int"]>;
  applicationType?: Maybe<Scalars["String"]>;
  feeType?: Maybe<Scalars["String"]>;
  glCreditAccountId?: Maybe<Scalars["String"]>;
  glDebitAccountId?: Maybe<Scalars["String"]>;
  paymentNetworkReferenceId?: Maybe<Scalars["String"]>;
  transactionTimestamp?: Maybe<Scalars["DateString"]>;
  transactionType?: Maybe<Scalars["String"]>;
  trsAccountId?: Maybe<Scalars["String"]>;
  usmleId?: Maybe<Scalars["String"]>;
};

export type OscexamInput = {
  _id?: InputMaybe<Scalars["String"]>;
  examCode?: InputMaybe<Scalars["String"]>;
  examFullName?: InputMaybe<Scalars["String"]>;
  examShortTitle?: InputMaybe<Scalars["String"]>;
};

export type OscexamResponseType = {
  __typename?: "oscexamResponseType";
  _id?: Maybe<Scalars["String"]>;
  examCode?: Maybe<Scalars["String"]>;
  examFullName?: Maybe<Scalars["String"]>;
  examShortTitle?: Maybe<Scalars["String"]>;
};

export type PhysicianRegAuthorityInput = {
  _id?: InputMaybe<Scalars["Int"]>;
  countryCode?: InputMaybe<Scalars["String"]>;
};

export type PhysicianRegAuthorityResponseType = {
  __typename?: "physicianRegAuthorityResponseType";
  _id?: Maybe<Scalars["Int"]>;
  countryCode?: Maybe<Scalars["String"]>;
  countryName?: Maybe<Scalars["String"]>;
  orgName?: Maybe<Scalars["String"]>;
};

export type RegulatoryAuthorityInput = {
  _id?: InputMaybe<Scalars["Int"]>;
  countryName?: InputMaybe<Scalars["String"]>;
  orgName?: InputMaybe<Scalars["String"]>;
};

export type RegulatoryAuthorityResponseType = {
  __typename?: "regulatoryAuthorityResponseType";
  _id?: Maybe<Scalars["Int"]>;
  countryName?: Maybe<Scalars["String"]>;
  orgName?: Maybe<Scalars["String"]>;
};

export type ApplicantAppApplicantPortalQueryVariables = Exact<{
  [key: string]: never;
}>;

export type ApplicantAppApplicantPortalQuery = {
  __typename?: "Query";
  applicantPortal?:
    | {
        __typename?: "ApplicantPortalResponseType";
        caseDetails?:
          | {
              __typename?: "A_CaseDetails";
              isApplicationCreationAllowed?: boolean | null | undefined;
              submittedPathway?: string | null | undefined;
              pathwaySeason?: number | null | undefined;
              applicationType?: string | null | undefined;
              state?: string | null | undefined;
              currentPathway?: string | null | undefined;
              submittedAt?: any | null | undefined;
              output?: string | null | undefined;
              outputReason?:
                | Array<string | null | undefined>
                | null
                | undefined;
              isExceptionCase?: boolean | null | undefined;
            }
          | null
          | undefined;
        graduation?:
          | {
              __typename?: "GraduationType";
              isYearEligible?: boolean | null | undefined;
              school?: string | null | undefined;
              schoolEligibility?:
                | {
                    __typename?: "SchoolEligibilityType";
                    schoolPathway?: string | null | undefined;
                    isSchoolEMSWPMember?: boolean | null | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
        pathway1?:
          | {
              __typename?: "Pathway1";
              userResponse?:
                | {
                    __typename?: "Pathway1UserResponse";
                    isCurrentlyLicensed?: boolean | null | undefined;
                    wasLicensedInRecentPast?: boolean | null | undefined;
                  }
                | null
                | undefined;
              applicantProvidedData?:
                | Array<
                    | {
                        __typename?: "License";
                        _id?: string | null | undefined;
                        data?:
                          | {
                              __typename?: "LicenseData";
                              licenseIssueDate?: string | null | undefined;
                              licenseExpiryDate?: string | null | undefined;
                              hasDisciplinaryAction?:
                                | boolean
                                | null
                                | undefined;
                              isCurrentlyLicensed?: boolean | null | undefined;
                              licensureDocumentStatus?:
                                | string
                                | null
                                | undefined;
                              regulatoryAuthorityRef?:
                                | {
                                    __typename?: "regulatoryAuthorityResponseType";
                                    _id?: number | null | undefined;
                                    orgName?: string | null | undefined;
                                    countryName?: string | null | undefined;
                                  }
                                | null
                                | undefined;
                              documents?:
                                | Array<
                                    | {
                                        __typename?: "Document";
                                        docType?: string | null | undefined;
                                        title?: string | null | undefined;
                                        docId?: string | null | undefined;
                                      }
                                    | null
                                    | undefined
                                  >
                                | null
                                | undefined;
                            }
                          | null
                          | undefined;
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined;
              staffProvidedData?:
                | Array<
                    | {
                        __typename?: "License";
                        _id?: string | null | undefined;
                        data?:
                          | {
                              __typename?: "LicenseData";
                              licenseIssueDate?: string | null | undefined;
                              licenseExpiryDate?: string | null | undefined;
                              hasDisciplinaryAction?:
                                | boolean
                                | null
                                | undefined;
                              isCurrentlyLicensed?: boolean | null | undefined;
                              licensureDocumentStatus?:
                                | string
                                | null
                                | undefined;
                              regulatoryAuthorityRef?:
                                | {
                                    __typename?: "regulatoryAuthorityResponseType";
                                    _id?: number | null | undefined;
                                    orgName?: string | null | undefined;
                                    countryName?: string | null | undefined;
                                  }
                                | null
                                | undefined;
                              documents?:
                                | Array<
                                    | {
                                        __typename?: "Document";
                                        docType?: string | null | undefined;
                                        title?: string | null | undefined;
                                        docId?: string | null | undefined;
                                      }
                                    | null
                                    | undefined
                                  >
                                | null
                                | undefined;
                            }
                          | null
                          | undefined;
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined;
            }
          | null
          | undefined;
        pathway2?:
          | {
              __typename?: "Pathway2";
              userResponse?:
                | {
                    __typename?: "Pathway2UserResponse";
                    hasPassedCSExamInRecentPast?: boolean | null | undefined;
                    hasPassedFromQualifiedSchoolInRecentPast?:
                      | boolean
                      | null
                      | undefined;
                  }
                | null
                | undefined;
              applicantProvidedData?:
                | Array<
                    | {
                        __typename?: "CSExam";
                        _id?: string | null | undefined;
                        data?:
                          | {
                              __typename?: "CSExamData";
                              examDate?: string | null | undefined;
                              schoolRef?:
                                | {
                                    __typename?: "SchoolResponseType";
                                    _id?: number | null | undefined;
                                    name?: string | null | undefined;
                                    countryName?: string | null | undefined;
                                  }
                                | null
                                | undefined;
                              oscExamRef?:
                                | {
                                    __typename?: "oscexamResponseType";
                                    _id?: string | null | undefined;
                                    examCode?: string | null | undefined;
                                    examFullName?: string | null | undefined;
                                    examShortTitle?: string | null | undefined;
                                  }
                                | null
                                | undefined;
                              documents?:
                                | Array<
                                    | {
                                        __typename?: "Document";
                                        docId?: string | null | undefined;
                                        docType?: string | null | undefined;
                                        title?: string | null | undefined;
                                      }
                                    | null
                                    | undefined
                                  >
                                | null
                                | undefined;
                            }
                          | null
                          | undefined;
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined;
            }
          | null
          | undefined;
        pathway345?:
          | {
              __typename?: "Pathway345";
              userResponse?:
                | {
                    __typename?: "Pathway345UserResponse";
                    isGradSchoolEligible?: boolean | null | undefined;
                    isGradDateInAcceptableRange?: boolean | null | undefined;
                  }
                | null
                | undefined;
              applicantProvidedData?:
                | Array<
                    | {
                        __typename?: "Education";
                        _id?: string | null | undefined;
                        data?:
                          | {
                              __typename?: "EducationData";
                              gradDate?: string | null | undefined;
                              schoolRef?:
                                | {
                                    __typename?: "SchoolResponseType";
                                    name?: string | null | undefined;
                                    countryName?: string | null | undefined;
                                    _id?: number | null | undefined;
                                  }
                                | null
                                | undefined;
                              documents?:
                                | Array<
                                    | {
                                        __typename?: "Document";
                                        docId?: string | null | undefined;
                                        docType?: string | null | undefined;
                                        title?: string | null | undefined;
                                      }
                                    | null
                                    | undefined
                                  >
                                | null
                                | undefined;
                            }
                          | null
                          | undefined;
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined;
            }
          | null
          | undefined;
        pathway6?:
          | {
              __typename?: "Pathway6";
              userResponse?:
                | {
                    __typename?: "Pathway6UserResponse";
                    hasAgreedToProvidePhysicianInfo?:
                      | boolean
                      | null
                      | undefined;
                  }
                | null
                | undefined;
              applicantProvidedData?:
                | Array<
                    | {
                        __typename?: "Pathway6MiniCEXType";
                        _id?: string | null | undefined;
                        data?:
                          | {
                              __typename?: "MiniCEXData";
                              acknowledgedTimestamp?: any | null | undefined;
                              documents?:
                                | Array<
                                    | {
                                        __typename?: "Document";
                                        docId?: string | null | undefined;
                                        docType?: string | null | undefined;
                                        title?: string | null | undefined;
                                      }
                                    | null
                                    | undefined
                                  >
                                | null
                                | undefined;
                            }
                          | null
                          | undefined;
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined;
            }
          | null
          | undefined;
        pathwayX?:
          | {
              __typename?: "ExceptionRequest";
              data?:
                | {
                    __typename?: "ExceptionRequestData";
                    requestText?: string | null | undefined;
                    documents?:
                      | Array<
                          | {
                              __typename?: "Document";
                              docId?: string | null | undefined;
                              docType?: string | null | undefined;
                              title?: string | null | undefined;
                            }
                          | null
                          | undefined
                        >
                      | null
                      | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
        pastAttempts?:
          | Array<
              | {
                  __typename?: "PastAttemptsType";
                  rejectionReason?:
                    | Array<string | null | undefined>
                    | null
                    | undefined;
                  pathway?: string | null | undefined;
                  pathwaySeason?: string | null | undefined;
                  applicationType?: string | null | undefined;
                  submittedAt?: any | null | undefined;
                  completedAt?: any | null | undefined;
                  output?: string | null | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
        uiConfig?:
          | {
              __typename?: "UIPortalConfigType";
              config?:
                | {
                    __typename?: "UIConfigRecordType";
                    pages?:
                      | Array<
                          | {
                              __typename?: "UIPageConfigType";
                              pageName?: string | null | undefined;
                              components?:
                                | Array<
                                    | {
                                        __typename?: "UIComponentConfigType";
                                        componentName?:
                                          | string
                                          | null
                                          | undefined;
                                        version?: number | null | undefined;
                                      }
                                    | null
                                    | undefined
                                  >
                                | null
                                | undefined;
                            }
                          | null
                          | undefined
                        >
                      | null
                      | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
        progress?:
          | {
              __typename?: "A_Progress";
              pathwayEligibility?:
                | Array<
                    | {
                        __typename?: "A_ProgressStatus";
                        checklistRecord?: string | null | undefined;
                        checklistState?: string | null | undefined;
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined;
              englishEligibility?:
                | Array<
                    | {
                        __typename?: "A_ProgressStatus";
                        checklistRecord?: string | null | undefined;
                        checklistState?: string | null | undefined;
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined;
              generalEligibility?:
                | Array<
                    | {
                        __typename?: "A_ProgressStatus";
                        checklistRecord?: string | null | undefined;
                        checklistState?: string | null | undefined;
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined;
            }
          | null
          | undefined;
        finance?:
          | {
              __typename?: "Finance";
              payment?:
                | {
                    __typename?: "Payment";
                    amt?: number | null | undefined;
                    transTime?: any | null | undefined;
                  }
                | null
                | undefined;
              refund?:
                | {
                    __typename?: "Refund";
                    amt?: number | null | undefined;
                    transTime?: any | null | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
        eligibilityPreCheck?:
          | {
              __typename?: "EligibilityPreCheckResponseType";
              lastScreenName?: string | null | undefined;
              isStep2CSFailed?: boolean | null | undefined;
              eligibility?:
                | {
                    __typename?: "Eligibility";
                    isEligible?: boolean | null | undefined;
                    eligibilityCheckTimestamp?: any | null | undefined;
                    responseCode?: string | null | undefined;
                    responseMsg?: string | null | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type ApplicantAppProgressFieldsFragment = {
  __typename?: "ApplicantPortalResponseType";
  progress?:
    | {
        __typename?: "A_Progress";
        pathwayEligibility?:
          | Array<
              | {
                  __typename?: "A_ProgressStatus";
                  checklistRecord?: string | null | undefined;
                  checklistState?: string | null | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
        englishEligibility?:
          | Array<
              | {
                  __typename?: "A_ProgressStatus";
                  checklistRecord?: string | null | undefined;
                  checklistState?: string | null | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
        generalEligibility?:
          | Array<
              | {
                  __typename?: "A_ProgressStatus";
                  checklistRecord?: string | null | undefined;
                  checklistState?: string | null | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type ApplicantAppCaseDetailsFieldsFragment = {
  __typename?: "ApplicantPortalResponseType";
  caseDetails?:
    | {
        __typename?: "A_CaseDetails";
        state?: string | null | undefined;
        currentPathway?: string | null | undefined;
        submittedAt?: any | null | undefined;
        output?: string | null | undefined;
        outputReason?: Array<string | null | undefined> | null | undefined;
        isExceptionCase?: boolean | null | undefined;
      }
    | null
    | undefined;
};

export type ApplicantAppFinanceFieldsFragment = {
  __typename?: "ApplicantPortalResponseType";
  finance?:
    | {
        __typename?: "Finance";
        payment?:
          | {
              __typename?: "Payment";
              amt?: number | null | undefined;
              transTime?: any | null | undefined;
            }
          | null
          | undefined;
        refund?:
          | {
              __typename?: "Refund";
              amt?: number | null | undefined;
              transTime?: any | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type ApplicantAppPathway2FieldsFragment = {
  __typename?: "ApplicantPortalResponseType";
  pathway2?:
    | {
        __typename?: "Pathway2";
        userResponse?:
          | {
              __typename?: "Pathway2UserResponse";
              hasPassedCSExamInRecentPast?: boolean | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type ApplicantAppGraduationFieldsFragment = {
  __typename?: "ApplicantPortalResponseType";
  graduation?:
    | {
        __typename?: "GraduationType";
        schoolEligibility?:
          | {
              __typename?: "SchoolEligibilityType";
              isSchoolEMSWPMember?: boolean | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type ApplicantBillingInformationAuthTokenMutationVariables = Exact<{
  amount?: InputMaybe<Scalars["Float"]>;
  billToFirstName?: InputMaybe<Scalars["String"]>;
  billToMiddleName?: InputMaybe<Scalars["String"]>;
  billToLastName?: InputMaybe<Scalars["String"]>;
  billToStreet?: InputMaybe<Scalars["String"]>;
  billToStreet2?: InputMaybe<Scalars["String"]>;
  billToCity?: InputMaybe<Scalars["String"]>;
  billToState?: InputMaybe<Scalars["String"]>;
  billToZip?: InputMaybe<Scalars["String"]>;
  usmleID?: InputMaybe<Scalars["String"]>;
  acknowledgeTimestamp?: InputMaybe<Scalars["String"]>;
}>;

export type ApplicantBillingInformationAuthTokenMutation = {
  __typename?: "Mutation";
  requestSecureToken?:
    | {
        __typename?: "TokenPayload";
        secureToken?: string | null | undefined;
        secureTokenId?: string | null | undefined;
        alreadyPaid?: string | null | undefined;
      }
    | null
    | undefined;
};

export type ApplicantBillingInformationCaseFeeQueryVariables = Exact<{
  [key: string]: never;
}>;

export type ApplicantBillingInformationCaseFeeQuery = {
  __typename?: "Query";
  applicantPortal?:
    | {
        __typename?: "ApplicantPortalResponseType";
        finance?:
          | { __typename?: "Finance"; caseFee?: number | null | undefined }
          | null
          | undefined;
        applicant?:
          | { __typename?: "A_Applicant"; usmleId?: string | null | undefined }
          | null
          | undefined;
        caseDetails?:
          | {
              __typename?: "A_CaseDetails";
              isRequiredDataProvided?: boolean | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type ApplicantBillingInformationApplicationValidationQueryVariables =
  Exact<{ [key: string]: never }>;

export type ApplicantBillingInformationApplicationValidationQuery = {
  __typename?: "Query";
  applicantPortal?:
    | {
        __typename?: "ApplicantPortalResponseType";
        caseDetails?:
          | {
              __typename?: "A_CaseDetails";
              isRequiredDataProvided?: boolean | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type ApplicantBillingInformationCountriesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type ApplicantBillingInformationCountriesQuery = {
  __typename?: "Query";
  country?:
    | Array<
        | {
            __typename?: "countryResponseType";
            _id?: string | null | undefined;
            countryName?: string | null | undefined;
          }
        | null
        | undefined
      >
    | null
    | undefined;
};

export type ApplicantBillingInformationCheckPaymentExistsMutationVariables =
  Exact<{ [key: string]: never }>;

export type ApplicantBillingInformationCheckPaymentExistsMutation = {
  __typename?: "Mutation";
  ApplicantPortal_checkPaymentExists?: boolean | null | undefined;
};

export type ApplicantDocumentUploaderUploadDocumentMutationVariables = Exact<{
  fileName: Scalars["String"];
}>;

export type ApplicantDocumentUploaderUploadDocumentMutation = {
  __typename?: "Mutation";
  uploadDocument?:
    | {
        __typename?: "SASToken";
        token?:
          | { __typename?: "Token"; token: string; uri: string }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type ApplicantLoginPortalLoginMutationVariables = Exact<{
  userid: Scalars["String"];
  password: Scalars["String"];
}>;

export type ApplicantLoginPortalLoginMutation = {
  __typename?: "Mutation";
  ApplicantPortal_login?:
    | {
        __typename?: "ApplicantPortalLoginResponseType";
        token?: string | null | undefined;
        case?:
          | {
              __typename?: "CaseLoginPayloadType";
              isApplicationCreationAllowed?: boolean | null | undefined;
              applicationType?: string | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type ApplicantQualification2BPathway2FieldsFragment = {
  __typename?: "ApplicantPortalResponseType";
  pathway2?:
    | {
        __typename?: "Pathway2";
        userResponse?:
          | {
              __typename?: "Pathway2UserResponse";
              hasPassedCSExamInRecentPast?: boolean | null | undefined;
              hasPassedFromQualifiedSchoolInRecentPast?:
                | boolean
                | null
                | undefined;
            }
          | null
          | undefined;
        applicantProvidedData?:
          | Array<
              | {
                  __typename?: "CSExam";
                  _id?: string | null | undefined;
                  data?:
                    | {
                        __typename?: "CSExamData";
                        examDate?: string | null | undefined;
                        schoolRef?:
                          | {
                              __typename?: "SchoolResponseType";
                              _id?: number | null | undefined;
                              name?: string | null | undefined;
                              countryName?: string | null | undefined;
                            }
                          | null
                          | undefined;
                        oscExamRef?:
                          | {
                              __typename?: "oscexamResponseType";
                              _id?: string | null | undefined;
                              examCode?: string | null | undefined;
                              examFullName?: string | null | undefined;
                              examShortTitle?: string | null | undefined;
                            }
                          | null
                          | undefined;
                        documents?:
                          | Array<
                              | {
                                  __typename?: "Document";
                                  docId?: string | null | undefined;
                                  docType?: string | null | undefined;
                                  title?: string | null | undefined;
                                }
                              | null
                              | undefined
                            >
                          | null
                          | undefined;
                      }
                    | null
                    | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type ApplicantQualification2BGraduationQueryVariables = Exact<{
  [key: string]: never;
}>;

export type ApplicantQualification2BGraduationQuery = {
  __typename?: "Query";
  applicantPortal?:
    | {
        __typename?: "ApplicantPortalResponseType";
        graduation?:
          | {
              __typename?: "GraduationType";
              isYearEligible?: boolean | null | undefined;
              school?: string | null | undefined;
              schoolEligibility?:
                | {
                    __typename?: "SchoolEligibilityType";
                    schoolPathway?: string | null | undefined;
                    isSchoolEMSWPMember?: boolean | null | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type ApplicantQualification2BApplicantPortal_Pathway2bMutationVariables =
  Exact<{
    supportingData?: InputMaybe<
      Array<InputMaybe<CsExamInput>> | InputMaybe<CsExamInput>
    >;
  }>;

export type ApplicantQualification2BApplicantPortal_Pathway2bMutation = {
  __typename?: "Mutation";
  ApplicantPortal_pathway2b?:
    | {
        __typename?: "ApplicantPortalMutationResponseType";
        status?: string | null | undefined;
        data?:
          | {
              __typename?: "ApplicantPortalResponseType";
              pathway2?:
                | {
                    __typename?: "Pathway2";
                    applicantProvidedData?:
                      | Array<
                          | {
                              __typename?: "CSExam";
                              data?:
                                | {
                                    __typename?: "CSExamData";
                                    examDate?: string | null | undefined;
                                    schoolRef?:
                                      | {
                                          __typename?: "SchoolResponseType";
                                          _id?: number | null | undefined;
                                          name?: string | null | undefined;
                                          countryName?:
                                            | string
                                            | null
                                            | undefined;
                                        }
                                      | null
                                      | undefined;
                                    oscExamRef?:
                                      | {
                                          __typename?: "oscexamResponseType";
                                          examCode?: string | null | undefined;
                                          examShortTitle?:
                                            | string
                                            | null
                                            | undefined;
                                          examFullName?:
                                            | string
                                            | null
                                            | undefined;
                                          _id?: string | null | undefined;
                                        }
                                      | null
                                      | undefined;
                                    documents?:
                                      | Array<
                                          | {
                                              __typename?: "Document";
                                              docId?: string | null | undefined;
                                              docType?:
                                                | string
                                                | null
                                                | undefined;
                                              title?: string | null | undefined;
                                            }
                                          | null
                                          | undefined
                                        >
                                      | null
                                      | undefined;
                                  }
                                | null
                                | undefined;
                            }
                          | null
                          | undefined
                        >
                      | null
                      | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type ApplicantQualification6EncounterManagementPathway6FieldsFragment = {
  __typename?: "ApplicantPortalResponseType";
  pathway6?:
    | {
        __typename?: "Pathway6";
        userResponse?:
          | {
              __typename?: "Pathway6UserResponse";
              hasAgreedToProvidePhysicianInfo?: boolean | null | undefined;
            }
          | null
          | undefined;
        applicantProvidedData?:
          | Array<
              | {
                  __typename?: "Pathway6MiniCEXType";
                  _id?: string | null | undefined;
                  data?:
                    | {
                        __typename?: "MiniCEXData";
                        acknowledgedTimestamp?: any | null | undefined;
                        documents?:
                          | Array<
                              | {
                                  __typename?: "Document";
                                  docId?: string | null | undefined;
                                  docType?: string | null | undefined;
                                  title?: string | null | undefined;
                                }
                              | null
                              | undefined
                            >
                          | null
                          | undefined;
                      }
                    | null
                    | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type ApplicantQualification6Pathway6FieldsFragment = {
  __typename?: "ApplicantPortalResponseType";
  pathway6?:
    | {
        __typename?: "Pathway6";
        userResponse?:
          | {
              __typename?: "Pathway6UserResponse";
              hasAgreedToProvidePhysicianInfo?: boolean | null | undefined;
            }
          | null
          | undefined;
        applicantProvidedData?:
          | Array<
              | {
                  __typename?: "Pathway6MiniCEXType";
                  _id?: string | null | undefined;
                  data?:
                    | {
                        __typename?: "MiniCEXData";
                        acknowledgedTimestamp?: any | null | undefined;
                        documents?:
                          | Array<
                              | {
                                  __typename?: "Document";
                                  docId?: string | null | undefined;
                                  docType?: string | null | undefined;
                                  title?: string | null | undefined;
                                }
                              | null
                              | undefined
                            >
                          | null
                          | undefined;
                      }
                    | null
                    | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type ApplicantQualification6ApplicantPortal_Pathway6MutationVariables =
  Exact<{ [key: string]: never }>;

export type ApplicantQualification6ApplicantPortal_Pathway6Mutation = {
  __typename?: "Mutation";
  ApplicantPortal_pathway6?:
    | {
        __typename?: "ApplicantPortalMutationResponseType";
        data?:
          | {
              __typename?: "ApplicantPortalResponseType";
              pathway6?:
                | {
                    __typename?: "Pathway6";
                    applicantProvidedData?:
                      | Array<
                          | {
                              __typename?: "Pathway6MiniCEXType";
                              _id?: string | null | undefined;
                            }
                          | null
                          | undefined
                        >
                      | null
                      | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type ApplicantQualificationAPathway1FieldsFragment = {
  __typename?: "ApplicantPortalResponseType";
  pathway1?:
    | {
        __typename?: "Pathway1";
        userResponse?:
          | {
              __typename?: "Pathway1UserResponse";
              isCurrentlyLicensed?: boolean | null | undefined;
              wasLicensedInRecentPast?: boolean | null | undefined;
            }
          | null
          | undefined;
        applicantProvidedData?:
          | Array<
              | {
                  __typename?: "License";
                  _id?: string | null | undefined;
                  data?:
                    | {
                        __typename?: "LicenseData";
                        licenseIssueDate?: string | null | undefined;
                        licenseExpiryDate?: string | null | undefined;
                        hasDisciplinaryAction?: boolean | null | undefined;
                        isCurrentlyLicensed?: boolean | null | undefined;
                        licensureDocumentStatus?: string | null | undefined;
                        regulatoryAuthorityRef?:
                          | {
                              __typename?: "regulatoryAuthorityResponseType";
                              _id?: number | null | undefined;
                              orgName?: string | null | undefined;
                              countryName?: string | null | undefined;
                            }
                          | null
                          | undefined;
                        documents?:
                          | Array<
                              | {
                                  __typename?: "Document";
                                  docType?: string | null | undefined;
                                  title?: string | null | undefined;
                                  docId?: string | null | undefined;
                                }
                              | null
                              | undefined
                            >
                          | null
                          | undefined;
                      }
                    | null
                    | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
        staffProvidedData?:
          | Array<
              | {
                  __typename?: "License";
                  _id?: string | null | undefined;
                  data?:
                    | {
                        __typename?: "LicenseData";
                        licenseIssueDate?: string | null | undefined;
                        licenseExpiryDate?: string | null | undefined;
                        hasDisciplinaryAction?: boolean | null | undefined;
                        isCurrentlyLicensed?: boolean | null | undefined;
                        licensureDocumentStatus?: string | null | undefined;
                        regulatoryAuthorityRef?:
                          | {
                              __typename?: "regulatoryAuthorityResponseType";
                              _id?: number | null | undefined;
                              orgName?: string | null | undefined;
                              countryName?: string | null | undefined;
                            }
                          | null
                          | undefined;
                        documents?:
                          | Array<
                              | {
                                  __typename?: "Document";
                                  docType?: string | null | undefined;
                                  title?: string | null | undefined;
                                  docId?: string | null | undefined;
                                }
                              | null
                              | undefined
                            >
                          | null
                          | undefined;
                      }
                    | null
                    | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type ApplicantQualificationAApplicantPortal_Pathway1aMutationVariables =
  Exact<{
    isCurrentlyLicensed: Scalars["Boolean"];
    supportingData?: InputMaybe<
      Array<InputMaybe<LicenseInput>> | InputMaybe<LicenseInput>
    >;
  }>;

export type ApplicantQualificationAApplicantPortal_Pathway1aMutation = {
  __typename?: "Mutation";
  ApplicantPortal_pathway1a?:
    | {
        __typename?: "ApplicantPortalMutationResponseType";
        status?: string | null | undefined;
        data?:
          | {
              __typename?: "ApplicantPortalResponseType";
              _id?: string | null | undefined;
              pathway1?:
                | {
                    __typename?: "Pathway1";
                    userResponse?:
                      | {
                          __typename?: "Pathway1UserResponse";
                          isCurrentlyLicensed?: boolean | null | undefined;
                          wasLicensedInRecentPast?: boolean | null | undefined;
                        }
                      | null
                      | undefined;
                    applicantProvidedData?:
                      | Array<
                          | {
                              __typename?: "License";
                              _id?: string | null | undefined;
                              data?:
                                | {
                                    __typename?: "LicenseData";
                                    licenseIssueDate?:
                                      | string
                                      | null
                                      | undefined;
                                    licenseExpiryDate?:
                                      | string
                                      | null
                                      | undefined;
                                    hasDisciplinaryAction?:
                                      | boolean
                                      | null
                                      | undefined;
                                    isCurrentlyLicensed?:
                                      | boolean
                                      | null
                                      | undefined;
                                    licensureDocumentStatus?:
                                      | string
                                      | null
                                      | undefined;
                                    regulatoryAuthorityRef?:
                                      | {
                                          __typename?: "regulatoryAuthorityResponseType";
                                          _id?: number | null | undefined;
                                          orgName?: string | null | undefined;
                                          countryName?:
                                            | string
                                            | null
                                            | undefined;
                                        }
                                      | null
                                      | undefined;
                                    documents?:
                                      | Array<
                                          | {
                                              __typename?: "Document";
                                              docType?:
                                                | string
                                                | null
                                                | undefined;
                                              title?: string | null | undefined;
                                              docId?: string | null | undefined;
                                            }
                                          | null
                                          | undefined
                                        >
                                      | null
                                      | undefined;
                                  }
                                | null
                                | undefined;
                            }
                          | null
                          | undefined
                        >
                      | null
                      | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type ApplicantQualificationBPathway1FieldsFragment = {
  __typename?: "ApplicantPortalResponseType";
  pathway1?:
    | {
        __typename?: "Pathway1";
        userResponse?:
          | {
              __typename?: "Pathway1UserResponse";
              isCurrentlyLicensed?: boolean | null | undefined;
              wasLicensedInRecentPast?: boolean | null | undefined;
            }
          | null
          | undefined;
        applicantProvidedData?:
          | Array<
              | {
                  __typename?: "License";
                  _id?: string | null | undefined;
                  data?:
                    | {
                        __typename?: "LicenseData";
                        licenseIssueDate?: string | null | undefined;
                        licenseExpiryDate?: string | null | undefined;
                        hasDisciplinaryAction?: boolean | null | undefined;
                        isCurrentlyLicensed?: boolean | null | undefined;
                        licensureDocumentStatus?: string | null | undefined;
                        regulatoryAuthorityRef?:
                          | {
                              __typename?: "regulatoryAuthorityResponseType";
                              _id?: number | null | undefined;
                              orgName?: string | null | undefined;
                              countryName?: string | null | undefined;
                            }
                          | null
                          | undefined;
                        documents?:
                          | Array<
                              | {
                                  __typename?: "Document";
                                  docType?: string | null | undefined;
                                  title?: string | null | undefined;
                                  docId?: string | null | undefined;
                                }
                              | null
                              | undefined
                            >
                          | null
                          | undefined;
                      }
                    | null
                    | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
        staffProvidedData?:
          | Array<
              | {
                  __typename?: "License";
                  _id?: string | null | undefined;
                  data?:
                    | {
                        __typename?: "LicenseData";
                        licenseIssueDate?: string | null | undefined;
                        licenseExpiryDate?: string | null | undefined;
                        hasDisciplinaryAction?: boolean | null | undefined;
                        isCurrentlyLicensed?: boolean | null | undefined;
                        licensureDocumentStatus?: string | null | undefined;
                        regulatoryAuthorityRef?:
                          | {
                              __typename?: "regulatoryAuthorityResponseType";
                              _id?: number | null | undefined;
                              orgName?: string | null | undefined;
                              countryName?: string | null | undefined;
                            }
                          | null
                          | undefined;
                        documents?:
                          | Array<
                              | {
                                  __typename?: "Document";
                                  docType?: string | null | undefined;
                                  title?: string | null | undefined;
                                  docId?: string | null | undefined;
                                }
                              | null
                              | undefined
                            >
                          | null
                          | undefined;
                      }
                    | null
                    | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type ApplicantQualificationBApplicantPortal_Pathway1bMutationVariables =
  Exact<{
    wasLicensedInRecentPast: Scalars["Boolean"];
    supportingData?: InputMaybe<
      Array<InputMaybe<LicenseInput>> | InputMaybe<LicenseInput>
    >;
  }>;

export type ApplicantQualificationBApplicantPortal_Pathway1bMutation = {
  __typename?: "Mutation";
  ApplicantPortal_pathway1b?:
    | {
        __typename?: "ApplicantPortalMutationResponseType";
        status?: string | null | undefined;
        data?:
          | {
              __typename?: "ApplicantPortalResponseType";
              _id?: string | null | undefined;
              pathway1?:
                | {
                    __typename?: "Pathway1";
                    userResponse?:
                      | {
                          __typename?: "Pathway1UserResponse";
                          isCurrentlyLicensed?: boolean | null | undefined;
                          wasLicensedInRecentPast?: boolean | null | undefined;
                        }
                      | null
                      | undefined;
                    applicantProvidedData?:
                      | Array<
                          | {
                              __typename?: "License";
                              _id?: string | null | undefined;
                              data?:
                                | {
                                    __typename?: "LicenseData";
                                    licenseIssueDate?:
                                      | string
                                      | null
                                      | undefined;
                                    licenseExpiryDate?:
                                      | string
                                      | null
                                      | undefined;
                                    hasDisciplinaryAction?:
                                      | boolean
                                      | null
                                      | undefined;
                                    isCurrentlyLicensed?:
                                      | boolean
                                      | null
                                      | undefined;
                                    licensureDocumentStatus?:
                                      | string
                                      | null
                                      | undefined;
                                    regulatoryAuthorityRef?:
                                      | {
                                          __typename?: "regulatoryAuthorityResponseType";
                                          _id?: number | null | undefined;
                                          orgName?: string | null | undefined;
                                          countryName?:
                                            | string
                                            | null
                                            | undefined;
                                        }
                                      | null
                                      | undefined;
                                    documents?:
                                      | Array<
                                          | {
                                              __typename?: "Document";
                                              docType?:
                                                | string
                                                | null
                                                | undefined;
                                              title?: string | null | undefined;
                                              docId?: string | null | undefined;
                                            }
                                          | null
                                          | undefined
                                        >
                                      | null
                                      | undefined;
                                  }
                                | null
                                | undefined;
                            }
                          | null
                          | undefined
                        >
                      | null
                      | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type ApplicantQualificationC1RegulatoryAuthorityQueryVariables = Exact<{
  [key: string]: never;
}>;

export type ApplicantQualificationC1RegulatoryAuthorityQuery = {
  __typename?: "Query";
  regulatoryAuthority?:
    | Array<
        | {
            __typename?: "regulatoryAuthorityResponseType";
            _id?: number | null | undefined;
            orgName?: string | null | undefined;
            countryName?: string | null | undefined;
          }
        | null
        | undefined
      >
    | null
    | undefined;
};

export type ApplicantQualificationC2ApplicantPortal_Pathway1aMutationVariables =
  Exact<{
    isCurrentlyLicensed: Scalars["Boolean"];
    supportingData?: InputMaybe<
      Array<InputMaybe<LicenseInput>> | InputMaybe<LicenseInput>
    >;
  }>;

export type ApplicantQualificationC2ApplicantPortal_Pathway1aMutation = {
  __typename?: "Mutation";
  ApplicantPortal_pathway1a?:
    | {
        __typename?: "ApplicantPortalMutationResponseType";
        status?: string | null | undefined;
        data?:
          | {
              __typename?: "ApplicantPortalResponseType";
              _id?: string | null | undefined;
              pathway1?:
                | {
                    __typename?: "Pathway1";
                    userResponse?:
                      | {
                          __typename?: "Pathway1UserResponse";
                          isCurrentlyLicensed?: boolean | null | undefined;
                          wasLicensedInRecentPast?: boolean | null | undefined;
                        }
                      | null
                      | undefined;
                    applicantProvidedData?:
                      | Array<
                          | {
                              __typename?: "License";
                              _id?: string | null | undefined;
                              data?:
                                | {
                                    __typename?: "LicenseData";
                                    licenseIssueDate?:
                                      | string
                                      | null
                                      | undefined;
                                    licenseExpiryDate?:
                                      | string
                                      | null
                                      | undefined;
                                    hasDisciplinaryAction?:
                                      | boolean
                                      | null
                                      | undefined;
                                    isCurrentlyLicensed?:
                                      | boolean
                                      | null
                                      | undefined;
                                    licensureDocumentStatus?:
                                      | string
                                      | null
                                      | undefined;
                                    regulatoryAuthorityRef?:
                                      | {
                                          __typename?: "regulatoryAuthorityResponseType";
                                          _id?: number | null | undefined;
                                          orgName?: string | null | undefined;
                                          countryName?:
                                            | string
                                            | null
                                            | undefined;
                                        }
                                      | null
                                      | undefined;
                                    documents?:
                                      | Array<
                                          | {
                                              __typename?: "Document";
                                              docType?:
                                                | string
                                                | null
                                                | undefined;
                                              title?: string | null | undefined;
                                              docId?: string | null | undefined;
                                            }
                                          | null
                                          | undefined
                                        >
                                      | null
                                      | undefined;
                                  }
                                | null
                                | undefined;
                            }
                          | null
                          | undefined
                        >
                      | null
                      | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type ApplicantQualificationC2ApplicantPortal_Pathway1bMutationVariables =
  Exact<{
    wasLicensedInRecentPast: Scalars["Boolean"];
    supportingData?: InputMaybe<
      Array<InputMaybe<LicenseInput>> | InputMaybe<LicenseInput>
    >;
  }>;

export type ApplicantQualificationC2ApplicantPortal_Pathway1bMutation = {
  __typename?: "Mutation";
  ApplicantPortal_pathway1b?:
    | {
        __typename?: "ApplicantPortalMutationResponseType";
        status?: string | null | undefined;
        data?:
          | {
              __typename?: "ApplicantPortalResponseType";
              _id?: string | null | undefined;
              pathway1?:
                | {
                    __typename?: "Pathway1";
                    userResponse?:
                      | {
                          __typename?: "Pathway1UserResponse";
                          isCurrentlyLicensed?: boolean | null | undefined;
                          wasLicensedInRecentPast?: boolean | null | undefined;
                        }
                      | null
                      | undefined;
                    applicantProvidedData?:
                      | Array<
                          | {
                              __typename?: "License";
                              _id?: string | null | undefined;
                              data?:
                                | {
                                    __typename?: "LicenseData";
                                    licenseIssueDate?:
                                      | string
                                      | null
                                      | undefined;
                                    licenseExpiryDate?:
                                      | string
                                      | null
                                      | undefined;
                                    hasDisciplinaryAction?:
                                      | boolean
                                      | null
                                      | undefined;
                                    isCurrentlyLicensed?:
                                      | boolean
                                      | null
                                      | undefined;
                                    licensureDocumentStatus?:
                                      | string
                                      | null
                                      | undefined;
                                    regulatoryAuthorityRef?:
                                      | {
                                          __typename?: "regulatoryAuthorityResponseType";
                                          _id?: number | null | undefined;
                                          orgName?: string | null | undefined;
                                          countryName?:
                                            | string
                                            | null
                                            | undefined;
                                        }
                                      | null
                                      | undefined;
                                    documents?:
                                      | Array<
                                          | {
                                              __typename?: "Document";
                                              docType?:
                                                | string
                                                | null
                                                | undefined;
                                              title?: string | null | undefined;
                                              docId?: string | null | undefined;
                                            }
                                          | null
                                          | undefined
                                        >
                                      | null
                                      | undefined;
                                  }
                                | null
                                | undefined;
                            }
                          | null
                          | undefined
                        >
                      | null
                      | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type ApplicantQualificationFPathway345FFieldsFragment = {
  __typename?: "ApplicantPortalResponseType";
  pathway345?:
    | {
        __typename?: "Pathway345";
        userResponse?:
          | {
              __typename?: "Pathway345UserResponse";
              isGradSchoolEligible?: boolean | null | undefined;
              isGradDateInAcceptableRange?: boolean | null | undefined;
            }
          | null
          | undefined;
        applicantProvidedData?:
          | Array<
              | {
                  __typename?: "Education";
                  _id?: string | null | undefined;
                  data?:
                    | {
                        __typename?: "EducationData";
                        gradDate?: string | null | undefined;
                        schoolRef?:
                          | {
                              __typename?: "SchoolResponseType";
                              name?: string | null | undefined;
                              countryName?: string | null | undefined;
                              _id?: number | null | undefined;
                            }
                          | null
                          | undefined;
                        documents?:
                          | Array<
                              | {
                                  __typename?: "Document";
                                  docId?: string | null | undefined;
                                  docType?: string | null | undefined;
                                  title?: string | null | undefined;
                                }
                              | null
                              | undefined
                            >
                          | null
                          | undefined;
                      }
                    | null
                    | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type ApplicantQualificationFGraduationQueryVariables = Exact<{
  [key: string]: never;
}>;

export type ApplicantQualificationFGraduationQuery = {
  __typename?: "Query";
  applicantPortal?:
    | {
        __typename?: "ApplicantPortalResponseType";
        graduation?:
          | {
              __typename?: "GraduationType";
              isYearEligible?: boolean | null | undefined;
              school?: string | null | undefined;
              schoolEligibility?:
                | {
                    __typename?: "SchoolEligibilityType";
                    schoolPathway?: string | null | undefined;
                    isSchoolEMSWPMember?: boolean | null | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type ApplicantQualificationFApplicantPortal_Pathway345MutationVariables =
  Exact<{ [key: string]: never }>;

export type ApplicantQualificationFApplicantPortal_Pathway345Mutation = {
  __typename?: "Mutation";
  ApplicantPortal_pathway345?:
    | {
        __typename?: "ApplicantPortalMutationResponseType";
        data?:
          | {
              __typename?: "ApplicantPortalResponseType";
              pathway345?:
                | {
                    __typename?: "Pathway345";
                    applicantProvidedData?:
                      | Array<
                          | {
                              __typename?: "Education";
                              _id?: string | null | undefined;
                            }
                          | null
                          | undefined
                        >
                      | null
                      | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type ApplicantQualificationIPathwayXFieldsFragment = {
  __typename?: "ApplicantPortalResponseType";
  pathwayX?:
    | {
        __typename?: "ExceptionRequest";
        data?:
          | {
              __typename?: "ExceptionRequestData";
              requestText?: string | null | undefined;
              documents?:
                | Array<
                    | {
                        __typename?: "Document";
                        docId?: string | null | undefined;
                        docType?: string | null | undefined;
                        title?: string | null | undefined;
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type ApplicantQualificationIApplicantPortal_PathwayXMutationVariables =
  Exact<{
    requestText?: InputMaybe<Scalars["String"]>;
    documents?: InputMaybe<
      Array<InputMaybe<DocumentInput>> | InputMaybe<DocumentInput>
    >;
  }>;

export type ApplicantQualificationIApplicantPortal_PathwayXMutation = {
  __typename?: "Mutation";
  ApplicantPortal_pathwayX?:
    | {
        __typename?: "ApplicantPortalMutationResponseType";
        data?:
          | {
              __typename?: "ApplicantPortalResponseType";
              pathwayX?:
                | {
                    __typename?: "ExceptionRequest";
                    data?:
                      | {
                          __typename?: "ExceptionRequestData";
                          documents?:
                            | Array<
                                | {
                                    __typename?: "Document";
                                    docId?: string | null | undefined;
                                    docType?: string | null | undefined;
                                    title?: string | null | undefined;
                                  }
                                | null
                                | undefined
                              >
                            | null
                            | undefined;
                        }
                      | null
                      | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type ApplicantPhysicianEncounterAddModalAddEncounterMutationVariables =
  Exact<{
    input: AddMiniCexInput;
  }>;

export type ApplicantPhysicianEncounterAddModalAddEncounterMutation = {
  __typename?: "Mutation";
  ApplicantPortal_MiniCEX_add?:
    | Array<
        | {
            __typename?: "MiniCEXSummaryType";
            dataVersion?: number | null | undefined;
            encounterId?: string | null | undefined;
            physicianEmail?: string | null | undefined;
            physicianLastNameProvidedByApplicant?: string | null | undefined;
            physicianRestOfNameProvidedByApplicant?: string | null | undefined;
            physicianLastNameProvidedByPhysician?: string | null | undefined;
            physicianRestOfNameProvidedByPhysician?: string | null | undefined;
            createdAt?: any | null | undefined;
            expiresAt?: any | null | undefined;
            state?: string | null | undefined;
            allowedActions?:
              | Array<string | null | undefined>
              | null
              | undefined;
            isActive?: boolean | null | undefined;
            stateModel?:
              | Array<
                  | {
                      __typename?: "MiniCEXStateModelType";
                      state?: string | null | undefined;
                      timestamp?: any | null | undefined;
                    }
                  | null
                  | undefined
                >
              | null
              | undefined;
          }
        | null
        | undefined
      >
    | null
    | undefined;
};

export type ApplicantPhysicianRequestLayoutCseEncountersQueryVariables = Exact<{
  responseType: Scalars["String"];
}>;

export type ApplicantPhysicianRequestLayoutCseEncountersQuery = {
  __typename?: "Query";
  applicantPortal?:
    | {
        __typename?: "ApplicantPortalResponseType";
        minicexList?:
          | Array<
              | {
                  __typename?: "MiniCEXSummaryType";
                  dataVersion?: number | null | undefined;
                  encounterId?: string | null | undefined;
                  physicianEmail?: string | null | undefined;
                  physicianLastNameProvidedByApplicant?:
                    | string
                    | null
                    | undefined;
                  physicianRestOfNameProvidedByApplicant?:
                    | string
                    | null
                    | undefined;
                  physicianLastNameProvidedByPhysician?:
                    | string
                    | null
                    | undefined;
                  physicianRestOfNameProvidedByPhysician?:
                    | string
                    | null
                    | undefined;
                  createdAt?: any | null | undefined;
                  completedAt?: any | null | undefined;
                  expiresAt?: any | null | undefined;
                  state?: string | null | undefined;
                  allowedActions?:
                    | Array<string | null | undefined>
                    | null
                    | undefined;
                  isActive?: boolean | null | undefined;
                  stateModel?:
                    | Array<
                        | {
                            __typename?: "MiniCEXStateModelType";
                            state?: string | null | undefined;
                            timestamp?: any | null | undefined;
                          }
                        | null
                        | undefined
                      >
                    | null
                    | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
        caseDetails?:
          | {
              __typename?: "A_CaseDetails";
              output?: string | null | undefined;
              state?: string | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type ApplicantPhysicianRequestLayoutRemoveEncounterMutationVariables =
  Exact<{
    encounterId: Scalars["String"];
    dataVersion: Scalars["Int"];
  }>;

export type ApplicantPhysicianRequestLayoutRemoveEncounterMutation = {
  __typename?: "Mutation";
  ApplicantPortal_MiniCEX_remove?:
    | Array<
        | {
            __typename?: "MiniCEXSummaryType";
            dataVersion?: number | null | undefined;
            encounterId?: string | null | undefined;
            physicianEmail?: string | null | undefined;
            physicianLastNameProvidedByApplicant?: string | null | undefined;
            physicianRestOfNameProvidedByApplicant?: string | null | undefined;
            physicianLastNameProvidedByPhysician?: string | null | undefined;
            physicianRestOfNameProvidedByPhysician?: string | null | undefined;
            createdAt?: any | null | undefined;
            completedAt?: any | null | undefined;
            expiresAt?: any | null | undefined;
            state?: string | null | undefined;
            allowedActions?:
              | Array<string | null | undefined>
              | null
              | undefined;
            isActive?: boolean | null | undefined;
            stateModel?:
              | Array<
                  | {
                      __typename?: "MiniCEXStateModelType";
                      state?: string | null | undefined;
                      timestamp?: any | null | undefined;
                    }
                  | null
                  | undefined
                >
              | null
              | undefined;
          }
        | null
        | undefined
      >
    | null
    | undefined;
};

export type ApplicantPhysicianRequestLayoutValidateEmailMutationVariables =
  Exact<{
    email: Scalars["String"];
  }>;

export type ApplicantPhysicianRequestLayoutValidateEmailMutation = {
  __typename?: "Mutation";
  validateEmail?:
    | {
        __typename?: "ValidationResult";
        isValidated?: boolean | null | undefined;
        errorMessage?: string | null | undefined;
      }
    | null
    | undefined;
};

export type ApplicantQualificationReviewApplicantInfoFieldsFragment = {
  __typename?: "ApplicantPortalResponseType";
  applicant?:
    | {
        __typename?: "A_Applicant";
        usmleId?: string | null | undefined;
        firstName?: string | null | undefined;
        lastName?: string | null | undefined;
        email?: string | null | undefined;
      }
    | null
    | undefined;
};

export type ApplicantQualificationReviewCsExamNotRecentPastFieldsFragment = {
  __typename?: "ApplicantPortalResponseType";
  pathway2?:
    | {
        __typename?: "Pathway2";
        userResponse?:
          | {
              __typename?: "Pathway2UserResponse";
              hasPassedCSExamInRecentPast?: boolean | null | undefined;
            }
          | null
          | undefined;
        applicantProvidedData?:
          | Array<
              | {
                  __typename?: "CSExam";
                  _id?: string | null | undefined;
                  data?:
                    | {
                        __typename?: "CSExamData";
                        examDate?: string | null | undefined;
                        schoolRef?:
                          | {
                              __typename?: "SchoolResponseType";
                              _id?: number | null | undefined;
                              name?: string | null | undefined;
                              countryName?: string | null | undefined;
                            }
                          | null
                          | undefined;
                        oscExamRef?:
                          | {
                              __typename?: "oscexamResponseType";
                              _id?: string | null | undefined;
                              examCode?: string | null | undefined;
                              examFullName?: string | null | undefined;
                              examShortTitle?: string | null | undefined;
                            }
                          | null
                          | undefined;
                        documents?:
                          | Array<
                              | {
                                  __typename?: "Document";
                                  docId?: string | null | undefined;
                                  docType?: string | null | undefined;
                                  title?: string | null | undefined;
                                }
                              | null
                              | undefined
                            >
                          | null
                          | undefined;
                      }
                    | null
                    | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type ApplicantQualificationReviewCsExamFieldsFragment = {
  __typename?: "ApplicantPortalResponseType";
  pathway2?:
    | {
        __typename?: "Pathway2";
        userResponse?:
          | {
              __typename?: "Pathway2UserResponse";
              hasPassedCSExamInRecentPast?: boolean | null | undefined;
              hasPassedFromQualifiedSchoolInRecentPast?:
                | boolean
                | null
                | undefined;
            }
          | null
          | undefined;
        applicantProvidedData?:
          | Array<
              | {
                  __typename?: "CSExam";
                  _id?: string | null | undefined;
                  data?:
                    | {
                        __typename?: "CSExamData";
                        examDate?: string | null | undefined;
                        schoolRef?:
                          | {
                              __typename?: "SchoolResponseType";
                              _id?: number | null | undefined;
                              name?: string | null | undefined;
                              countryName?: string | null | undefined;
                            }
                          | null
                          | undefined;
                        oscExamRef?:
                          | {
                              __typename?: "oscexamResponseType";
                              _id?: string | null | undefined;
                              examCode?: string | null | undefined;
                              examFullName?: string | null | undefined;
                              examShortTitle?: string | null | undefined;
                            }
                          | null
                          | undefined;
                        documents?:
                          | Array<
                              | {
                                  __typename?: "Document";
                                  docId?: string | null | undefined;
                                  docType?: string | null | undefined;
                                  title?: string | null | undefined;
                                }
                              | null
                              | undefined
                            >
                          | null
                          | undefined;
                      }
                    | null
                    | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type ApplicantQualificationReviewEducationFieldsFragment = {
  __typename?: "ApplicantPortalResponseType";
  pathway345?:
    | {
        __typename?: "Pathway345";
        userResponse?:
          | {
              __typename?: "Pathway345UserResponse";
              isGradSchoolEligible?: boolean | null | undefined;
              isGradDateInAcceptableRange?: boolean | null | undefined;
            }
          | null
          | undefined;
        applicantProvidedData?:
          | Array<
              | {
                  __typename?: "Education";
                  _id?: string | null | undefined;
                  data?:
                    | {
                        __typename?: "EducationData";
                        gradDate?: string | null | undefined;
                        schoolRef?:
                          | {
                              __typename?: "SchoolResponseType";
                              name?: string | null | undefined;
                              countryName?: string | null | undefined;
                              _id?: number | null | undefined;
                            }
                          | null
                          | undefined;
                        documents?:
                          | Array<
                              | {
                                  __typename?: "Document";
                                  docId?: string | null | undefined;
                                  docType?: string | null | undefined;
                                  title?: string | null | undefined;
                                }
                              | null
                              | undefined
                            >
                          | null
                          | undefined;
                      }
                    | null
                    | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
      }
    | null
    | undefined;
  caseDetails?:
    | {
        __typename?: "A_CaseDetails";
        currentPathway?: string | null | undefined;
      }
    | null
    | undefined;
};

export type ApplicantQualificationReviewExceptionFieldsFragment = {
  __typename?: "ApplicantPortalResponseType";
  pathwayX?:
    | {
        __typename?: "ExceptionRequest";
        data?:
          | {
              __typename?: "ExceptionRequestData";
              requestText?: string | null | undefined;
              documents?:
                | Array<
                    | {
                        __typename?: "Document";
                        docType?: string | null | undefined;
                        title?: string | null | undefined;
                        docId?: string | null | undefined;
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type ApplicantQualificationReviewLicenseFieldsFragment = {
  __typename?: "ApplicantPortalResponseType";
  pathway1?:
    | {
        __typename?: "Pathway1";
        userResponse?:
          | {
              __typename?: "Pathway1UserResponse";
              isCurrentlyLicensed?: boolean | null | undefined;
              wasLicensedInRecentPast?: boolean | null | undefined;
            }
          | null
          | undefined;
        applicantProvidedData?:
          | Array<
              | {
                  __typename?: "License";
                  _id?: string | null | undefined;
                  data?:
                    | {
                        __typename?: "LicenseData";
                        licenseIssueDate?: string | null | undefined;
                        licenseExpiryDate?: string | null | undefined;
                        hasDisciplinaryAction?: boolean | null | undefined;
                        isCurrentlyLicensed?: boolean | null | undefined;
                        licensureDocumentStatus?: string | null | undefined;
                        regulatoryAuthorityRef?:
                          | {
                              __typename?: "regulatoryAuthorityResponseType";
                              _id?: number | null | undefined;
                              orgName?: string | null | undefined;
                              countryName?: string | null | undefined;
                            }
                          | null
                          | undefined;
                        documents?:
                          | Array<
                              | {
                                  __typename?: "Document";
                                  docType?: string | null | undefined;
                                  title?: string | null | undefined;
                                  docId?: string | null | undefined;
                                }
                              | null
                              | undefined
                            >
                          | null
                          | undefined;
                      }
                    | null
                    | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type ApplicantQualificationReviewLicenseRegulatoryAuthorityQueryVariables =
  Exact<{ [key: string]: never }>;

export type ApplicantQualificationReviewLicenseRegulatoryAuthorityQuery = {
  __typename?: "Query";
  regulatoryAuthority?:
    | Array<
        | {
            __typename?: "regulatoryAuthorityResponseType";
            _id?: number | null | undefined;
            orgName?: string | null | undefined;
            countryName?: string | null | undefined;
          }
        | null
        | undefined
      >
    | null
    | undefined;
};

export type ApplicantQualificationReviewPathway6FieldsFragment = {
  __typename?: "ApplicantPortalResponseType";
  pathway6?:
    | {
        __typename?: "Pathway6";
        userResponse?:
          | {
              __typename?: "Pathway6UserResponse";
              hasAgreedToProvidePhysicianInfo?: boolean | null | undefined;
            }
          | null
          | undefined;
        applicantProvidedData?:
          | Array<
              | {
                  __typename?: "Pathway6MiniCEXType";
                  _id?: string | null | undefined;
                  data?:
                    | {
                        __typename?: "MiniCEXData";
                        acknowledgedTimestamp?: any | null | undefined;
                        documents?:
                          | Array<
                              | {
                                  __typename?: "Document";
                                  docId?: string | null | undefined;
                                  docType?: string | null | undefined;
                                  title?: string | null | undefined;
                                }
                              | null
                              | undefined
                            >
                          | null
                          | undefined;
                      }
                    | null
                    | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type ApplicantQualificationReviewSummaryQueryVariables = Exact<{
  [key: string]: never;
}>;

export type ApplicantQualificationReviewSummaryQuery = {
  __typename?: "Query";
  applicantPortal?:
    | {
        __typename?: "ApplicantPortalResponseType";
        _id?: string | null | undefined;
        caseDetails?:
          | {
              __typename?: "A_CaseDetails";
              state?: string | null | undefined;
              isExceptionCase?: boolean | null | undefined;
              output?: string | null | undefined;
              outputReason?:
                | Array<string | null | undefined>
                | null
                | undefined;
              currentPathway?: string | null | undefined;
              isRequiredDataProvided?: boolean | null | undefined;
            }
          | null
          | undefined;
        graduation?:
          | {
              __typename?: "GraduationType";
              isYearEligible?: boolean | null | undefined;
              schoolEligibility?:
                | {
                    __typename?: "SchoolEligibilityType";
                    schoolPathway?: string | null | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
        eligibilityPreCheck?:
          | {
              __typename?: "EligibilityPreCheckResponseType";
              lastScreenName?: string | null | undefined;
              isStep2CSFailed?: boolean | null | undefined;
              eligibility?:
                | {
                    __typename?: "Eligibility";
                    isEligible?: boolean | null | undefined;
                    eligibilityCheckTimestamp?: any | null | undefined;
                    responseCode?: string | null | undefined;
                    responseMsg?: string | null | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
        pathwayX?:
          | {
              __typename?: "ExceptionRequest";
              data?:
                | {
                    __typename?: "ExceptionRequestData";
                    requestText?: string | null | undefined;
                    documents?:
                      | Array<
                          | {
                              __typename?: "Document";
                              docType?: string | null | undefined;
                              title?: string | null | undefined;
                              docId?: string | null | undefined;
                            }
                          | null
                          | undefined
                        >
                      | null
                      | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
        applicant?:
          | {
              __typename?: "A_Applicant";
              usmleId?: string | null | undefined;
              firstName?: string | null | undefined;
              lastName?: string | null | undefined;
              email?: string | null | undefined;
            }
          | null
          | undefined;
        pathway1?:
          | {
              __typename?: "Pathway1";
              userResponse?:
                | {
                    __typename?: "Pathway1UserResponse";
                    isCurrentlyLicensed?: boolean | null | undefined;
                    wasLicensedInRecentPast?: boolean | null | undefined;
                  }
                | null
                | undefined;
              applicantProvidedData?:
                | Array<
                    | {
                        __typename?: "License";
                        _id?: string | null | undefined;
                        data?:
                          | {
                              __typename?: "LicenseData";
                              licenseIssueDate?: string | null | undefined;
                              licenseExpiryDate?: string | null | undefined;
                              hasDisciplinaryAction?:
                                | boolean
                                | null
                                | undefined;
                              isCurrentlyLicensed?: boolean | null | undefined;
                              licensureDocumentStatus?:
                                | string
                                | null
                                | undefined;
                              regulatoryAuthorityRef?:
                                | {
                                    __typename?: "regulatoryAuthorityResponseType";
                                    _id?: number | null | undefined;
                                    orgName?: string | null | undefined;
                                    countryName?: string | null | undefined;
                                  }
                                | null
                                | undefined;
                              documents?:
                                | Array<
                                    | {
                                        __typename?: "Document";
                                        docType?: string | null | undefined;
                                        title?: string | null | undefined;
                                        docId?: string | null | undefined;
                                      }
                                    | null
                                    | undefined
                                  >
                                | null
                                | undefined;
                            }
                          | null
                          | undefined;
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined;
            }
          | null
          | undefined;
        pathway2?:
          | {
              __typename?: "Pathway2";
              userResponse?:
                | {
                    __typename?: "Pathway2UserResponse";
                    hasPassedCSExamInRecentPast?: boolean | null | undefined;
                    hasPassedFromQualifiedSchoolInRecentPast?:
                      | boolean
                      | null
                      | undefined;
                  }
                | null
                | undefined;
              applicantProvidedData?:
                | Array<
                    | {
                        __typename?: "CSExam";
                        _id?: string | null | undefined;
                        data?:
                          | {
                              __typename?: "CSExamData";
                              examDate?: string | null | undefined;
                              schoolRef?:
                                | {
                                    __typename?: "SchoolResponseType";
                                    _id?: number | null | undefined;
                                    name?: string | null | undefined;
                                    countryName?: string | null | undefined;
                                  }
                                | null
                                | undefined;
                              oscExamRef?:
                                | {
                                    __typename?: "oscexamResponseType";
                                    _id?: string | null | undefined;
                                    examCode?: string | null | undefined;
                                    examFullName?: string | null | undefined;
                                    examShortTitle?: string | null | undefined;
                                  }
                                | null
                                | undefined;
                              documents?:
                                | Array<
                                    | {
                                        __typename?: "Document";
                                        docId?: string | null | undefined;
                                        docType?: string | null | undefined;
                                        title?: string | null | undefined;
                                      }
                                    | null
                                    | undefined
                                  >
                                | null
                                | undefined;
                            }
                          | null
                          | undefined;
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined;
            }
          | null
          | undefined;
        pathway345?:
          | {
              __typename?: "Pathway345";
              userResponse?:
                | {
                    __typename?: "Pathway345UserResponse";
                    isGradSchoolEligible?: boolean | null | undefined;
                    isGradDateInAcceptableRange?: boolean | null | undefined;
                  }
                | null
                | undefined;
              applicantProvidedData?:
                | Array<
                    | {
                        __typename?: "Education";
                        _id?: string | null | undefined;
                        data?:
                          | {
                              __typename?: "EducationData";
                              gradDate?: string | null | undefined;
                              schoolRef?:
                                | {
                                    __typename?: "SchoolResponseType";
                                    name?: string | null | undefined;
                                    countryName?: string | null | undefined;
                                    _id?: number | null | undefined;
                                  }
                                | null
                                | undefined;
                              documents?:
                                | Array<
                                    | {
                                        __typename?: "Document";
                                        docId?: string | null | undefined;
                                        docType?: string | null | undefined;
                                        title?: string | null | undefined;
                                      }
                                    | null
                                    | undefined
                                  >
                                | null
                                | undefined;
                            }
                          | null
                          | undefined;
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined;
            }
          | null
          | undefined;
        pathway6?:
          | {
              __typename?: "Pathway6";
              userResponse?:
                | {
                    __typename?: "Pathway6UserResponse";
                    hasAgreedToProvidePhysicianInfo?:
                      | boolean
                      | null
                      | undefined;
                  }
                | null
                | undefined;
              applicantProvidedData?:
                | Array<
                    | {
                        __typename?: "Pathway6MiniCEXType";
                        _id?: string | null | undefined;
                        data?:
                          | {
                              __typename?: "MiniCEXData";
                              acknowledgedTimestamp?: any | null | undefined;
                              documents?:
                                | Array<
                                    | {
                                        __typename?: "Document";
                                        docId?: string | null | undefined;
                                        docType?: string | null | undefined;
                                        title?: string | null | undefined;
                                      }
                                    | null
                                    | undefined
                                  >
                                | null
                                | undefined;
                            }
                          | null
                          | undefined;
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type ApplicantRedirectAfterLoginNewFieldsFragment = {
  __typename?: "ApplicantPortalResponseType";
  eligibilityPreCheck?:
    | {
        __typename?: "EligibilityPreCheckResponseType";
        lastScreenName?: string | null | undefined;
        isStep2CSFailed?: boolean | null | undefined;
        eligibility?:
          | {
              __typename?: "Eligibility";
              isEligible?: boolean | null | undefined;
              eligibilityCheckTimestamp?: any | null | undefined;
              responseCode?: string | null | undefined;
              responseMsg?: string | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type ApplicantUploadDocumentUploadDocumentMutationVariables = Exact<{
  fileName: Scalars["String"];
}>;

export type ApplicantUploadDocumentUploadDocumentMutation = {
  __typename?: "Mutation";
  uploadDocument?:
    | {
        __typename?: "SASToken";
        token?:
          | { __typename?: "Token"; token: string; uri: string }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type ApplicantVerificationEligibleStep2CsFailedPastAttemptsFieldsFragment =
  {
    __typename?: "ApplicantPortalResponseType";
    pastAttempts?:
      | Array<
          | {
              __typename?: "PastAttemptsType";
              rejectionReason?:
                | Array<string | null | undefined>
                | null
                | undefined;
              pathway?: string | null | undefined;
            }
          | null
          | undefined
        >
      | null
      | undefined;
  };

export type ApplicantVerificationEligiblePastAttemptsFieldsFragment = {
  __typename?: "ApplicantPortalResponseType";
  pastAttempts?:
    | Array<
        | {
            __typename?: "PastAttemptsType";
            rejectionReason?:
              | Array<string | null | undefined>
              | null
              | undefined;
            pathway?: string | null | undefined;
            pathwaySeason?: string | null | undefined;
            applicationType?: string | null | undefined;
            submittedAt?: any | null | undefined;
            completedAt?: any | null | undefined;
            output?: string | null | undefined;
          }
        | null
        | undefined
      >
    | null
    | undefined;
};

export type ApplicantVerificationIneligiblePastAttemptsFieldsFragment = {
  __typename?: "ApplicantPortalResponseType";
  pastAttempts?:
    | Array<
        | {
            __typename?: "PastAttemptsType";
            rejectionReason?:
              | Array<string | null | undefined>
              | null
              | undefined;
            pathway?: string | null | undefined;
          }
        | null
        | undefined
      >
    | null
    | undefined;
};

export type ApplicantViewPdfDownloadDocumentQueryVariables = Exact<{
  filename: Scalars["String"];
}>;

export type ApplicantViewPdfDownloadDocumentQuery = {
  __typename?: "Query";
  downloadDocument?:
    | {
        __typename?: "SASToken";
        token?:
          | { __typename?: "Token"; token: string; uri: string }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type ApplicantRejectionReasonsFactoryUiConfigFieldsFragment = {
  __typename?: "ApplicantPortalResponseType";
  uiConfig?:
    | {
        __typename?: "UIPortalConfigType";
        config?:
          | {
              __typename?: "UIConfigRecordType";
              pages?:
                | Array<
                    | {
                        __typename?: "UIPageConfigType";
                        pageName?: string | null | undefined;
                        components?:
                          | Array<
                              | {
                                  __typename?: "UIComponentConfigType";
                                  componentName?: string | null | undefined;
                                  version?: number | null | undefined;
                                }
                              | null
                              | undefined
                            >
                          | null
                          | undefined;
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type ApplicantStatusMappingsFactoryUiConfigFieldsFragment = {
  __typename?: "ApplicantPortalResponseType";
  uiConfig?:
    | {
        __typename?: "UIPortalConfigType";
        config?:
          | {
              __typename?: "UIConfigRecordType";
              pages?:
                | Array<
                    | {
                        __typename?: "UIPageConfigType";
                        pageName?: string | null | undefined;
                        components?:
                          | Array<
                              | {
                                  __typename?: "UIComponentConfigType";
                                  componentName?: string | null | undefined;
                                  version?: number | null | undefined;
                                }
                              | null
                              | undefined
                            >
                          | null
                          | undefined;
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export const ApplicantAppProgressFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ApplicantAppProgressFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "ApplicantPortalResponseType" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "progress" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "pathwayEligibility" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "checklistRecord" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "checklistState" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "englishEligibility" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "checklistRecord" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "checklistState" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "generalEligibility" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "checklistRecord" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "checklistState" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ApplicantAppProgressFieldsFragment, unknown>;
export const ApplicantAppCaseDetailsFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ApplicantAppCaseDetailsFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "ApplicantPortalResponseType" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "caseDetails" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "state" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "currentPathway" },
                },
                { kind: "Field", name: { kind: "Name", value: "submittedAt" } },
                { kind: "Field", name: { kind: "Name", value: "output" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "outputReason" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "isExceptionCase" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ApplicantAppCaseDetailsFieldsFragment, unknown>;
export const ApplicantAppFinanceFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ApplicantAppFinanceFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "ApplicantPortalResponseType" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "finance" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "payment" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "amt" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "transTime" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "refund" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "amt" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "transTime" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ApplicantAppFinanceFieldsFragment, unknown>;
export const ApplicantAppPathway2FieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ApplicantAppPathway2Fields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "ApplicantPortalResponseType" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "pathway2" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "userResponse" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "hasPassedCSExamInRecentPast",
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ApplicantAppPathway2FieldsFragment, unknown>;
export const ApplicantAppGraduationFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ApplicantAppGraduationFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "ApplicantPortalResponseType" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "graduation" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "schoolEligibility" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "isSchoolEMSWPMember" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ApplicantAppGraduationFieldsFragment, unknown>;
export const ApplicantQualification2BPathway2FieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ApplicantQualification2BPathway2Fields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "ApplicantPortalResponseType" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "pathway2" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "userResponse" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "hasPassedCSExamInRecentPast",
                        },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "hasPassedFromQualifiedSchoolInRecentPast",
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "applicantProvidedData" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "data" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "examDate" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "schoolRef" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "_id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "countryName",
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "oscExamRef" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "_id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "examCode" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "examFullName",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "examShortTitle",
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "documents" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "docId" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "docType" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "title" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ApplicantQualification2BPathway2FieldsFragment,
  unknown
>;
export const ApplicantQualification6EncounterManagementPathway6FieldsFragmentDoc =
  {
    kind: "Document",
    definitions: [
      {
        kind: "FragmentDefinition",
        name: {
          kind: "Name",
          value: "ApplicantQualification6EncounterManagementPathway6Fields",
        },
        typeCondition: {
          kind: "NamedType",
          name: { kind: "Name", value: "ApplicantPortalResponseType" },
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [
            {
              kind: "Field",
              name: { kind: "Name", value: "pathway6" },
              selectionSet: {
                kind: "SelectionSet",
                selections: [
                  {
                    kind: "Field",
                    name: { kind: "Name", value: "userResponse" },
                    selectionSet: {
                      kind: "SelectionSet",
                      selections: [
                        {
                          kind: "Field",
                          name: {
                            kind: "Name",
                            value: "hasAgreedToProvidePhysicianInfo",
                          },
                        },
                      ],
                    },
                  },
                  {
                    kind: "Field",
                    name: { kind: "Name", value: "applicantProvidedData" },
                    selectionSet: {
                      kind: "SelectionSet",
                      selections: [
                        { kind: "Field", name: { kind: "Name", value: "_id" } },
                        {
                          kind: "Field",
                          name: { kind: "Name", value: "data" },
                          selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                              {
                                kind: "Field",
                                name: {
                                  kind: "Name",
                                  value: "acknowledgedTimestamp",
                                },
                              },
                              {
                                kind: "Field",
                                name: { kind: "Name", value: "documents" },
                                selectionSet: {
                                  kind: "SelectionSet",
                                  selections: [
                                    {
                                      kind: "Field",
                                      name: { kind: "Name", value: "docId" },
                                    },
                                    {
                                      kind: "Field",
                                      name: { kind: "Name", value: "docType" },
                                    },
                                    {
                                      kind: "Field",
                                      name: { kind: "Name", value: "title" },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                        },
                      ],
                    },
                  },
                ],
              },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    ApplicantQualification6EncounterManagementPathway6FieldsFragment,
    unknown
  >;
export const ApplicantQualification6Pathway6FieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ApplicantQualification6Pathway6Fields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "ApplicantPortalResponseType" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "pathway6" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "userResponse" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "hasAgreedToProvidePhysicianInfo",
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "applicantProvidedData" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "data" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "acknowledgedTimestamp",
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "documents" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "docId" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "docType" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "title" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ApplicantQualification6Pathway6FieldsFragment,
  unknown
>;
export const ApplicantQualificationAPathway1FieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ApplicantQualificationAPathway1Fields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "ApplicantPortalResponseType" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "pathway1" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "userResponse" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "isCurrentlyLicensed" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "wasLicensedInRecentPast",
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "applicantProvidedData" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "data" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "regulatoryAuthorityRef",
                              },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "_id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "orgName" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "countryName",
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "licenseIssueDate" },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "licenseExpiryDate",
                              },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "hasDisciplinaryAction",
                              },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "isCurrentlyLicensed",
                              },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "licensureDocumentStatus",
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "documents" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "docType" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "title" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "docId" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "staffProvidedData" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "data" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "regulatoryAuthorityRef",
                              },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "_id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "orgName" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "countryName",
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "licenseIssueDate" },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "licenseExpiryDate",
                              },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "hasDisciplinaryAction",
                              },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "isCurrentlyLicensed",
                              },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "licensureDocumentStatus",
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "documents" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "docType" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "title" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "docId" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ApplicantQualificationAPathway1FieldsFragment,
  unknown
>;
export const ApplicantQualificationBPathway1FieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ApplicantQualificationBPathway1Fields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "ApplicantPortalResponseType" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "pathway1" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "userResponse" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "isCurrentlyLicensed" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "wasLicensedInRecentPast",
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "applicantProvidedData" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "data" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "regulatoryAuthorityRef",
                              },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "_id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "orgName" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "countryName",
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "licenseIssueDate" },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "licenseExpiryDate",
                              },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "hasDisciplinaryAction",
                              },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "isCurrentlyLicensed",
                              },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "licensureDocumentStatus",
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "documents" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "docType" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "title" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "docId" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "staffProvidedData" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "data" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "regulatoryAuthorityRef",
                              },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "_id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "orgName" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "countryName",
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "licenseIssueDate" },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "licenseExpiryDate",
                              },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "hasDisciplinaryAction",
                              },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "isCurrentlyLicensed",
                              },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "licensureDocumentStatus",
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "documents" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "docType" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "title" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "docId" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ApplicantQualificationBPathway1FieldsFragment,
  unknown
>;
export const ApplicantQualificationFPathway345FFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ApplicantQualificationFPathway345FFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "ApplicantPortalResponseType" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "pathway345" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "userResponse" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "isGradSchoolEligible" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "isGradDateInAcceptableRange",
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "applicantProvidedData" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "data" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "gradDate" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "schoolRef" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "countryName",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "_id" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "documents" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "docId" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "docType" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "title" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ApplicantQualificationFPathway345FFieldsFragment,
  unknown
>;
export const ApplicantQualificationIPathwayXFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ApplicantQualificationIPathwayXFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "ApplicantPortalResponseType" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "pathwayX" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "data" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "requestText" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "documents" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "docId" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "docType" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "title" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ApplicantQualificationIPathwayXFieldsFragment,
  unknown
>;
export const ApplicantQualificationReviewApplicantInfoFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: {
        kind: "Name",
        value: "ApplicantQualificationReviewApplicantInfoFields",
      },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "ApplicantPortalResponseType" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "applicant" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "usmleId" } },
                { kind: "Field", name: { kind: "Name", value: "firstName" } },
                { kind: "Field", name: { kind: "Name", value: "lastName" } },
                { kind: "Field", name: { kind: "Name", value: "email" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ApplicantQualificationReviewApplicantInfoFieldsFragment,
  unknown
>;
export const ApplicantQualificationReviewCsExamNotRecentPastFieldsFragmentDoc =
  {
    kind: "Document",
    definitions: [
      {
        kind: "FragmentDefinition",
        name: {
          kind: "Name",
          value: "ApplicantQualificationReviewCSExamNotRecentPastFields",
        },
        typeCondition: {
          kind: "NamedType",
          name: { kind: "Name", value: "ApplicantPortalResponseType" },
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [
            {
              kind: "Field",
              name: { kind: "Name", value: "pathway2" },
              selectionSet: {
                kind: "SelectionSet",
                selections: [
                  {
                    kind: "Field",
                    name: { kind: "Name", value: "userResponse" },
                    selectionSet: {
                      kind: "SelectionSet",
                      selections: [
                        {
                          kind: "Field",
                          name: {
                            kind: "Name",
                            value: "hasPassedCSExamInRecentPast",
                          },
                        },
                      ],
                    },
                  },
                  {
                    kind: "Field",
                    name: { kind: "Name", value: "applicantProvidedData" },
                    selectionSet: {
                      kind: "SelectionSet",
                      selections: [
                        { kind: "Field", name: { kind: "Name", value: "_id" } },
                        {
                          kind: "Field",
                          name: { kind: "Name", value: "data" },
                          selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                              {
                                kind: "Field",
                                name: { kind: "Name", value: "examDate" },
                              },
                              {
                                kind: "Field",
                                name: { kind: "Name", value: "schoolRef" },
                                selectionSet: {
                                  kind: "SelectionSet",
                                  selections: [
                                    {
                                      kind: "Field",
                                      name: { kind: "Name", value: "_id" },
                                    },
                                    {
                                      kind: "Field",
                                      name: { kind: "Name", value: "name" },
                                    },
                                    {
                                      kind: "Field",
                                      name: {
                                        kind: "Name",
                                        value: "countryName",
                                      },
                                    },
                                  ],
                                },
                              },
                              {
                                kind: "Field",
                                name: { kind: "Name", value: "oscExamRef" },
                                selectionSet: {
                                  kind: "SelectionSet",
                                  selections: [
                                    {
                                      kind: "Field",
                                      name: { kind: "Name", value: "_id" },
                                    },
                                    {
                                      kind: "Field",
                                      name: { kind: "Name", value: "examCode" },
                                    },
                                    {
                                      kind: "Field",
                                      name: {
                                        kind: "Name",
                                        value: "examFullName",
                                      },
                                    },
                                    {
                                      kind: "Field",
                                      name: {
                                        kind: "Name",
                                        value: "examShortTitle",
                                      },
                                    },
                                  ],
                                },
                              },
                              {
                                kind: "Field",
                                name: { kind: "Name", value: "documents" },
                                selectionSet: {
                                  kind: "SelectionSet",
                                  selections: [
                                    {
                                      kind: "Field",
                                      name: { kind: "Name", value: "docId" },
                                    },
                                    {
                                      kind: "Field",
                                      name: { kind: "Name", value: "docType" },
                                    },
                                    {
                                      kind: "Field",
                                      name: { kind: "Name", value: "title" },
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                        },
                      ],
                    },
                  },
                ],
              },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    ApplicantQualificationReviewCsExamNotRecentPastFieldsFragment,
    unknown
  >;
export const ApplicantQualificationReviewCsExamFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ApplicantQualificationReviewCSExamFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "ApplicantPortalResponseType" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "pathway2" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "userResponse" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "hasPassedCSExamInRecentPast",
                        },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "hasPassedFromQualifiedSchoolInRecentPast",
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "applicantProvidedData" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "data" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "examDate" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "schoolRef" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "_id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "countryName",
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "oscExamRef" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "_id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "examCode" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "examFullName",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "examShortTitle",
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "documents" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "docId" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "docType" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "title" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ApplicantQualificationReviewCsExamFieldsFragment,
  unknown
>;
export const ApplicantQualificationReviewEducationFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: {
        kind: "Name",
        value: "ApplicantQualificationReviewEducationFields",
      },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "ApplicantPortalResponseType" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "pathway345" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "userResponse" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "isGradSchoolEligible" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "isGradDateInAcceptableRange",
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "applicantProvidedData" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "data" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "gradDate" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "schoolRef" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "countryName",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "_id" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "documents" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "docId" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "docType" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "title" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "caseDetails" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "currentPathway" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ApplicantQualificationReviewEducationFieldsFragment,
  unknown
>;
export const ApplicantQualificationReviewExceptionFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: {
        kind: "Name",
        value: "ApplicantQualificationReviewExceptionFields",
      },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "ApplicantPortalResponseType" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "pathwayX" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "data" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "requestText" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "documents" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "docType" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "title" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "docId" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ApplicantQualificationReviewExceptionFieldsFragment,
  unknown
>;
export const ApplicantQualificationReviewLicenseFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: {
        kind: "Name",
        value: "ApplicantQualificationReviewLicenseFields",
      },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "ApplicantPortalResponseType" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "pathway1" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "userResponse" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "isCurrentlyLicensed" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "wasLicensedInRecentPast",
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "applicantProvidedData" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "data" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "regulatoryAuthorityRef",
                              },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "_id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "orgName" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "countryName",
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "licenseIssueDate" },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "licenseExpiryDate",
                              },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "hasDisciplinaryAction",
                              },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "isCurrentlyLicensed",
                              },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "licensureDocumentStatus",
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "documents" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "docType" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "title" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "docId" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ApplicantQualificationReviewLicenseFieldsFragment,
  unknown
>;
export const ApplicantQualificationReviewPathway6FieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: {
        kind: "Name",
        value: "ApplicantQualificationReviewPathway6Fields",
      },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "ApplicantPortalResponseType" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "pathway6" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "userResponse" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "hasAgreedToProvidePhysicianInfo",
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "applicantProvidedData" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "data" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "acknowledgedTimestamp",
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "documents" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "docId" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "docType" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "title" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ApplicantQualificationReviewPathway6FieldsFragment,
  unknown
>;
export const ApplicantRedirectAfterLoginNewFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ApplicantRedirectAfterLoginNewFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "ApplicantPortalResponseType" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "eligibilityPreCheck" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "eligibility" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "isEligible" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "eligibilityCheckTimestamp",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "responseCode" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "responseMsg" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "lastScreenName" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "isStep2CSFailed" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ApplicantRedirectAfterLoginNewFieldsFragment,
  unknown
>;
export const ApplicantVerificationEligibleStep2CsFailedPastAttemptsFieldsFragmentDoc =
  {
    kind: "Document",
    definitions: [
      {
        kind: "FragmentDefinition",
        name: {
          kind: "Name",
          value: "ApplicantVerificationEligibleStep2CSFailedPastAttemptsFields",
        },
        typeCondition: {
          kind: "NamedType",
          name: { kind: "Name", value: "ApplicantPortalResponseType" },
        },
        selectionSet: {
          kind: "SelectionSet",
          selections: [
            {
              kind: "Field",
              name: { kind: "Name", value: "pastAttempts" },
              selectionSet: {
                kind: "SelectionSet",
                selections: [
                  {
                    kind: "Field",
                    name: { kind: "Name", value: "rejectionReason" },
                  },
                  { kind: "Field", name: { kind: "Name", value: "pathway" } },
                ],
              },
            },
          ],
        },
      },
    ],
  } as unknown as DocumentNode<
    ApplicantVerificationEligibleStep2CsFailedPastAttemptsFieldsFragment,
    unknown
  >;
export const ApplicantVerificationEligiblePastAttemptsFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: {
        kind: "Name",
        value: "ApplicantVerificationEligiblePastAttemptsFields",
      },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "ApplicantPortalResponseType" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "pastAttempts" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "rejectionReason" },
                },
                { kind: "Field", name: { kind: "Name", value: "pathway" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "pathwaySeason" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "applicationType" },
                },
                { kind: "Field", name: { kind: "Name", value: "submittedAt" } },
                { kind: "Field", name: { kind: "Name", value: "completedAt" } },
                { kind: "Field", name: { kind: "Name", value: "output" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ApplicantVerificationEligiblePastAttemptsFieldsFragment,
  unknown
>;
export const ApplicantVerificationIneligiblePastAttemptsFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: {
        kind: "Name",
        value: "ApplicantVerificationIneligiblePastAttemptsFields",
      },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "ApplicantPortalResponseType" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "pastAttempts" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "rejectionReason" },
                },
                { kind: "Field", name: { kind: "Name", value: "pathway" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ApplicantVerificationIneligiblePastAttemptsFieldsFragment,
  unknown
>;
export const ApplicantRejectionReasonsFactoryUiConfigFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: {
        kind: "Name",
        value: "ApplicantRejectionReasonsFactoryUIConfigFields",
      },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "ApplicantPortalResponseType" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "uiConfig" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "config" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "pages" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "pageName" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "components" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "componentName",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "version" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ApplicantRejectionReasonsFactoryUiConfigFieldsFragment,
  unknown
>;
export const ApplicantStatusMappingsFactoryUiConfigFieldsFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: {
        kind: "Name",
        value: "ApplicantStatusMappingsFactoryUIConfigFields",
      },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "ApplicantPortalResponseType" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "uiConfig" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "config" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "pages" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "pageName" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "components" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "componentName",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "version" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ApplicantStatusMappingsFactoryUiConfigFieldsFragment,
  unknown
>;
export const ApplicantAppApplicantPortalDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "ApplicantAppApplicantPortal" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "applicantPortal" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: {
                    kind: "Name",
                    value: "ApplicantQualificationAPathway1Fields",
                  },
                },
                {
                  kind: "FragmentSpread",
                  name: {
                    kind: "Name",
                    value: "ApplicantQualificationBPathway1Fields",
                  },
                },
                {
                  kind: "FragmentSpread",
                  name: {
                    kind: "Name",
                    value: "ApplicantQualification2BPathway2Fields",
                  },
                },
                {
                  kind: "FragmentSpread",
                  name: {
                    kind: "Name",
                    value: "ApplicantQualificationFPathway345FFields",
                  },
                },
                {
                  kind: "FragmentSpread",
                  name: {
                    kind: "Name",
                    value: "ApplicantQualification6Pathway6Fields",
                  },
                },
                {
                  kind: "FragmentSpread",
                  name: {
                    kind: "Name",
                    value:
                      "ApplicantQualification6EncounterManagementPathway6Fields",
                  },
                },
                {
                  kind: "FragmentSpread",
                  name: {
                    kind: "Name",
                    value: "ApplicantQualificationIPathwayXFields",
                  },
                },
                {
                  kind: "FragmentSpread",
                  name: {
                    kind: "Name",
                    value: "ApplicantVerificationIneligiblePastAttemptsFields",
                  },
                },
                {
                  kind: "FragmentSpread",
                  name: {
                    kind: "Name",
                    value:
                      "ApplicantVerificationEligibleStep2CSFailedPastAttemptsFields",
                  },
                },
                {
                  kind: "FragmentSpread",
                  name: {
                    kind: "Name",
                    value: "ApplicantVerificationEligiblePastAttemptsFields",
                  },
                },
                {
                  kind: "FragmentSpread",
                  name: {
                    kind: "Name",
                    value: "ApplicantRejectionReasonsFactoryUIConfigFields",
                  },
                },
                {
                  kind: "FragmentSpread",
                  name: {
                    kind: "Name",
                    value: "ApplicantStatusMappingsFactoryUIConfigFields",
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "caseDetails" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "isApplicationCreationAllowed",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "submittedPathway" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "pathwaySeason" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "applicationType" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "graduation" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "isYearEligible" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "school" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "schoolEligibility" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "schoolPathway" },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "isSchoolEMSWPMember",
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "ApplicantAppProgressFields" },
                },
                {
                  kind: "FragmentSpread",
                  name: {
                    kind: "Name",
                    value: "ApplicantAppCaseDetailsFields",
                  },
                },
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "ApplicantAppFinanceFields" },
                },
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "ApplicantAppPathway2Fields" },
                },
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "ApplicantAppGraduationFields" },
                },
                {
                  kind: "FragmentSpread",
                  name: {
                    kind: "Name",
                    value: "ApplicantRedirectAfterLoginNewFields",
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...ApplicantQualificationAPathway1FieldsFragmentDoc.definitions,
    ...ApplicantQualificationBPathway1FieldsFragmentDoc.definitions,
    ...ApplicantQualification2BPathway2FieldsFragmentDoc.definitions,
    ...ApplicantQualificationFPathway345FFieldsFragmentDoc.definitions,
    ...ApplicantQualification6Pathway6FieldsFragmentDoc.definitions,
    ...ApplicantQualification6EncounterManagementPathway6FieldsFragmentDoc.definitions,
    ...ApplicantQualificationIPathwayXFieldsFragmentDoc.definitions,
    ...ApplicantVerificationIneligiblePastAttemptsFieldsFragmentDoc.definitions,
    ...ApplicantVerificationEligibleStep2CsFailedPastAttemptsFieldsFragmentDoc.definitions,
    ...ApplicantVerificationEligiblePastAttemptsFieldsFragmentDoc.definitions,
    ...ApplicantRejectionReasonsFactoryUiConfigFieldsFragmentDoc.definitions,
    ...ApplicantStatusMappingsFactoryUiConfigFieldsFragmentDoc.definitions,
    ...ApplicantAppProgressFieldsFragmentDoc.definitions,
    ...ApplicantAppCaseDetailsFieldsFragmentDoc.definitions,
    ...ApplicantAppFinanceFieldsFragmentDoc.definitions,
    ...ApplicantAppPathway2FieldsFragmentDoc.definitions,
    ...ApplicantAppGraduationFieldsFragmentDoc.definitions,
    ...ApplicantRedirectAfterLoginNewFieldsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  ApplicantAppApplicantPortalQuery,
  ApplicantAppApplicantPortalQueryVariables
>;
export const ApplicantBillingInformationAuthTokenDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "ApplicantBillingInformationAuthToken" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "amount" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "Float" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "billToFirstName" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "billToMiddleName" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "billToLastName" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "billToStreet" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "billToStreet2" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "billToCity" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "billToState" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "billToZip" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "usmleID" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "acknowledgeTimestamp" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "requestSecureToken" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "requestArgument" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "amount" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "amount" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "billToFirstName" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "billToFirstName" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "billToMiddleName" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "billToMiddleName" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "billToLastName" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "billToLastName" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "billToStreet" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "billToStreet" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "billToStreet2" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "billToStreet2" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "billToCity" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "billToCity" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "billToState" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "billToState" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "billToZip" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "billToZip" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "usmleID" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "usmleID" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "acknowledgeTimestamp" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "acknowledgeTimestamp" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "secureToken" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "secureTokenId" },
                },
                { kind: "Field", name: { kind: "Name", value: "alreadyPaid" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ApplicantBillingInformationAuthTokenMutation,
  ApplicantBillingInformationAuthTokenMutationVariables
>;
export const ApplicantBillingInformationCaseFeeDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "ApplicantBillingInformationCaseFee" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "applicantPortal" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "finance" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "caseFee" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "applicant" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "usmleId" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "caseDetails" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "isRequiredDataProvided" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ApplicantBillingInformationCaseFeeQuery,
  ApplicantBillingInformationCaseFeeQueryVariables
>;
export const ApplicantBillingInformationApplicationValidationDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: {
        kind: "Name",
        value: "ApplicantBillingInformationApplicationValidation",
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "applicantPortal" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "caseDetails" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "isRequiredDataProvided" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ApplicantBillingInformationApplicationValidationQuery,
  ApplicantBillingInformationApplicationValidationQueryVariables
>;
export const ApplicantBillingInformationCountriesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "ApplicantBillingInformationCountries" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "country" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "countryName" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ApplicantBillingInformationCountriesQuery,
  ApplicantBillingInformationCountriesQueryVariables
>;
export const ApplicantBillingInformationCheckPaymentExistsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: {
        kind: "Name",
        value: "ApplicantBillingInformationCheckPaymentExists",
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "ApplicantPortal_checkPaymentExists" },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ApplicantBillingInformationCheckPaymentExistsMutation,
  ApplicantBillingInformationCheckPaymentExistsMutationVariables
>;
export const ApplicantDocumentUploaderUploadDocumentDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "ApplicantDocumentUploaderUploadDocument" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "fileName" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "uploadDocument" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "fileName" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "fileName" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "token" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "token" } },
                      { kind: "Field", name: { kind: "Name", value: "uri" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ApplicantDocumentUploaderUploadDocumentMutation,
  ApplicantDocumentUploaderUploadDocumentMutationVariables
>;
export const ApplicantLoginPortalLoginDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "ApplicantLoginPortalLogin" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "userid" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "password" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "ApplicantPortal_login" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "userid" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "userid" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "password" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "password" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "token" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "case" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "isApplicationCreationAllowed",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "applicationType" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ApplicantLoginPortalLoginMutation,
  ApplicantLoginPortalLoginMutationVariables
>;
export const ApplicantQualification2BGraduationDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "ApplicantQualification2BGraduation" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "applicantPortal" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "graduation" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "isYearEligible" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "school" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "schoolEligibility" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "schoolPathway" },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "isSchoolEMSWPMember",
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ApplicantQualification2BGraduationQuery,
  ApplicantQualification2BGraduationQueryVariables
>;
export const ApplicantQualification2BApplicantPortal_Pathway2bDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: {
        kind: "Name",
        value: "ApplicantQualification2BApplicantPortal_pathway2b",
      },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "supportingData" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "CSExamInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "ApplicantPortal_pathway2b" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "applicantProvidedData" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "supportingData" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "status" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "data" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "pathway2" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "applicantProvidedData",
                              },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "data" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "examDate",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "schoolRef",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "_id",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "name",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "countryName",
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "oscExamRef",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "examCode",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "examShortTitle",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "examFullName",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "_id",
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "documents",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "docId",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "docType",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "title",
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ApplicantQualification2BApplicantPortal_Pathway2bMutation,
  ApplicantQualification2BApplicantPortal_Pathway2bMutationVariables
>;
export const ApplicantQualification6ApplicantPortal_Pathway6Document = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: {
        kind: "Name",
        value: "ApplicantQualification6ApplicantPortal_pathway6",
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "ApplicantPortal_pathway6" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "data" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "pathway6" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "applicantProvidedData",
                              },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "_id" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ApplicantQualification6ApplicantPortal_Pathway6Mutation,
  ApplicantQualification6ApplicantPortal_Pathway6MutationVariables
>;
export const ApplicantQualificationAApplicantPortal_Pathway1aDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: {
        kind: "Name",
        value: "ApplicantQualificationAApplicantPortal_pathway1a",
      },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "isCurrentlyLicensed" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "Boolean" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "supportingData" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "LicenseInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "ApplicantPortal_pathway1a" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "userResponse" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: {
                              kind: "Name",
                              value: "isCurrentlyLicensed",
                            },
                            value: {
                              kind: "Variable",
                              name: {
                                kind: "Name",
                                value: "isCurrentlyLicensed",
                              },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "applicantProvidedData" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "supportingData" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "status" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "data" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "pathway1" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "userResponse" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "isCurrentlyLicensed",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "wasLicensedInRecentPast",
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "applicantProvidedData",
                              },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "_id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "data" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "regulatoryAuthorityRef",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "_id",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "orgName",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "countryName",
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "licenseIssueDate",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "licenseExpiryDate",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "hasDisciplinaryAction",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "isCurrentlyLicensed",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "licensureDocumentStatus",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "documents",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "docType",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "title",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "docId",
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ApplicantQualificationAApplicantPortal_Pathway1aMutation,
  ApplicantQualificationAApplicantPortal_Pathway1aMutationVariables
>;
export const ApplicantQualificationBApplicantPortal_Pathway1bDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: {
        kind: "Name",
        value: "ApplicantQualificationBApplicantPortal_pathway1b",
      },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "wasLicensedInRecentPast" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "Boolean" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "supportingData" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "LicenseInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "ApplicantPortal_pathway1b" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "userResponse" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: {
                              kind: "Name",
                              value: "wasLicensedInRecentPast",
                            },
                            value: {
                              kind: "Variable",
                              name: {
                                kind: "Name",
                                value: "wasLicensedInRecentPast",
                              },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "applicantProvidedData" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "supportingData" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "status" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "data" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "pathway1" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "userResponse" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "isCurrentlyLicensed",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "wasLicensedInRecentPast",
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "applicantProvidedData",
                              },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "_id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "data" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "regulatoryAuthorityRef",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "_id",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "orgName",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "countryName",
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "licenseIssueDate",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "licenseExpiryDate",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "hasDisciplinaryAction",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "isCurrentlyLicensed",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "licensureDocumentStatus",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "documents",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "docType",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "title",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "docId",
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ApplicantQualificationBApplicantPortal_Pathway1bMutation,
  ApplicantQualificationBApplicantPortal_Pathway1bMutationVariables
>;
export const ApplicantQualificationC1RegulatoryAuthorityDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: {
        kind: "Name",
        value: "ApplicantQualificationC1RegulatoryAuthority",
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "regulatoryAuthority" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "orgName" } },
                { kind: "Field", name: { kind: "Name", value: "countryName" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ApplicantQualificationC1RegulatoryAuthorityQuery,
  ApplicantQualificationC1RegulatoryAuthorityQueryVariables
>;
export const ApplicantQualificationC2ApplicantPortal_Pathway1aDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: {
        kind: "Name",
        value: "ApplicantQualificationC2ApplicantPortal_pathway1a",
      },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "isCurrentlyLicensed" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "Boolean" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "supportingData" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "LicenseInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "ApplicantPortal_pathway1a" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "userResponse" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: {
                              kind: "Name",
                              value: "isCurrentlyLicensed",
                            },
                            value: {
                              kind: "Variable",
                              name: {
                                kind: "Name",
                                value: "isCurrentlyLicensed",
                              },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "applicantProvidedData" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "supportingData" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "status" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "data" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "pathway1" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "userResponse" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "isCurrentlyLicensed",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "wasLicensedInRecentPast",
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "applicantProvidedData",
                              },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "_id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "data" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "regulatoryAuthorityRef",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "_id",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "orgName",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "countryName",
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "licenseIssueDate",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "licenseExpiryDate",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "hasDisciplinaryAction",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "isCurrentlyLicensed",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "licensureDocumentStatus",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "documents",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "docType",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "title",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "docId",
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ApplicantQualificationC2ApplicantPortal_Pathway1aMutation,
  ApplicantQualificationC2ApplicantPortal_Pathway1aMutationVariables
>;
export const ApplicantQualificationC2ApplicantPortal_Pathway1bDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: {
        kind: "Name",
        value: "ApplicantQualificationC2ApplicantPortal_pathway1b",
      },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "wasLicensedInRecentPast" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "Boolean" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "supportingData" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "LicenseInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "ApplicantPortal_pathway1b" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "userResponse" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: {
                              kind: "Name",
                              value: "wasLicensedInRecentPast",
                            },
                            value: {
                              kind: "Variable",
                              name: {
                                kind: "Name",
                                value: "wasLicensedInRecentPast",
                              },
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "applicantProvidedData" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "supportingData" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "status" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "data" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "pathway1" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "userResponse" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "isCurrentlyLicensed",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "wasLicensedInRecentPast",
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "applicantProvidedData",
                              },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "_id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "data" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "regulatoryAuthorityRef",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "_id",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "orgName",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "countryName",
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "licenseIssueDate",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "licenseExpiryDate",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "hasDisciplinaryAction",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "isCurrentlyLicensed",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "licensureDocumentStatus",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "documents",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "docType",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "title",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "docId",
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ApplicantQualificationC2ApplicantPortal_Pathway1bMutation,
  ApplicantQualificationC2ApplicantPortal_Pathway1bMutationVariables
>;
export const ApplicantQualificationFGraduationDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "ApplicantQualificationFGraduation" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "applicantPortal" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "graduation" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "isYearEligible" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "school" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "schoolEligibility" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "schoolPathway" },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "isSchoolEMSWPMember",
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ApplicantQualificationFGraduationQuery,
  ApplicantQualificationFGraduationQueryVariables
>;
export const ApplicantQualificationFApplicantPortal_Pathway345Document = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: {
        kind: "Name",
        value: "ApplicantQualificationFApplicantPortal_pathway345",
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "ApplicantPortal_pathway345" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "data" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "pathway345" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "applicantProvidedData",
                              },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "_id" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ApplicantQualificationFApplicantPortal_Pathway345Mutation,
  ApplicantQualificationFApplicantPortal_Pathway345MutationVariables
>;
export const ApplicantQualificationIApplicantPortal_PathwayXDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: {
        kind: "Name",
        value: "ApplicantQualificationIApplicantPortal_pathwayX",
      },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "requestText" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "documents" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "DocumentInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "ApplicantPortal_pathwayX" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "data" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "requestText" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "requestText" },
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "documents" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "documents" },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "data" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "pathwayX" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "data" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "documents" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "docId",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "docType",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "title",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ApplicantQualificationIApplicantPortal_PathwayXMutation,
  ApplicantQualificationIApplicantPortal_PathwayXMutationVariables
>;
export const ApplicantPhysicianEncounterAddModalAddEncounterDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: {
        kind: "Name",
        value: "ApplicantPhysicianEncounterAddModalAddEncounter",
      },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "AddMiniCEXInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "ApplicantPortal_MiniCEX_add" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "dataVersion" } },
                { kind: "Field", name: { kind: "Name", value: "encounterId" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "physicianEmail" },
                },
                {
                  kind: "Field",
                  name: {
                    kind: "Name",
                    value: "physicianLastNameProvidedByApplicant",
                  },
                },
                {
                  kind: "Field",
                  name: {
                    kind: "Name",
                    value: "physicianRestOfNameProvidedByApplicant",
                  },
                },
                {
                  kind: "Field",
                  name: {
                    kind: "Name",
                    value: "physicianLastNameProvidedByPhysician",
                  },
                },
                {
                  kind: "Field",
                  name: {
                    kind: "Name",
                    value: "physicianRestOfNameProvidedByPhysician",
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "createdAt" } },
                { kind: "Field", name: { kind: "Name", value: "expiresAt" } },
                { kind: "Field", name: { kind: "Name", value: "state" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "stateModel" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "state" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "timestamp" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "allowedActions" },
                },
                { kind: "Field", name: { kind: "Name", value: "isActive" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ApplicantPhysicianEncounterAddModalAddEncounterMutation,
  ApplicantPhysicianEncounterAddModalAddEncounterMutationVariables
>;
export const ApplicantPhysicianRequestLayoutCseEncountersDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: {
        kind: "Name",
        value: "ApplicantPhysicianRequestLayoutCSEEncounters",
      },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "responseType" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "applicantPortal" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "minicexList" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "filter" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "responseType" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "responseType" },
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "dataVersion" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "encounterId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "physicianEmail" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "physicianLastNameProvidedByApplicant",
                        },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "physicianRestOfNameProvidedByApplicant",
                        },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "physicianLastNameProvidedByPhysician",
                        },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "physicianRestOfNameProvidedByPhysician",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "createdAt" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "completedAt" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "expiresAt" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "state" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "stateModel" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "state" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "timestamp" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "allowedActions" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "isActive" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "caseDetails" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "output" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "state" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ApplicantPhysicianRequestLayoutCseEncountersQuery,
  ApplicantPhysicianRequestLayoutCseEncountersQueryVariables
>;
export const ApplicantPhysicianRequestLayoutRemoveEncounterDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: {
        kind: "Name",
        value: "ApplicantPhysicianRequestLayoutRemoveEncounter",
      },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "encounterId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "dataVersion" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "ApplicantPortal_MiniCEX_remove" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "encounterId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "encounterId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "dataVersion" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "dataVersion" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "dataVersion" } },
                { kind: "Field", name: { kind: "Name", value: "encounterId" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "physicianEmail" },
                },
                {
                  kind: "Field",
                  name: {
                    kind: "Name",
                    value: "physicianLastNameProvidedByApplicant",
                  },
                },
                {
                  kind: "Field",
                  name: {
                    kind: "Name",
                    value: "physicianRestOfNameProvidedByApplicant",
                  },
                },
                {
                  kind: "Field",
                  name: {
                    kind: "Name",
                    value: "physicianLastNameProvidedByPhysician",
                  },
                },
                {
                  kind: "Field",
                  name: {
                    kind: "Name",
                    value: "physicianRestOfNameProvidedByPhysician",
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "createdAt" } },
                { kind: "Field", name: { kind: "Name", value: "completedAt" } },
                { kind: "Field", name: { kind: "Name", value: "expiresAt" } },
                { kind: "Field", name: { kind: "Name", value: "state" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "stateModel" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "state" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "timestamp" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "allowedActions" },
                },
                { kind: "Field", name: { kind: "Name", value: "isActive" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ApplicantPhysicianRequestLayoutRemoveEncounterMutation,
  ApplicantPhysicianRequestLayoutRemoveEncounterMutationVariables
>;
export const ApplicantPhysicianRequestLayoutValidateEmailDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: {
        kind: "Name",
        value: "ApplicantPhysicianRequestLayoutValidateEmail",
      },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "email" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "validateEmail" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "email" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "email" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "isValidated" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "errorMessage" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ApplicantPhysicianRequestLayoutValidateEmailMutation,
  ApplicantPhysicianRequestLayoutValidateEmailMutationVariables
>;
export const ApplicantQualificationReviewLicenseRegulatoryAuthorityDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: {
        kind: "Name",
        value: "ApplicantQualificationReviewLicenseRegulatoryAuthority",
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "regulatoryAuthority" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                { kind: "Field", name: { kind: "Name", value: "orgName" } },
                { kind: "Field", name: { kind: "Name", value: "countryName" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ApplicantQualificationReviewLicenseRegulatoryAuthorityQuery,
  ApplicantQualificationReviewLicenseRegulatoryAuthorityQueryVariables
>;
export const ApplicantQualificationReviewSummaryDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "ApplicantQualificationReviewSummary" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "applicantPortal" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: {
                    kind: "Name",
                    value: "ApplicantQualificationReviewExceptionFields",
                  },
                },
                {
                  kind: "FragmentSpread",
                  name: {
                    kind: "Name",
                    value: "ApplicantQualificationReviewApplicantInfoFields",
                  },
                },
                {
                  kind: "FragmentSpread",
                  name: {
                    kind: "Name",
                    value: "ApplicantQualificationReviewLicenseFields",
                  },
                },
                {
                  kind: "FragmentSpread",
                  name: {
                    kind: "Name",
                    value:
                      "ApplicantQualificationReviewCSExamNotRecentPastFields",
                  },
                },
                {
                  kind: "FragmentSpread",
                  name: {
                    kind: "Name",
                    value: "ApplicantQualificationReviewCSExamFields",
                  },
                },
                {
                  kind: "FragmentSpread",
                  name: {
                    kind: "Name",
                    value: "ApplicantQualificationReviewEducationFields",
                  },
                },
                {
                  kind: "FragmentSpread",
                  name: {
                    kind: "Name",
                    value: "ApplicantQualificationReviewPathway6Fields",
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "_id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "caseDetails" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "state" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "isExceptionCase" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "output" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "outputReason" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "currentPathway" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "isRequiredDataProvided" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "graduation" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "isYearEligible" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "schoolEligibility" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "schoolPathway" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "eligibilityPreCheck" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "eligibility" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "isEligible" },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "eligibilityCheckTimestamp",
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "responseCode" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "responseMsg" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "lastScreenName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "isStep2CSFailed" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...ApplicantQualificationReviewExceptionFieldsFragmentDoc.definitions,
    ...ApplicantQualificationReviewApplicantInfoFieldsFragmentDoc.definitions,
    ...ApplicantQualificationReviewLicenseFieldsFragmentDoc.definitions,
    ...ApplicantQualificationReviewCsExamNotRecentPastFieldsFragmentDoc.definitions,
    ...ApplicantQualificationReviewCsExamFieldsFragmentDoc.definitions,
    ...ApplicantQualificationReviewEducationFieldsFragmentDoc.definitions,
    ...ApplicantQualificationReviewPathway6FieldsFragmentDoc.definitions,
  ],
} as unknown as DocumentNode<
  ApplicantQualificationReviewSummaryQuery,
  ApplicantQualificationReviewSummaryQueryVariables
>;
export const ApplicantUploadDocumentUploadDocumentDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "ApplicantUploadDocumentUploadDocument" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "fileName" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "uploadDocument" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "fileName" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "fileName" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "token" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "token" } },
                      { kind: "Field", name: { kind: "Name", value: "uri" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ApplicantUploadDocumentUploadDocumentMutation,
  ApplicantUploadDocumentUploadDocumentMutationVariables
>;
export const ApplicantViewPdfDownloadDocumentDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "ApplicantViewPDFDownloadDocument" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "filename" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "downloadDocument" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "fileName" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "filename" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "token" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "token" } },
                      { kind: "Field", name: { kind: "Name", value: "uri" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ApplicantViewPdfDownloadDocumentQuery,
  ApplicantViewPdfDownloadDocumentQueryVariables
>;
